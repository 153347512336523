import React, {useEffect} from 'react'
import {Link} from 'react-router-dom';

import {Container, Header, Table} from 'semantic-ui-react'
import {useGetPublicHolidaysQuery} from "../../../lib/api/apiSlice";
import {New} from "../../core/shared/controls";

export default function PublicHolidays(props) {

  const {data: public_holidays = []} = useGetPublicHolidaysQuery()

  useEffect(() => {
    document.title = 'Feiertage | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
    <Header as="h2">Feiertage</Header>
    <New/>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Tag</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Staat</Table.HeaderCell>
          <Table.HeaderCell>Land</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {public_holidays.map(holiday => <Table.Row key={holiday.id}>
          <Table.Cell><Link to={`${holiday.id}`}>{holiday.day}</Link></Table.Cell>
          <Table.Cell>{holiday.name}</Table.Cell>
          <Table.Cell>{holiday.country && holiday.country.name}</Table.Cell>
          <Table.Cell>{holiday.state && holiday.state.name}</Table.Cell>
        </Table.Row>)}
      </Table.Body>
    </Table>
  </Container>
}

