import React from 'react'
import {Container, Header, Segment} from "semantic-ui-react";
import {initialPresetting, PresettingForm} from "./PresettingForm";
import {useNavigate, useParams} from "react-router-dom";
import {useGetPresettingQuery, useUpdatePresettingMutation} from "../../../lib/api/apiSlice";
import moment from "moment/moment";

export default function PresettingEdit(props) {
  let {id} = useParams()
const navigate = useNavigate()

const  {data: presetting = initialPresetting, isLoading, isSuccess, isError, error, refetch} = useGetPresettingQuery(id)
const [updatePresetting, {isLoading: isLoadingUpdatePresetting}] = useUpdatePresettingMutation()

  const update = async (formData) => {
    try{
      let {...clean_formData} = formData
      clean_formData.auto_logging_duration = moment.duration(formData.auto_logging_duration).asSeconds()

      await updatePresetting({id: id, body: clean_formData}).unwrap()
      navigate(`/presettings/${id}`)
    } catch(err){
      console.log('Failure', err)
    }
  }
    return <Container style={{marginTop: '1em'}}>
      <Segment>
        <Header as="h2">Tätigkeit bearbeiten</Header>
        <PresettingForm formData={presetting} onSubmit={update}/>
      </Segment>
    </Container>
}
