import React, {useEffect, useState} from 'react'

import {Button, Checkbox, Divider, Dropdown, Form} from 'semantic-ui-react'
import RemoveIcon from "../../core/shared/controls/RemoveIcon";
import {Cancel, Submit} from "../../core/shared/controls";
import {useSearchParams} from "react-router-dom";
import {
  useGetBusesSelectQuery,
  useGetCoursetypesSelectQuery,
  useGetLinesSelectQuery,
  useGetPlacesSelectQuery,
  useGetSchedulesSelectQuery,
  useGetTrafficdaycodesSelectQuery
} from "../../../lib/api/apiSlice";

export const initialCourse = {
  schedule_id: '',
  line_id: '',
  bus_id: '',
  nr: '',
  arrival: '',
  arrival_place_id: '',
  departure: '',
  departure_place_id: '',
  distance:'',
  comment: '',
  course_type_id: '',
  trafficdaycodes: [],
  trafficdaycode_ids:[],
  turn_sec: 0,
  turn_course_type_id: 0,
  turn_manual: false,
  transfers: [],
  line_nr: '',
}

export function CourseForm(props) {
  const [searchParams, setSearchParams] = useSearchParams({});

  const [formData, setFormData] = useState({
    ...initialCourse,
    schedule_id: parseInt(searchParams.get("schedule_id")),
    line_id: parseInt(searchParams.get("line_id"))
  })

  const transfer_icon_select = [
    {key: "train", value: "train", text: "Zug"},
    {key: "bus", value: "bus", text: "Bus"}
  ]
  const transfer_direction_select = [
    {key: "arrive", value: "arrive", text: "Ankunft"},
    {key: "departure", value: "departure", text: "Abfahrt"}
  ]
  const {data: buses_select = []} = useGetBusesSelectQuery()
  const {data: lines_select = []} = useGetLinesSelectQuery()
  const {data: schedules_select = []} = useGetSchedulesSelectQuery()
  const {data: coursetypes_select = []} = useGetCoursetypesSelectQuery()
  const {data: places_select = []} = useGetPlacesSelectQuery()
  const {data: trafficdaycodes_select = []} = useGetTrafficdaycodesSelectQuery()


  const change = (e, data) => {
    setFormData((prevState => ({...prevState, [e.target.name]: e.target.value})))
  }
  const changeDropdown = (e, data) => {
    setFormData((prevState => ({...prevState, [data.name]: data.value})))
  }
  const changeTransfer = (e, data) => {
    let newTransfers = formData.transfers;
    newTransfers[data.index][data.name] = data.value
    setFormData((prevState => ({...prevState, transfers: newTransfers})))
  }
  const addTransfer = () => {
    console.log("addTransfers")
    console.log(formData)

    const {transfers} = formData
    const initialTransfer = {icon: 'train', direction: 'arrive', info: '', time: ''}

    const newTransfers = [ ...transfers, initialTransfer]

    setFormData((prevState => ({...prevState, transfers: newTransfers})))
  }
  const removeTransfer = (index) => {
    console.log("index", index)
    const {transfers} = formData

    let newTransfers = [...transfers]

    newTransfers.splice(index,1)

    setFormData((prevState => ({...prevState, transfers: newTransfers})))
  }
  const changeTurnManual = (e, data) => {
    setFormData((prevState => ({...prevState, turn_manual: data.checked})))

  }

  useEffect(() => {
    if (props.formData != undefined)
      setFormData(props.formData)
  }, [])


  return <Form>
    <Form.Group widths='equal'>
      <Form.Field required>
        <label>Wagenumlauf</label>
        <Dropdown placeholder="" name='schedule_id' value={formData.schedule_id}
                  onChange={changeDropdown} fluid search selection options={schedules_select}/>
      </Form.Field>
      <Form.Field required>
        <label>Linie</label>
        <Dropdown placeholder="" name='line_id' value={formData.line_id}
                  onChange={changeDropdown} fluid search selection options={lines_select}/>
      </Form.Field>
      <Form.Field>
        <Form.Input label="Kurs Nummer" placeholder="" name='nr' value={formData.nr} onChange={change} required/>
      </Form.Field>
    </Form.Group>

    <Form.Group widths='equal'>
      <Form.Field>
        <Form.Input label="Abfahrt" placeholder="" name='departure' value={formData.departure} onChange={change}
                    type="time" required/>
      </Form.Field>
      <Form.Field required>
        <label>Abfahrtsort</label>
        <Dropdown placeholder="" name='departure_place_id'
                  value={formData.departure_place_id} onChange={changeDropdown} fluid search
                  selection options={places_select}/>
      </Form.Field>
    </Form.Group>
    <Form.Group widths='equal'>
      <Form.Field>
        <Form.Input label="Ankunft" placeholder="" name='arrival' value={formData.arrival} onChange={change}
                    type="time" required/>
      </Form.Field>
      <Form.Field required>
        <label>Ankunftsort</label>
        <Dropdown placeholder="" name='arrival_place_id' value={formData.arrival_place_id}
                  onChange={changeDropdown} fluid search selection options={places_select}/>
      </Form.Field>
    </Form.Group>

    <Form.Group widths='equal'>
      <Form.Field>
        <label>Verkehrstagesschlüssel</label>
        <Dropdown placeholder='Verkehrstagesschlüssel' name="trafficdaycode_ids" onChange={changeDropdown}
                  value={formData.trafficdaycode_ids} fluid multiple search selection options={trafficdaycodes_select}/>
      </Form.Field>
      <Form.Field required>
        <label>Bus</label>
        <Dropdown placeholder='Bus wählen' name="bus_id" onChange={changeDropdown}
                  value={formData.bus_id} fluid search selection options={buses_select}/>
      </Form.Field>
      <Form.Field required>
        <label>Type</label>
        <Dropdown placeholder='Type' name="course_type_id" onChange={changeDropdown}
                  value={formData.course_type_id} fluid search selection options={coursetypes_select}/>
      </Form.Field>
      <Form.Field>
        <Form.Input label="Meter" placeholder="" name='distance' value={formData.distance} onChange={change}/>
      </Form.Field>
    </Form.Group>
    <Form.Field>
      <Form.Input label="Kommentar" placeholder="" name="comment" value={formData.comment} onChange={change}/>
    </Form.Field>

    <Divider horizontal>Wende</Divider>
    <Form.Group widths='equal'>
      <Form.Field>
        <Checkbox label="Wendetyp überschreiben" name="turn_manual" checked={formData.turn_manual}
                  onChange={changeTurnManual} slider/>
      </Form.Field>
      {formData.turn_manual && <Form.Field required>
        <label>Wendetyp</label>
        <Dropdown placeholder='Wendetyp' name="turn_course_type_id"
                  onChange={changeDropdown} value={formData.turn_course_type_id} fluid search
                  selection options={coursetypes_select}
        />
      </Form.Field>}
    </Form.Group>

    <Divider horizontal>Umsteigehinweis</Divider>

    {formData.transfers && formData.transfers.map((transfer, index) => <Form.Group widths='equal' key={index}>
        <Form.Field required>
          <label>Icon</label>
          <Dropdown name="icon" index={index}
                    onChange={changeTransfer} value={transfer.icon} fluid search
                    selection
                    options={transfer_icon_select}
          />
        </Form.Field>
        <Form.Field required>
          <label>Richtung</label>
          <Dropdown name="direction" index={index}
                    onChange={changeTransfer} value={transfer.direction} fluid search
                    selection options={transfer_direction_select}/>
        </Form.Field>
        <Form.Input index={index} label="Info" placeholder="" name="info" value={transfer.info}
                    onChange={changeTransfer}
                    required/>
        <Form.Input index={index} label="Zeit" placeholder="" name="time" value={transfer.time}
                    onChange={changeTransfer}
                    type="time"/>
        <Form.Field>
          <RemoveIcon onClick={() => removeTransfer(index)}/>
        </Form.Field>
      </Form.Group>
    )
    }
    <Button content="Umsteigehinweis hinzufügen" onClick={addTransfer} style={{marginBottom: "1em"}}/>
    <Submit onClick={() => props.onSubmit(formData)}/>
    <Cancel/>
  </Form>
}



