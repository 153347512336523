import React, {useEffect, useState} from 'react'
import {Form, Grid} from "semantic-ui-react";
import {Cancel, Submit} from "../../core/shared/controls";

export const inititalCountry = {
  id: '',
  matchcode: '',
  name: '',
}

export function CountryForm(props) {

  const [formData, setFormData] = useState({
    ...inititalCountry
  })

  const change = (e) => {
    setFormData((prevState => ({...prevState, [e.target.name]: e.target.value})))
  }
  useEffect(() => {
    if (props.formData != undefined)
      setFormData(props.formData)
  }, [])


    return <Form>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={8}>
          <Form.Field>
            <Form.Input label="Kürzel" placeholder="" name='matchcode'
                        value={formData.matchcode} onChange={change} required/>
          </Form.Field>
          </Grid.Column>
          <Grid.Column width={8}>
          <Form.Field>
            <Form.Input label="Name" placeholder="" name='name'
                        value={formData.name} onChange={change} required/>
          </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Submit onClick={() => props.onSubmit(formData)}/>
          <Cancel/>
        </Grid.Row>
      </Grid>
    </Form>
}
