import React, {useEffect, useState} from 'react'

import {Form} from "semantic-ui-react"
import {Cancel, Submit} from "../../core/shared/controls";

export const initialLine= {
  nr: "",
  name: ""
}


export function LineForm(props){
  const [formData, setFormData] = useState({
    ...initialLine
  })

  const change = (e) => {
    setFormData((prevState => ({...prevState, [e.target.name]: e.target.value})))
  }

  useEffect(() => {
    if (props.formData != undefined)
      setFormData(props.formData)
  }, [])

    return <Form>
      <Form.Field>
        <Form.Input label="Nummer" placeholder="Nummer" name='nr' value={formData.nr} onChange={change} required/>
      </Form.Field>
      <Form.Field>
        <Form.Input label="Name" placeholder="Name" name='name' value={formData.name} onChange={change}/>
      </Form.Field>
      <Submit onClick={() => props.onSubmit(formData)}/>
      <Cancel/>
    </Form>

}
