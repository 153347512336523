import React from "react";
import {BrowserRouter, Outlet, Route, Routes} from "react-router-dom";
import Buses from "./components/bus/Buses";
import BusNew from "./components/bus/BusNew";
import BusEdit from "./components/bus/BusEdit"
import BusDetail from "./components/bus/BusDetail";
import Login from "./components/Login";
import Home from "./components/Home";
import Courses from "./components/schedule/course/Courses";
import CourseNew from "./components/schedule/course/CourseNew";
import CourseDetail from "./components/schedule/course/CourseDetail";
import CourseEdit from "./components/schedule/course/CourseEdit";
import Schedules from "./components/schedule/Schedules";
import ScheduleDetail from "./components/schedule/ScheduleDetail";
import Lines from "./components/schedule/line/Lines";
import LineNew from "./components/schedule/line/LineNew";
import LineEdit from "./components/schedule/line/LineEdit";
import LineDetail from "./components/schedule/line/LineDetail";
import Planner from "./components/event/planner/Planner";
import Simulation from './components/schedule/simulation/Simulation'
import ScheduleNew from "./components/schedule/ScheduleNew";
import ScheduleEdit from "./components/schedule/ScheduleEdit";
import Events from "./components/event/Events";
import EventEdit from "./components/event/EventEdit";
import EventNew from "./components/event/EventNew";
import Help from "./components/help/Help"
import Trafficdaycode from "./components/schedule/trafficdaycode/Trafficdaycode"
import Places from './components/schedule/place/Places'
import PlaceDetail from './components/schedule/place/PlaceDetail'
import PlaceNew from './components/schedule/place/PlaceNew'
import About from './components/about/About'
import Coursetypes from "./components/schedule/coursetype/Coursetypes";
import Staffs from "./components/service/staff/Staffs";
import StaffDetail from "./components/service/staff/StaffDetail";
import StaffNew from "./components/service/staff/StaffNew";
import StaffEdit from "./components/service/staff/StaffEdit";
import Construtions from "./components/bus/construction/Construtions";
import Presettings from "./components/service/presetting/Presettings";
import PresettingNew from "./components/service/presetting/PresettingNew";
import PresettingEdit from "./components/service/presetting/PresettingEdit";
import PresettingDetail from "./components/service/presetting/PresettingDetail";
import Loggings from "./components/service/logging/Loggings";
import LoggingStaff from "./components/service/logging/LoggingStaff";
import LoggingNew from "./components/service/logging/LoggingNew";
import LoggingEdit from "./components/service/logging/LoggingEdit";
import ConstructionDetail from "./components/bus/construction/ConstructionDetail";
import ConstructionEdit from "./components/bus/construction/ConstructionEdit";
import ConstructionNew from "./components/bus/construction/ConstructionNew";
import Tags from "./components/bus/tag/Tags";
import TagDetail from "./components/bus/tag/TagDetail";
import TagNew from "./components/bus/tag/TagNew";
import TagEdit from "./components/bus/tag/TagEdit";
import Fuels from "./components/bus/fuel/Fuels";
import FuelDetail from "./components/bus/fuel/FuelDetail";
import FuelNew from "./components/bus/fuel/FuelNew";
import FuelEdit from "./components/bus/fuel/FuelEdit";
import Emission from "./components/bus/emission/Emission";
import EmissionEdit from "./components/bus/emission/EmissionEdit";
import EmissionNew from "./components/bus/emission/EmissionNew";
import EmissionDetail from "./components/bus/emission/EmissionDetail";
import Overtimes from "./components/service/overtime/Overtimes";
import Vacations from "./components/service/vacation/Vacations";
import VacationDetail from "./components/service/vacation/VacationDetail";
import VacationEdit from "./components/service/vacation/VacationEdit";
import VacationNew from "./components/service/vacation/VacationNew";
import SpecialVacation from "./components/service/specialvacation/SpecialVacation";
import ServiceNew from "./components/service/ServiceNew";
import Services from "./components/service/Services";
import PublicHolidays from "./components/settings/public-holiday/PublicHolidays";
import PublicHolidayDetail from "./components/settings/public-holiday/PublicHolidayDetail";
import PublicHolidayEdit from "./components/settings/public-holiday/PublicHolidayEdit";
import PublicHolidayNew from "./components/settings/public-holiday/PublicHolidayNew"
import SchoolHolidays from "./components/settings/school-holiday/SchoolHolidays";
import SchoolHolidayDetail from "./components/settings/school-holiday/SchoolHolidayDetail";
import SchoolHolidayNew from "./components/settings/school-holiday/SchoolHolidayNew";
import Settings from "./components/settings/Settings";
import Countries from "./components/settings/country/Countries";
import CountryDetail from "./components/settings/country/CountryDetail";
import CountryNew from "./components/settings/country/CountryNew";
import States from "./components/settings/state/States";
import StateDetail from "./components/settings/state/StateDetail";
import StateNew from "./components/settings/state/StateNew";
import Navigation from "./components/core/Navigation";
import Footer from "./components/core/Footer";

import './style.css'
import PlaceEdit from "./components/schedule/place/PlaceEdit";
import EventDetail from "./components/event/EventDetail";
import SchoolHolidayEdit from "./components/settings/school-holiday/SchoolHolidayEdit";
import StateEdit from "./components/settings/state/StateEdit";
import CountryEdit from "./components/settings/country/CountryEdit";
import {Media} from "./AppMedia";
import SettingEdit from "./components/settings/SettingEdit";
import OvertimeDetail from "./components/service/overtime/OvertimeDetail";
import OvertimeNew from "./components/service/overtime/OvertimeNew";
import OvertimeEdit from "./components/service/overtime/OvertimeEdit";
import VariableWorkingTimeNew from "./components/service/variable-working-time/VariableWorkingTimeNew";
import VariableWorkingTimeEdit from "./components/service/variable-working-time/VariableWorkingTimeEdit";

const NotFound = () => (
  <h1>code404 - Page not found</h1>
)

const Layout = () => {
  return <div>
    <Navigation/>
    <Media at='mobile'>
      <div style={{marginTop:"50px"}}>
      <Outlet/>
      <Footer/>
      </div>
    </Media>
    <Media greaterThan='mobile'>
      <div style={{marginLeft:"250px"}}>
        <Outlet/>
      <Footer/>
      </div>
    </Media>

  </div>
}

export default function AppRoutes(props) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout/>}>
          <Route index element={<Home/>}/>
          <Route path='login' element={<Login/>}/>
          <Route path='schedules'>
            <Route index element={<Schedules/>}/>
            <Route path=':id' element={<ScheduleDetail/>}/>
            <Route path=':id/edit' element={<ScheduleEdit/>}/>
            <Route path='new' element={<ScheduleNew/>}/>
            <Route path='planer' element={<Planner/>}/>
          </Route>

          <Route path='/simulation' element={<Simulation/>}/>

          <Route path='/services'>
            <Route index element={<Services/>}/>
            <Route path='new' element={<ServiceNew/>}/>
            <Route path='variable'>
              <Route index element={<VariableWorkingTimeNew/>}/>
              <Route path=':id/edit' element={<VariableWorkingTimeEdit/>}/>
            </Route>
          </Route>

          <Route path='/staffs' element={<Staffs/>}/>
          <Route path='/staffs/:id' element={<StaffDetail/>}/>
          <Route path='/staffs/:id/edit' element={<StaffEdit/>}/>
          <Route path='/staffs/new' element={<StaffNew/>}/>

          <Route path='/trafficdaycodes' element={<Trafficdaycode/>}/>

          <Route path='/presettings' element={<Presettings/>}/>
          <Route path='/presettings/:id' element={<PresettingDetail/>}/>
          <Route path='/presettings/:id/edit' element={<PresettingEdit/>}/>
          <Route path='/presettings/new' element={<PresettingNew/>}/>

          <Route path='/loggings' element={<Loggings/>}/>
          <Route path='/loggings/:id/edit' element={<LoggingEdit/>}/>
          <Route path='/loggings/staff/:staff_id' element={<LoggingStaff/>}/>
          <Route path='/loggings/staff/:staff_id/new' element={<LoggingNew/>}/>

          <Route path='/overtimes' element={<Overtimes/>}/>
          <Route path='/overtimes/:staff_id' element={<OvertimeDetail/>}/>
          <Route path='/overtimes/:staff_id/edit/:id' element={<OvertimeEdit/>}/>
          <Route path='/overtimes/:staff_id/new' element={<OvertimeNew/>}/>

          <Route path='/vacations' element={<Vacations/>}/>
          <Route path='/vacations/:staff_id' element={<VacationDetail/>}/>
          <Route path='/vacations/:staff_id/edit/:id' element={<VacationEdit/>}/>
          <Route path='/vacations/:staff_id/new' element={<VacationNew/>}/>

          <Route path='/specialvacations' element={<SpecialVacation/>}/>

          <Route path='/planer' element={<Planner/>}/>

          <Route path='/events' element={<Events/>}/>
          <Route path='/events/:id' element={<EventDetail/>}/>
          <Route path='/events/:id/edit' element={<EventEdit/>}/>
          <Route path='/events/new' element={<EventNew/>}/>

          <Route path='/buses' element={<Buses/>}/>
          <Route path='/buses/:id' element={<BusDetail/>}/>
          <Route path='/buses/:id/edit' element={<BusEdit/>}/>
          <Route path='/buses/new' element={<BusNew/>}/>

          <Route path='/tags' element={<Tags/>}/>
          <Route path='/tags/:id' element={<TagDetail/>}/>
          <Route path='/tags/:id/edit' element={<TagEdit/>}/>
          <Route path='/tags/new' element={<TagNew/>}/>

          <Route path='/fuels' element={<Fuels/>}/>
          <Route path='/fuels/:id' element={<FuelDetail/>}/>
          <Route path='/fuels/:id/edit' element={<FuelEdit/>}/>
          <Route path='/fuels/new' element={<FuelNew/>}/>

          <Route path='/lines' element={<Lines/>}/>
          <Route path='/lines/:id' element={<LineDetail/>}/>
          <Route path='/lines/:id/edit' element={<LineEdit/>}/>
          <Route path='/lines/new' element={<LineNew/>}/>

          <Route path='/courses' element={<Courses/>}/>
          <Route path='/courses/:id' element={<CourseDetail/>}/>
          <Route path='/courses/:id/edit' element={<CourseEdit/>}/>
          <Route path='/courses/new' element={<CourseNew/>}/>
          <Route path='/courses/neu/schedule/:scheduleId' element={<CourseNew/>}/>

          <Route path='/kurs/neu/:lineNr' element={<CourseNew/>}/>
          <Route path='/coursetypes' element={<Coursetypes/>}/>
          <Route path='/constructions' element={<Construtions/>}/>
          <Route path='/constructions/:id' element={<ConstructionDetail/>}/>
          <Route path='/constructions/:id/edit' element={<ConstructionEdit/>}/>
          <Route path='/constructions/new' element={<ConstructionNew/>}/>
          <Route path='/emissions' element={<Emission/>}/>
          <Route path='/emissions/:id' element={<EmissionDetail/>}/>
          <Route path='/emissions/:id/edit' element={<EmissionEdit/>}/>
          <Route path='/emissions/new' element={<EmissionNew/>}/>

          <Route path='/places' element={<Places/>}/>
          <Route path='/places/:id' element={<PlaceDetail/>}/>
          <Route path='/places/:id/edit' element={<PlaceEdit/>}/>
          <Route path='/places/new' element={<PlaceNew/>}/>

          <Route path='/settings' element={<Settings/>}/>
          <Route path='/settings/edit' element={<SettingEdit/>}/>

          <Route path='/countries' element={<Countries/>}/>
          <Route path='/countries/:id' element={<CountryDetail/>}/>
          <Route path='/countries/:id/edit' element={<CountryEdit/>}/>
          <Route path='/countries/new' element={<CountryNew/>}/>

          <Route path='/states' element={<States/>}/>
          <Route path='/states/:id' element={<StateDetail/>}/>
          <Route path='/states/:id/edit' element={<StateEdit/>}/>
          <Route path='/states/new' element={<StateNew/>}/>

          <Route path='/publicholidays' element={<PublicHolidays/>}/>
          <Route path='/publicholidays/:id' element={<PublicHolidayDetail/>}/>
          <Route path='/publicholidays/:id/edit' element={<PublicHolidayEdit/>}/>
          <Route path='/publicholidays/new' element={<PublicHolidayNew/>}/>

          <Route path='/schoolholidays' element={<SchoolHolidays/>}/>
          <Route path='/schoolholidays/:id' element={<SchoolHolidayDetail/>}/>
          <Route path='/schoolholidays/:id/edit' element={<SchoolHolidayEdit/>}/>
          <Route path='/schoolholidays/new' element={<SchoolHolidayNew/>}/>

          <Route path='/help' element={<Help/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='*' element={<NotFound/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

