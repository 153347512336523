import React from 'react'
import {Link} from 'react-router-dom'

import {Container, Grid, Header, Table} from 'semantic-ui-react'
import {useGetFuelsQuery} from "../../../lib/api/apiSlice";
import {New} from "../../core/shared/controls";


export default function Fuels(props) {
  const {
    data: fuels = [],
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetFuelsQuery()
  return <Container style={{marginTop: '1em'}}>
    <Header as="h2">Kraftstoffe</Header>
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={10}>
          <New/>
        </Grid.Column>
        <Grid.Column width={6}>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <div className='vertical-scrollable'>
      <Table unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Anzahl Busse</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {fuels.map(fuel => <Table.Row key={fuel.id}>
              <Table.Cell>
                <Link to={`${fuel.id}`}>{fuel.name}</Link>
              </Table.Cell>
              <Table.Cell>
                {fuel.buses && fuel.buses.length}
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  </Container>

}
