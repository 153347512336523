import React, {useEffect, useState} from 'react'

import {Divider, Dropdown, Form, Grid, Label, List} from 'semantic-ui-react'
import FormatedBool from "../core/shared/FormatedBool";
import ColorHelper from "../core/shared/color/ColorHelper";
import SuggestColors from "../core/shared/color/SuggestColors";
import {Cancel, Submit} from "../core/shared/controls";
import {
  useGetConstructionsSelectQuery,
  useGetEmissionsSelectQuery,
  useGetFuelsSelectQuery,
  useGetTagsSelectQuery
} from "../../lib/api/apiSlice";

export const initialBus = {
  id: '',
  axle: '',
  baby_stroller_place: false,
  color: '',
  comment: '',
  corner_handles: false,
  date_of_construction: '',
  date_of_initial_registration: '',
  emtpy_weight: '',
  fuel_id: '',
  handicapped_accessible: false,
  height: '',
  interior_lighting: false,
  length: '',
  license_plate: '',
  lift: false,
  manufacturer: '',
  manufacturer_type: '',
  max_passengers: '',
  name: '',
  neck_rest_width_1: '',
  neck_rest_width_2: '',
  safety_check: '',
  seats: '',
  seat_row_distance: '',
  skid_chain_able: false,
  standing_room: '',
  stop_request_button: false,
  tag_ids: [],
  total_weight: '',
  trailer: false,
  vehicle_color: '',
  vehicle_construction_type_id: '',
  vehicle_emission_standard_id: '',
  passenger_information_inside: '',
  passenger_information_outside: '',
  power: '',
  wheelchair_place: false,
  width: '',
}


export function BusForm(props) {
  const [formData, setFormData] = useState({
    ...initialBus
  })
  const {data: fuels_select = []} = useGetFuelsSelectQuery()
  const {data: emissions_select = []} = useGetEmissionsSelectQuery()
  const {data: constructions_select = []} = useGetConstructionsSelectQuery()
  const {data: tags_select = []} = useGetTagsSelectQuery()

  const change = (e) => {
    setFormData((prevState => ({...prevState, [e.target.name]: e.target.value})))
  }
  const changeDropdown = (e, data) => {
    setFormData((prevState => ({...prevState, [data.name]: data.value})))
  }
  const clickColor = (color) => {
    setFormData((prevState => ({...prevState, color: color})))
  }
  const handleCheckTrailer = () => {
    setFormData((prevState => ({...prevState, trailer: !prevState.trailer})))
  }
  const handleCheckSkidChainAble = () => {
    setFormData((prevState => ({...prevState, skid_chain_able: !prevState.skid_chain_able})))
  }
  const handleCheckStopRequestButton = () => {
    setFormData((prevState => ({...prevState, stop_request_button: !prevState.stop_request_button})))
  }
  const handleCheckCornerHandles = () => {
    setFormData((prevState => ({...prevState, corner_handles: !prevState.corner_handles})))
  }
  const handleCheckInteriorLighting = () => {
    setFormData((prevState => ({...prevState, interior_lighting: !prevState.interior_lighting})))
  }
  const handleCheckHandicappedAccessible = () => {
    setFormData((prevState => ({...prevState, handicapped_accessible: !prevState.handicapped_accessible})))
  }
  const handleCheckWheelchairPlace = () => {
    setFormData((prevState => ({...prevState, wheelchair_place: !prevState.wheelchair_place})))
  }
  const handleCheckLift = () => {
    setFormData((prevState => ({...prevState, lift: !prevState.lift})))
  }
  const handleCheckBabyStrollerPlace = () => {
    setFormData((prevState => ({...prevState, baby_stroller_place: !prevState.baby_stroller_place})))
  }

  const renderLabel = (option) => ({
    content: option.text,
    style: {background: option.color, color: ColorHelper.calculateForegroundTextColor(option.color)}
  })


  useEffect(() => {
    if (props.formData != undefined)
      setFormData(props.formData)
  }, [])

  return <Form>
    <Grid style={{marginTop: '1em'}} stackable>
      <Grid.Row>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Bus</List.Header>
                <List.Description>
                  <Form.Input label="Name" name='name' value={formData.name} onChange={change} required/>
                  <Form.Input label="Hersteller" name='manufacturer' value={formData.manufacturer}
                              onChange={change}/>
                  <Form.Input label="Type" name='manufacturer_type' value={formData.manufacturer_type}
                              onChange={change}/>
                  <Form.Input label="Kennzeichen" name='license_plate' value={formData.license_plate}
                              onChange={change}/>
                  <Form.Input label="Fahrzeugfarbe" name='vehicle_color' value={formData.vehicle_color}
                              onChange={change}/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Termine</List.Header>
                <List.Description>
                  <Form.Input label="Begutachtung" name='safety_check' value={formData.safety_check}
                              onChange={change} type="date"/>
                  <Form.Input label="Baujahr" name='date_of_construction' value={formData.date_of_construction}
                              onChange={change} type="date"/>
                  <Form.Input label="Erstzulassung" name='date_of_initial_registration'
                              value={formData.date_of_initial_registration}
                              onChange={change} type="date"/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={4}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Motor</List.Header>
                <List.Description>
                  <Form.Field>
                    <label>Kraftstoff</label>
                    <Dropdown name="fuel_id"
                              onChange={changeDropdown} value={formData.fuel_id}
                              fluid search selection options={fuels_select}/>
                  </Form.Field>
                  <Form.Field>
                    <label>Abgasnorm</label>
                    <Dropdown name="vehicle_emission_standard_id"
                              onChange={changeDropdown}
                              value={formData.vehicle_emission_standard_id}
                              fluid search selection options={emissions_select}/>
                  </Form.Field>
                  <Form.Input label="Leistung [kW]" name='power' value={formData.power}
                              onChange={change} type="number"/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Technische Details</List.Header>
                <List.Description>
                  <Form.Input label="Achsen" name='axle' value={formData.axle}
                              onChange={change} type='number'/>
                  <Form.Input label="Gesamtgewicht [kg]" name='total_weight' value={formData.total_weight}
                              onChange={change} type='number'/>
                  <Form.Input label="Eigengewicht [kg]" name='emtpy_weight' value={formData.emtpy_weight}
                              onChange={change} type='number'/>
                  <Form.Input label="Länge [mm]" name='length' value={formData.length}
                              onChange={change} type='number'/>
                  <Form.Input label="Breite [mm]" name='width' value={formData.width}
                              onChange={change} type='number'/>
                  <Form.Input label="Höhe [mm]" name='height' value={formData.height}
                              onChange={change} type='number'/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header></List.Header>
                <List.Description>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={4}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header></List.Header>
                <List.Description>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Input label='Kommentar' name='comment' value={formData.comment} onChange={change}/>

        </Grid.Column>
      </Grid.Row>
      <Divider horizontal content='Ausstattungsmerkmale'/>
      <Grid.Row>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Fahrzeugkonstruktion</List.Header>
                <List.Description>
                  <Form.Field>
                    <label>Fahrzeugtyp</label>
                    <Dropdown name="vehicle_construction_type_id"
                              onChange={changeDropdown}
                              value={formData.vehicle_construction_type_id}
                              fluid search selection options={constructions_select}/>
                  </Form.Field>
                  <Form.Checkbox label='Schneeketten fähig' name='skid_chain_able'
                                 checked={formData.skid_chain_able}
                                 onChange={handleCheckSkidChainAble} type='checkbox'/>
                  <Form.Checkbox label='Anhänger' name='trailer'
                                 checked={formData.trailer}
                                 onChange={handleCheckTrailer} type='checkbox'/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Fahrgastvorteile</List.Header>
                <List.Description>
                  <Form.Input label="Anzeige innen" name='passenger_information_inside'
                              value={formData.passenger_information_inside} onChange={change}/>
                  <Form.Input label="Anzeige Außen" name='passenger_information_outside'
                              value={formData.passenger_information_outside} onChange={change}/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={4}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Passagiere</List.Header>
                <List.Description>
                  <Form.Input label="Sitzplätze" name='seats'
                              value={formData.seats} onChange={change} type="number"/>
                  <Form.Input label="Stehplätze" name='standing_room'
                              value={formData.standing_room} onChange={change} type="number"/>
                  <Form.Input label="Max. Passagiere" name='max_passengers'
                              value={formData.max_passengers} onChange={change} type="number"/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Innenaustattung</List.Header>
                <List.Description>
                  <Form.Input label="Sitzreihenabstand [mm]" name='seat_row_distance'
                              value={formData.seat_row_distance} onChange={change} type="number"/>
                  <Form.Input label="Kopfstütze Messpunkt 1 [mm]" name='neck_rest_width_1'
                              value={formData.neck_rest_width_1} onChange={change} type="number"/>
                  <Form.Input label="Kopfstütze Messpunkt 2 [mm]" name='neck_rest_width_2'
                              value={formData.neck_rest_width_2} onChange={change} type="number"/>
                  <Form.Checkbox label='Haltewunschtaster' name='stop_request_button'
                                 checked={formData.stop_request_button}
                                 onChange={handleCheckStopRequestButton} type='checkbox'/>
                  <Form.Checkbox label='Eckhandgriffe' name='corner_handles'
                                 checked={formData.corner_handles}
                                 onChange={handleCheckCornerHandles} type='checkbox'/>
                  <Form.Checkbox label='Innenbeleuchtung' name='interior_lighting'
                                 checked={formData.interior_lighting}
                                 onChange={handleCheckInteriorLighting} type='checkbox'/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Zugänglichkeit</List.Header>
                <List.Description>
                  <Form.Checkbox label='Begindertengerecht' name='handicapped_accessible'
                                 checked={formData.handicapped_accessible}
                                 onChange={handleCheckHandicappedAccessible} type='checkbox'/>
                  <Form.Checkbox label='Rollstuhlplatz' name='wheelchair_place'
                                 checked={formData.wheelchair_place}
                                 onChange={handleCheckWheelchairPlace} type='checkbox'/>
                  <Form.Checkbox label='Hublift oder Rohlstuhlrampe' name='lift'
                                 checked={formData.lift}
                                 onChange={handleCheckLift} type='checkbox'/>
                  <Form.Checkbox label='Kinderwagenplatz' name='baby_stroller_place'
                                 checked={formData.baby_stroller_place}
                                 onChange={handleCheckBabyStrollerPlace} type='checkbox'/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={4}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>FCBUS Details</List.Header>
                <List.Description>
                  {formData.id != '' && <span><strong>Status</strong><br/>
                        Aktiv: <FormatedBool bool={formData.active}/><br/></span>
                  }
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Form.Input label="Anzeigefarbe" placeholder="#FF5533" name='color'
                      value={formData.color} onChange={clickColor} labelPosition='left'>
            <Label basic style={{background: `${formData.color}`}}></Label>
            <input/>
          </Form.Input>
          <SuggestColors onClick={clickColor}/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Form.Field>
            <label>Labels</label>
            <Dropdown name="tag_ids"
                      onChange={changeDropdown}
                      value={formData.tag_ids}
                      fluid multiple search selection options={tags_select}
                      renderLabel={renderLabel}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Submit onClick={() => props.onSubmit(formData)}/>
        <Cancel/>
      </Grid.Row>
    </Grid>
  </Form>
}
