import React, {useEffect} from "react";
import {Link} from 'react-router-dom';
import FormatedDateTimeBus from "../core/shared/FormatedDateTimeBus";
import {Container, Header, Table} from "semantic-ui-react";
import {useGetEventsQuery} from "../../lib/api/apiSlice";
import {New} from "../core/shared/controls";

export default function Events(props) {
  const {
    data: events = [],
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetEventsQuery()

  useEffect(() => {
    document.title = 'Fahraufträge | First Class bus'
  }, [])

  console.log("events", events)
  return <Container style={{marginTop: '1em'}}>
    <Header size="huge">Fahraufträge</Header>
    <p>Hinweis: Die Anzeige der Fahraufträge ist aktuell auf 30 limitiert.</p>
    <New/>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.HeaderCell>Titel</Table.HeaderCell>
          <Table.HeaderCell>Bus</Table.HeaderCell>
          <Table.HeaderCell>Abfahrt</Table.HeaderCell>
          <Table.HeaderCell>Abfahrtsort</Table.HeaderCell>
          <Table.HeaderCell>Ankunft</Table.HeaderCell>
          <Table.HeaderCell>Ankunftsort</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {events.map((event) => <Table.Row>
          <Table.Cell><Link to={`${event.id}`}>{event.id}</Link></Table.Cell>
          <Table.Cell><Link to={`${event.id}`}>{event.title}</Link></Table.Cell>
            <Table.Cell>{event.bus && event.bus.name}</Table.Cell>
            <Table.Cell><FormatedDateTimeBus date={event.start}/></Table.Cell>
          <Table.Cell>{event.departure_place}</Table.Cell>
          <Table.Cell><FormatedDateTimeBus date={event.end}/></Table.Cell>
            <Table.Cell>{event.arrival_place}</Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  </Container>


}
