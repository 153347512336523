import React from 'react'
import {Container, Header, Segment} from "semantic-ui-react";
import {PresettingForm} from "./PresettingForm";
import {useNavigate} from "react-router-dom";
import {useAddNewPresettingMutation} from "../../../lib/api/apiSlice";
import moment from "moment";

export default function PresettingNew(props){
  const navigate = useNavigate()
  const [addNewPresetting, {isLoading: isLoadingAddNewPresetting}] = useAddNewPresettingMutation()

  const create = async (formData) => {
    try{
      let {...clean_formData} = formData
      clean_formData.auto_logging_duration = moment.duration(formData.auto_logging_duration).asSeconds()

      await addNewPresetting(clean_formData).unwrap()
      navigate('/presettings')
    } catch(err){
      console.log('Failure', err)
    }
  }
    return <Container>
      <Segment>
        <Header as="h2">Neue Tätigkeit anlegen</Header>
        <PresettingForm onSubmit={create}/>
      </Segment>
    </Container>
}
