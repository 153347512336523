import React from 'react'


export default function FormatedDay(props) {

  const {day} = props
  let german_day = ''

  switch (day) {
    case 'Monday':
      german_day = 'Mo'
      break;
    case 'Tuesday':
      german_day = 'Di'
      break;
    case 'Wednesday':
      german_day = 'Mi'
      break;
    case 'Thursday':
      german_day = 'Do'
      break;
    case 'Friday':
      german_day = 'Fr'
      break;
    case 'Saturday':
      german_day = 'Sa'
      break;
    case 'Sunday':
      german_day = 'So'
      break;
    default:
      german_day = 'error'
      break;
  }

  return <span>
      {german_day}
   </span>

}
