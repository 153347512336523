import React from 'react'

export default function ServiceType(props) {
  const {service_type} = props
  let ret_val = ''
  switch (service_type) {
    case 0:
      ret_val = 'Reise';
      break;
    case 1:
      ret_val = 'Linie';
      break;
  }
  return <span>
      {ret_val}
    </span>

}
