import React, {useEffect} from 'react'

import {Container, Header} from 'semantic-ui-react'
import {useNavigate} from "react-router-dom";
import {useAddNewLineMutation} from "../../../lib/api/apiSlice";
import {LineForm} from "./LineForm";


export default function LineNew(props){
  const navigate = useNavigate()
  const [addNewLine, {isLoading: isLoadingAddNewLine}] = useAddNewLineMutation()

  const create = async (formData) => {
    try{


      await addNewLine(formData).unwrap()
      navigate('/lines')
    } catch(err){
      console.log('Failure', err)
    }
  }

  useEffect(() => {
    document.title = 'Linie anlegen | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
    <Header as="h5">Neuen Bus anlegen</Header>
    <LineForm onSubmit={create}/>
  </Container>
}

