import React, {useEffect} from 'react'

//import {CourseForm} from './CourseForm'
import {Container, Header} from 'semantic-ui-react'
import {CourseForm, initialCourse} from "./CourseForm";
import {useNavigate, useParams} from "react-router-dom";
import {useGetCourseQuery, useUpdateCourseMutation} from "../../../lib/api/apiSlice";


export default function CourseEdit(props) {
  let {id} = useParams()
  const navigate = useNavigate()

  const {data: course = initialCourse, isLoading, isSuccess, isError, error, refetch} = useGetCourseQuery(id)
  const [updateCourse, {isLoading: isLoadingUpdateCourse}] = useUpdateCourseMutation()

  const update = async (formData) => {
    try {
      await updateCourse({id: id, body: formData}).unwrap()
      navigate(`/courses/${id}`)
    } catch (err) {
      console.log('Failure', err)
    }
  }
  useEffect(() => {
    document.title = 'Kurs bearbeiten | First Class bus'
  }, [])

    return <Container style={{marginTop: '1em'}}>
      <Header as="h5">Kurs bearbeiten</Header>
      {!isLoading && <CourseForm formData={course} onSubmit={update}/>}
    </Container>
}
