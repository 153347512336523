import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";

import reqwest from 'reqwest'
import {Button, Container, Form, Grid, Header, Image, Input, List, Segment} from 'semantic-ui-react'
import FCBusLogo from './core/shared/FCBusLogo'


export default function Login(props) {
  const api_url = API_URL

  const [data, setData] = useState({
    data: {
      email: '',
      password: ''
    }
  })

  const navigate = useNavigate()

  const change = (e) => {
    e.preventDefault()
    let newState = data
    let value = e.target.value
    newState[e.target.name] = value
    setData(newState)
  }

  const send = (e) => {
    e.preventDefault()
    console.log("Now before send. data: ", data)
    if (data.email !== '' && data.password !== '') {
      let body = {
        email: data.email,
        password: data.password
      }
      reqwest({
        url: `${api_url}/v1/auth`,
        type: 'json',
        method: 'post',
        contentType: 'application/json',
        crossOrigin: true,
        data: JSON.stringify(body),
        success: (resp) => {
          const auth = {
            id: resp.id,
            name: resp.name,
            token: btoa(resp.email + ':' + resp.password)
          }
          window.sessionStorage.setItem('auth', JSON.stringify(auth))
          setData( {email: '', password: ''})
          console.log("now navigate to /")
          navigate('/')
        },
        error: (error) => {
          let data = {
            email: data.email,
            password: ''
          }
          setData(data)
          console.error(error)
          if (error.status === 401) {
            setError({error: {code: error.status, message: 'Zugangsdaten ungültig!'}})
          }
        }
      })
    }
  }

  useEffect(() => {
    document.title = 'Login | First Class bus'
    document.head.querySelector('meta[name=description]').content = ''
    if (window.sessionStorage.auth && window.sessionStorage.auth.length > 0) navigate('/')
  }, [])

  const {email, password} = data

  return (<Container>
      <Grid doubling columns={2} verticalAlign='middle' centered padded>
        <Grid.Column>
          <Segment>
            <Form onSubmit={send}>
              <Header>FCBUS Anmeldung</Header>
              <Form.Field>
                <Input name='email' value={email} onChange={change} autoFocus
                       placeholder='E-Mail Adresse' required/>
              </Form.Field>
              <Form.Field>
                <Input type='password' name='password' value={password} onChange={change}
                       placeholder='Passwort *'
                       required/>
              </Form.Field>
              <Button type="submit" name="action">Login
              </Button>
            </Form>
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <FCBusLogo/>
          <Header as="h5">First Class Bus</Header>
          <p>Ist eine online App für Busunternehmen und Reiseveranstalter. Es bietet Funktionen zur Verwaltung von</p>
          <Grid columns={2}>
            <Grid.Column>
              <List>
                <List.Item><List.Icon name='thumbs outline up'/><List.Content>Bussen</List.Content></List.Item>
                <List.Item><List.Icon name='thumbs outline up'/><List.Content>Fahrpläne</List.Content></List.Item>
                <List.Item><List.Icon name='thumbs outline up'/><List.Content>Linien</List.Content></List.Item>
                <List.Item><List.Icon name='thumbs outline up'/><List.Content>Kursen</List.Content></List.Item>
                <List.Item><List.Icon name='thumbs outline up'/><List.Content>Kunden</List.Content></List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item><List.Icon name='thumbs outline up'/><List.Content>Angebote</List.Content></List.Item>
                <List.Item><List.Icon name='thumbs outline up'/><List.Content>Rechnungen</List.Content></List.Item>
                <List.Item><List.Icon name='thumbs outline up'/><List.Content>Reisen</List.Content></List.Item>
                <List.Item><List.Icon name='thumbs outline up'/><List.Content>Artikel</List.Content></List.Item>
              </List>
            </Grid.Column>
          </Grid>
          <Header as="h5">Von überall auf der Welt. Am Computer, Tablet oder Smartphone!</Header>
        </Grid.Column>
      </Grid>
      <Image src="https://i.imgur.com/s5LSw2a.jpg"/>
    </Container>
  )
}
