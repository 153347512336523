import React, {useEffect, useState} from 'react'
import {Divider, Dropdown, Form, Grid, List} from "semantic-ui-react";
import {useGetDrivingLicenseGroupsSelectQuery} from "../../../lib/api/apiSlice";
import {Cancel, Submit} from "../../core/shared/controls";

export const initialStaff = {}

export const civil_status_select = [
  {key: 0, value: "single", text: "ledig"},
  {key: 1, value: "married", text: "verheiratet"},
  {key: 2, value: "civil_union", text: "in eingetragener Partnerschaft lebend"},
  {key: 3, value: "divorced", text: "geschieden"},
  {key: 4, value: "divorced_civil_union", text: "aufgelöste eingetragene Partnerschaft"},
  {key: 5, value: "widowed", text: "verwitwet"},
  {key: 6, value: "widowed_civil_union", text: "hinterbliebener eingetragener Partner"}
]

export const crew_select = [
  {key: 0, value: 'driving', text: 'Fahrpersonal'},
  {key: 1, value: 'operating', text: 'Betriebspersonal'}
]

export function StaffForm(props) {

  const [formData, setFormData] = useState({
    ...initialStaff
  })

  const change = (e) => {
    setFormData((prevState => ({...prevState, [e.target.name]: e.target.value})))
  }

  const {data: driving_license_groups_select = []} = useGetDrivingLicenseGroupsSelectQuery()

  useEffect(() => {
    if (props.formData != undefined)
      setFormData(props.formData)
  }, [])

  const changeDropdown = (e, data) => {
    setFormData((prevState => ({...prevState, [data.name]: data.value})))
  }
  const handleCheckAdvantageDisabled = () => {
    setFormData((prevState => ({...prevState, advantage_disabled: !prevState.advantage_disabled})))
  }
  const handleCheckApprentice = () => {
    setFormData((prevState => ({...prevState, apprentice: !prevState.apprentice})))
  }

  return <Form>
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={6}>
          <Form.Input label='Anzeigename' name='name' value={formData.name} onChange={change} required/>
          <Form.Input label='Vorname' name='forename' value={formData.forename} onChange={change}/>
          <Form.Input label='Nachname' name='surename' value={formData.surename} onChange={change}/>
          <Form.Input label='Telefon' name='phone' value={formData.phone} onChange={change}/>
          <Form.Input label='E-Mail' name='email' value={formData.email} onChange={change}/>
        </Grid.Column>
        <Grid.Column width={6}>
          <Form.Input label='Straße' name='street' value={formData.street} onChange={change}/>
          <Form.Input label='Ort' name='place' value={formData.place} onChange={change}/>
          <Form.Input label='PLZ' name='postcode' value={formData.postcode} onChange={change}/>
          <Form.Input label='Land' name='country' value={formData.country} onChange={change}/>
        </Grid.Column>
        <Grid.Column width={4}>
          <List>
            <List.Item>
              <List.Content>
                <List.Description>
                  <Form.Input label='Geburtstag' name='birthday' value={formData.birthday} onChange={change}
                              type='date'/>
                  <Form.Input label='Geburtsort' name='birth_place' value={formData.birth_place} onChange={change}/>
                  <Form.Field>
                    <label>Geschlecht</label>
                    <Dropdown name='gender'
                              onChange={changeDropdown}
                              value={formData.gender} selection options={[
                      {key: 0, value: "male", text: "männlich"},
                      {key: 1, value: "female", text: "weiblich"},
                      {key: 2, value: "diverse", text: "divers"}
                    ]}
                    />
                  </Form.Field>
                  <Form.Input label='Staatsbürgerschaft' name='citizenship' value={formData.citizenship}
                              onChange={change}/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <Form.Field>
            <label>Personenstand</label>
            <Dropdown name='civil_status'
                      onChange={changeDropdown}
                      value={formData.civil_status} selection options={civil_status_select}
            />
          </Form.Field>
          <Form.Input label='Partner' name='name_of_partner' value={formData.name_of_partner} onChange={change}/>
          <Form.Input label='geboren am' name='birthday_of_partner' value={formData.birthday_of_partner}
                      onChange={change} type='date'/>
          <Form.Input label='Kinder' name='children' value={formData.children} onChange={change}/>
        </Grid.Column>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Details</List.Header>
                <List.Description>
                  <Form.Input label='Eintrittsdatum' name='date_of_joining' value={formData.date_of_joining}
                              onChange={change} type='date'/>
                  <Form.Input label='Austrittsdatum' name='termination' value={formData.termination}
                              onChange={change} type='date'/>
                  <Form.Input label='Versicherungsnummer' name='insurance_number' value={formData.insurance_number}
                              onChange={change}/>
                  <Form.Input label='Arbeitsbewilligung von' name='work_permit_start' value={formData.work_permit_start}
                              onChange={change} type='date'/>
                  <Form.Input label='Arbeitsbewilligung bis' name='work_permit_end' value={formData.work_permit_end}
                              onChange={change} type='date'/>
                  <Form.Checkbox label='Begünstigter Behinderter' name='advantage_disabled'
                                 checked={formData.advantage_disabled}
                                 onChange={handleCheckAdvantageDisabled} type='checkbox'/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={4}>
          <Form.Checkbox label='Lehrling' name='apprentice' checked={formData.apprentice}
                         onChange={handleCheckApprentice} type='checkbox'/>
          <Form.Input label='Lehre von' name='apprentice_start' value={formData.apprentice_start}
                      onChange={change} type='date'/>
          <Form.Input label='Lehre bis' name='apprentice_end' value={formData.apprentice_end}
                      onChange={change} type='date'/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <Form.Input label='Beschäftigt als' name='employed_as' value={formData.employed_as} onChange={change}/>
          <Form.Input label='Vereinbarte Arbeitszeit' name='working_time_description'
                      value={formData.working_time_description} onChange={change}/>
          <Form.Input label='Einsatzort' name='place_of_work' value={formData.place_of_work} onChange={change}/>
          <Form.Input label='Zusatzvereinbarung' name='additional_agreement' value={formData.additional_agreement}
                      onChange={change}/>
        </Grid.Column>
        <Grid.Column width={6}>
          <Form.Input label='Kollektivvertrag' name='collective_contract' value={formData.collective_contract}
                      onChange={change}/>
          <Form.Input label='Gehalt' name='salary' value={formData.salary} onChange={change}/>
          <Form.Input label='Gehaltsgruppe' name='salary_grade' value={formData.salary_grade} onChange={change}/>
          <Form.Input label='Zuschläge' name='extra_pay' value={formData.extra_pay} onChange={change}/>
        </Grid.Column>
        <Grid.Column width={4}>
          <Form.Input label='Bank' name='bank' value={formData.bank} onChange={change}/>
          <Form.Input label='IBAN' name='iban' value={formData.iban} onChange={change}/>
          <Form.Input label='BIC' name='bic' value={formData.bic} onChange={change}/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <Form.Input label='Urlaubsanspruch' name='vacation_contingent' value={formData.vacation_contingent}
                      onChange={change} required/>
          <Form.Input label='Beginn Urlaubsjahr' name='vacation_period_start' value={formData.vacation_period_start}
                      onChange={change} type='date' required/>
        </Grid.Column>
        <Grid.Column width={10}>
          <Form.Input label='Notiz' name='note' value={formData.note} onChange={change}/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Divider horizontal>Fahrer</Divider>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <Form.Field>
            <label>Personal</label>
            <Dropdown name="crew"
                      onChange={changeDropdown} value={formData.crew}
                      fluid search selection
                      options={crew_select}/>
          </Form.Field>
        </Grid.Column>
        <Grid.Column width={6}>
          <Form.Input label='Führerschein Nummer' name='driver_driving_license_number'
                      value={formData.driver_driving_license_number} onChange={change}/>
          <Form.Input label='Führerschein Behörde' name='driver_driving_license_authority'
                      value={formData.driver_driving_license_authority} onChange={change}/>
          <Form.Input label='Führerschein Ausstellungsdatum' name='driver_driving_license_issued_start'
                      value={formData.driver_driving_license_issued_start}
                      onChange={change} type='date'/>
          <Form.Input label='Führerschein gültig bis' name='driver_driving_license_valid_end'
                      value={formData.driver_driving_license_valid_end}
                      onChange={change} type='date'/>
          <Form.Field>
            <label>Führerscheinklassen</label>
            <Dropdown placeholder='Führerscheinklassen' name="driving_license_group_ids"
                      onChange={changeDropdown} value={formData.driving_license_group_ids}
                      fluid multiple search selection
                      options={driving_license_groups_select}/>
          </Form.Field>
        </Grid.Column>
        <Grid.Column width={4}>
          <Form.Input label='Reisepass Nummer' name='driver_passport_number' value={formData.driver_passport_number}
                      onChange={change}/>
          <Form.Input label='Reisepass gültig bis' name='driver_passport_valid_end'
                      value={formData.driver_passport_valid_end}
                      onChange={change} type='date'/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <Form.Input label='Fahrerkarte Nummer' name='driver_card_number' value={formData.driver_card_number}
                      onChange={change}/>
          <Form.Input label='Fahrerkarte gültig bis' name='driver_card_valid_end' value={formData.driver_card_valid_end}
                      onChange={change} type='date'/>
          <Form.Input label='D95 gültig bis' name='d95_valid_end' value={formData.d95_valid_end}
                      onChange={change} type='date'/>
        </Grid.Column>
        <Grid.Column width={6}>
        </Grid.Column>
        <Grid.Column width={4}>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Input label='Fahrernotiz' name='driver_note' value={formData.driver_note} onChange={change}/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Submit onClick={() => props.onSubmit(formData)}/>
        <Cancel/>
      </Grid.Row>
    </Grid>
  </Form>
}
