import React, {useEffect} from 'react'
import {Container, Header} from "semantic-ui-react";
import {StaffForm} from "./StaffForm";
import {useNavigate} from "react-router-dom";
import {useAddNewStaffMutation} from "../../../lib/api/apiSlice";
import {hhmmAsSeconds} from "../../../lib/time/Time";

export default function StaffNew(props) {
  const navigate = useNavigate()
  const [addNewStaff, {isLoading: isLoadingAddNewStaff}] = useAddNewStaffMutation()

  const create = async (formData) => {
    try{
      //clean up formdata remove selects (see Mozilla Reference Operators/Destructuring_assignment)
      let {fuels_select,
        vehicle_emission_standard_select,
        vehicle_construction_type_select,
        bus_tags_select,
        ...clean_formData} = formData

      await addNewStaff(clean_formData).unwrap()
      navigate('/staffs')
    } catch(err){
      console.log('Failure', err)
    }
  }
  useEffect(() => {
    document.title = 'Mitarbeiter anlegen | First Class bus'
  }, [])
    return <Container style={{marginTop: '1em'}}>
        <Header as="h5">Neuen Fahrer anlegen</Header>
        <StaffForm onSubmit={create}/>
    </Container>
}
