import React, {useEffect} from 'react'

import {Button, Container, Divider, Grid, Header, Icon, List, Segment, Statistic} from 'semantic-ui-react'
import YourNextService from "./YourNextService";
import {Link} from "react-router-dom";
import {useGetDashboardQuery, useGetUpcomingEntriesQuery} from "../../lib/api/apiSlice";

const initialDashboard = {
  stats: {
    buses_count: '-',
    schedules_count: '-',
    archived_schedules_count: '-',
    lines_count: '-',
    courses_count: '-',
    places_count: '-',
    events_count: '-',
    total_courses_km: '-',
    total_sum_seconds_operating_time: '-'
  },
  upcoming_entries: [],
}

export default function Dashboard(props) {
  const authentication = JSON.parse(sessionStorage.getItem('auth'))
  const staff_id = authentication.id

  const {data: upcoming_entries = []} = useGetUpcomingEntriesQuery(staff_id)
  const {data: stats = {}, isLoading} = useGetDashboardQuery()

  useEffect(() => {
    document.title = "Dashboard | FBUS"
  }, [])

  const hours = Math.floor(stats.total_sum_seconds_operating_time / 60 / 60)
  const minutes = Math.floor((stats.total_sum_seconds_operating_time - (hours * 60 * 60)) / 60)
  const auth = JSON.parse(sessionStorage.getItem('auth'))
  let name = ''
  if (auth != null) {
    name = auth.name
  }

  return <Container style={{marginTop: '1em'}}>
    <Header>Hallo {name}</Header>
    <Header>Schnellstart</Header>
    <List>
      <List.Item>
        <Button icon labelPosition='left' basic primary as={Link} to={`/loggings/staff/${staff_id}/new`}> <Icon
          name='star'/>
          Neuen Zeiteintrag anlegen</Button>
      </List.Item>
    </List>
    <YourNextService upcoming_entries={upcoming_entries}/>
    <Header>Statistikübersicht</Header>
    <Segment>
      <Grid stackable>
        <Grid.Row columns={5} textAlign='center'>
          <Grid.Column>
            <Statistic>
              <Statistic.Value>
                {stats.buses_count}
              </Statistic.Value>
              <Statistic.Label> <Icon name='bus'/> Busse</Statistic.Label>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Statistic>
              <Statistic.Value>
                {stats.schedules_count}

              </Statistic.Value>
              <Statistic.Label> <Icon name='calendar outline'/> Aktive Wagenumläufe</Statistic.Label>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Statistic>
              <Statistic.Value>
                {stats.archived_schedules_count}
              </Statistic.Value>
              <Statistic.Label> <Icon name='calendar outline'/> Archivierte Wagenumläufe</Statistic.Label>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Statistic>
              <Statistic.Value>
                {stats.lines_count}
              </Statistic.Value>
              <Statistic.Label>Linien</Statistic.Label>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Statistic>
              <Statistic.Value>{stats.courses_count}</Statistic.Value>
              <Statistic.Label>Kurse</Statistic.Label>
            </Statistic>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider/>
      <Statistic.Group>
        <Statistic>
          <Statistic.Value>{`${stats.total_courses_km} km`}</Statistic.Value>
          <Statistic.Label>In aktiven Wagenumläufen</Statistic.Label>
        </Statistic>
      </Statistic.Group>
      <Statistic.Group size='small'>
        <Statistic label='Einsatzzeit aller aktiven Wagenumläufe'
                   value={`${hours} Stunden und ${minutes} Minuten`}/>
      </Statistic.Group>

      <Divider/>
      <Statistic.Group>
        <Statistic>
          <Statistic.Value>{stats.places_count}</Statistic.Value>
          <Statistic.Label> <Icon name='marker'/> Orte</Statistic.Label>

        </Statistic>
        <Statistic label='Fahraufträge' value={stats.events_count}/>
      </Statistic.Group>
    </Segment>
  </Container>
}
