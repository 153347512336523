import React, {useEffect} from 'react'

import {Checkbox, Container, Header, Table} from 'semantic-ui-react'
import CourseTypeLabel from "../../core/shared/course_type/CourseTypeLabel";
import {useGetCoursetypesQuery} from "../../../lib/api/apiSlice";


export default function Coursetypes(props) {
  const {
    data: course_types = [],
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetCoursetypesQuery()

  useEffect(() => {
    document.title = 'Kurstypen | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
          <Header as="h2">Kurstypen</Header>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Einsatzzeit</Table.HeaderCell>
                <Table.HeaderCell>Lenkzeit</Table.HeaderCell>
                <Table.HeaderCell>Arbeitszeit</Table.HeaderCell>
                <Table.HeaderCell>Lenkpause</Table.HeaderCell>
                <Table.HeaderCell>Ruhezeit</Table.HeaderCell>
                <Table.HeaderCell>Ruhepause</Table.HeaderCell>
                <Table.HeaderCell>Leerfahrt</Table.HeaderCell>
                <Table.HeaderCell>Sonstige Zeit</Table.HeaderCell>
                <Table.HeaderCell>Sonstiges</Table.HeaderCell>
                <Table.HeaderCell>Ausrücken</Table.HeaderCell>
                <Table.HeaderCell>Einrücken</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {course_types.map(course_type =>     <Table.Row key={course_type.id}>
                <Table.Cell>{course_type.id}</Table.Cell>
                <Table.Cell><CourseTypeLabel course_type={course_type}/></Table.Cell>
                <Table.Cell><Checkbox name="operating_time" checked={course_type.operating_time} disabled/></Table.Cell>
                <Table.Cell><Checkbox name="driving_time" checked={course_type.driving_time} disabled/></Table.Cell>
                <Table.Cell><Checkbox name="work_time" checked={course_type.work_time} disabled/></Table.Cell>
                <Table.Cell><Checkbox name="break" checked={course_type.break} disabled/></Table.Cell>
                <Table.Cell><Checkbox name="rest_period" checked={course_type.rest_period} disabled/></Table.Cell>
                <Table.Cell><Checkbox name="rest_break" checked={course_type.rest_break} disabled/></Table.Cell>
                <Table.Cell><Checkbox name="empty_drive" checked={course_type.empty_drive} disabled/></Table.Cell>
                <Table.Cell><Checkbox name="misc_drive" checked={course_type.misc_drive} disabled/></Table.Cell>
                <Table.Cell><Checkbox name="misc" checked={course_type.misc} disabled/></Table.Cell>
                <Table.Cell><Checkbox name="launching" checked={course_type.launching} disabled/></Table.Cell>
                <Table.Cell><Checkbox name="return_to_base" checked={course_type.return_to_base} disabled/></Table.Cell>
              </Table.Row>)}
            </Table.Body>
          </Table>
      </Container>
}

