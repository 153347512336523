import React, {useState} from "react"
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import {useNavigate, useParams} from "react-router-dom";
import {useGetEventsQuery} from "../../../lib/api/apiSlice";
import moment from "moment";

export default function PlannerCalendar(props) {
  let {id} = useParams()
  const navigate = useNavigate()

  const [settings, setSettings] = useState({
    start: "2022-11-01",
    end: "2022-11-30"
  })
  const [tooltip, setTooltip] = useState({
    event: {},
    x: 400,
    y: 400,
    show: false
  })
  const {data: events = [], isLoading, isSuccess, isError, error, refetch} = useGetEventsQuery({
    start: settings.start,
    end: settings.end
  })


  const views = {
    timeGridWeek: {
    },
    dayGridMonth: {
    },
  }

  const businessHours = {
    // days of week. an array of zero-based day of week integers (0=Sunday)
    dow: [1, 2, 3, 4, 5], // Monday - Friday
    start: '05:00',
    end: '20:00',
  }

  const headerToolbar = {
    center: 'dayGridMonth,timeGridWeek,timeGridDay',
  }

  const showTooltip = (info) => {
    const event = events.find(element => element.id === parseInt(info.event.id))
    const x = info.jsEvent.pageX
    const y = info.jsEvent.pageY
    const show = true
    setTooltip(prevState => ({...prevState, event: event, x: x, y: y, show: show}))
  }
  const hideTooltip = (info) => {
    const event = {}
    const x = info.jsEvent.pageX
    const y = info.jsEvent.pageY
    const show = false
    setTooltip(prevState => ({...prevState, event: event, x: x, y: y, show: show}))
  }

  return <div>
    {tooltip.show && <Tooltip {...tooltip}/>}
    <FullCalendar schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  initialView='dayGridMonth'
                  events={events}
                  views={views}
                  headerToolbar={headerToolbar}
                  timeZone='local'
                  editable={true}
                  contentHeight='auto'
                  nowIndicator
                  eventClick={(info) => navigate(`/events/${info.event.id}`)}
                  eventMouseEnter={showTooltip}
                  eventMouseLeave={hideTooltip}
    />
  </div>
}

const Tooltip = (props) => {
  const {event} = props
  console.log(event)
  return <div className='tooltiptopicevent'
              style={{
                width: 'auto',
                height: 'auto',
                background: '#f5f5f5',
                position: 'absolute',
                zIndex: 10001,
                padding: '10px 10px 10px 10px',
                lineHeight: '200%',
                top: props.y + 20,
                left: props.x - 60
              }}>
    {event.bus && event.bus.name} <br/>
    Ab: {moment(event.start).format("HH:mm DD.MM.YYYY")} {event.departure_place} <br/>
    An: {moment(event.end).format("HH:mm DD.MM.YYYY")} {event.arrival_place} <br/>
  </div>
}
