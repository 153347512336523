export default class ApiPDF {
  static fetch_pdf(url, filename) {
    console.log(`Fetching PDF from ${url} with filename ${filename}`);

    const auth = JSON.parse(sessionStorage.getItem('auth'))

    fetch(url, {
      method: 'GET',
      headers: {'Authorization': 'Basic ' + auth.token},
      crossOrigin: true
    })
      .then(r => r.blob())
      //.then(blobFile => new File([blobFile], "fileNameGoesHere", {type: "application/pdf"}))
      .then(blob => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.target = "_blank"
        link.setAttribute('download', filename);

        document.body.appendChild(link); // 3. Append to html page
        link.click(); // 4. Force download
        link.parentNode.removeChild(link); // 5. Clean up and remove the link
      })
  }

}
