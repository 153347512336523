import React, {useEffect, useState} from 'react'
import {Form, Grid} from "semantic-ui-react";
import {Cancel, Remove, Submit} from "../../core/shared/controls";

export const initialVacation = {
  day: '',
  amount: '',
  note: '',
  is_amount_carry_forward: false,
  is_amount_period_contingent: false,
}

export function VacationForm(props){
  const [formData, setFormData] = useState({
  ...initialVacation
  })

  const change = (e, data) => {
    setFormData((prevState => ({...prevState, [e.target.name]: e.target.value})))
  }
  const handleCheckIsAmountCarryForward = () => {
    setFormData((prevState => ({...prevState, is_amount_carry_forward: !prevState.is_amount_carry_forward})))

  }
  const handleCheckIsAmountPeriodContingent = () => {
    setFormData((prevState => ({...prevState, is_amount_period_contingent: !prevState.is_amount_period_contingent})))
  }

  useEffect(() => {
    if (props.formData != undefined)
      setFormData(props.formData)
  }, [])


    return <Form>
      <Grid stackable>
        <Grid.Row columns={3}>
          <Grid.Column>
            <Form.Input label='Eintragsdatum' name='day' value={formData.day}
                        onChange={change} type='date' required/>
          </Grid.Column>
          <Grid.Column>
            <Form.Input label='Tage' name='amount' value={formData.amount}
                        onChange={change} type='number' required/>
          </Grid.Column>
          <Grid.Column>
            <Form.Input label='Notiz' name='note' value={formData.note}
                        onChange={change}/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Form.Checkbox label="Übertrag aus Vorjahr" placeholder="" name='is_amount_carry_forward'
                           checked={formData.is_amount_carry_forward}
                           onChange={handleCheckIsAmountCarryForward}
                           type="checkbox"/>
          </Grid.Column>
          <Grid.Column>
            <Form.Checkbox label="Urlaubsanspruch" placeholder="" name='is_amount_period_contingent'
                           checked={formData.is_amount_period_contingent}
                           onChange={handleCheckIsAmountPeriodContingent}
                           type="checkbox"/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Submit onClick={() => props.onSubmit(formData)}/>
          <Cancel/>
          {formData.id && <Remove onClick={() => props.onRemove(formData)}/>}
        </Grid.Row>
      </Grid>
    </Form>
}
