import React, {useEffect} from 'react'
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  List,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableHeaderCell, Message
} from "semantic-ui-react";
import FormatedGender from "../../core/shared/FormatedGender";
import FormatedDateBus from "../../core/shared/FormatedDateBus";
import FormatedBool from "../../core/shared/FormatedBool";
import {Link, useParams} from "react-router-dom";
import {useGetStaffQuery, useUpdateStaffMutation} from "../../../lib/api/apiSlice";
import {Edit} from "../../core/shared/controls";
import {civil_status_select} from "./StaffForm";
import FormatedCrew from "./FormatedCrew";
import {hhmm, iso8601ToLocaleDateString} from "../../../lib/time/Time";

export default function StaffDetail(props) {
  let {id} = useParams()

  const {
    data: staff = {},
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetStaffQuery(id)
  const [updateStaff, {isLoading: isLoadingUpdateStaff}] = useUpdateStaffMutation()

  useEffect(() => {
    document.title = 'Mitarbeiter Details | First Class bus'
  }, [])

  const onActiveStaffClicked = async (active) => {
    let newStaff = {...staff}
    try {
      newStaff.active = active
      await updateStaff({id: id, body: newStaff}).unwrap()
    } catch (err){
      console.error("Failed to activate bus")
    }
  }

  const civil_selected = civil_status_select.find(c => c.value == staff.civil_status)
  return <Container>
    <Grid style={{marginTop: '1em'}} stackable>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2">Mitarbeiter Stammdaten</Header>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right'>
          <Edit/>
          {staff.active ? <Button basic color='red' floated='right' onClick={() => onActiveStaffClicked(false)}>
              Als inaktiv markieren
            </Button>
            :
            <Button basic color='green' floated='right' onClick={() => onActiveStaffClicked(true)}>
              Als aktiv markieren
            </Button>
          }
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <Icon name='user'/>
              <List.Content>
                <List.Header>{staff.name}</List.Header>
                <List.Description>
                  {staff.surename} {staff.forename}<br/>
                  <a href={`tel:${staff.phone}`}>{staff.phone}</a><br/>
                  <a href={`mailto:${staff.email}`}>{staff.email}</a><br/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Adresse</List.Header>
                <List.Description>
                  {staff.street}<br/>
                  {staff.postcode} {staff.place}<br/>
                  {staff.country}<br/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={4}>
          <List>
            <List.Item>
              <List.Content>
                <List.Description>
                  Geboren am <FormatedDateBus date={staff.birthday}/> in {staff.birth_place}<br/>
                  Geschlecht: <FormatedGender gender={staff.gender}/><br/>
                  Staatsbürgerschaft: {staff.citizenship}<br/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Familie</List.Header>
                <List.Description>
                  Personenstand: {civil_selected && civil_selected.text}<br/>
                  Partner: {staff.name_of_partner}<br/>
                  geboren am: <FormatedDateBus date={staff.birthday_of_partner}/> <br/>
                  Kinder: <br/>
                  {staff.children}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Details</List.Header>
                <List.Description>
                  Eintrittsdatum: <FormatedDateBus date={staff.date_of_joining}/><br/>
                  Austrittsdatum: <FormatedDateBus date={staff.termination}/><br/>
                  Versicherungsnummer: {staff.insurance_number}<br/>
                  Arbeitsbewilligung: <FormatedDateBus date={staff.work_permit_start}/>
                  - <FormatedDateBus date={staff.work_permit_end}/><br/>
                  begünstigter Behinderter: <FormatedBool bool={staff.advantage_disabled}/><br/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={4}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Lehre</List.Header>
                <List.Description>
                  Lehrling: <FormatedBool bool={staff.apprentice}/><br/>
                  Zeitraum: <FormatedDateBus date={staff.apprentice_start}/>
                  - <FormatedDateBus date={staff.apprentice_end}/>
                  <br/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Anstellung</List.Header>
                <List.Description>
                  Beschäftigt als: {staff.employed_as}<br/>
                  Vereinbarte Arbeitszeit: {staff.working_time_description} <br/>
                  Einsatzort: {staff.place_of_work} <br/>
                  Zusatzvereinbarung: {staff.additional_agreement} <br/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Gehalt</List.Header>
                <List.Description>
                  Kollektivvertrag: {staff.collective_contract}<br/>
                  Gehalt: € {staff.salary} <br/>
                  Gehaltsgruppe: {staff.salary_grade} <br/>
                  Zuschläge: {staff.extra_pay} <br/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={4}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Kontodaten</List.Header>
                <List.Description>
                  Bank: {staff.bank} <br/>
                  IBAN: {staff.iban}<br/>
                  BIC: {staff.bic}<br/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <strong>Urlaub</strong> <br/>
          Urlaubsanspruch: {staff.vacation_contingent} Tage <br/>
          Beginn Urlaubsjahr: <FormatedDateBus date={staff.vacation_period_start}/><br/>
        </Grid.Column>
        <Grid.Column width={10}>
          <strong>Notiz</strong> <br/>
          {staff.note}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Divider horizontal>Fahrer</Divider>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Fahrer</List.Header>
                <List.Description>
                  Personal: <FormatedCrew crew={staff.crew}/> <br/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Führerschein</List.Header>
                <List.Description>
                  Nummer: {staff.driver_driving_license_number} <br/>
                  Behörde: {staff.driver_driving_license_authority} <br/>
                  Ausstellungsdatum: <FormatedDateBus date={staff.driver_driving_license_issued_start}/> <br/>
                  Gültig bis: <FormatedDateBus date={staff.driver_driving_license_valid_end}/><br/>
                  Führerscheinklassen:
                  {staff.driving_license_groups && staff.driving_license_groups.map(
                    group => <span key={group.id}> {group.code},</span>
                  )}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={4}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Reisepass</List.Header>
                <List.Description>
                  Nummer: {staff.driver_passport_number} <br/>
                  Gültig bis: <FormatedDateBus date={staff.driver_passport_valid_end}/><br/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Fahrerkarte</List.Header>
                <List.Description>
                  Nummer: {staff.driver_card_number} <br/>
                  Gültig bis: <FormatedDateBus date={staff.driver_card_valid_end}/> <br/>
                  D95 gültig bis: <FormatedDateBus date={staff.d95_valid_end}/><br/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={6}>
        </Grid.Column>
        <Grid.Column width={4}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Status</List.Header>
                <List.Description>
                  Aktiv: <FormatedBool bool={staff.active}/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <strong>Fahrernotiz</strong><br/>
          {staff.driver_note}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Divider horizontal>Arbeitszeit</Divider>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <strong>Aktuelle Arbeitszeit</strong> < br/>
          Arbeitsstunden pro Monat: {staff.working_time_month} <br/>
          Arbeitsstunden pro Woche: {staff.working_time_week}<br/>
          Arbeitsstunden pro Tag: {staff.working_time_day}<br/>
          < br/>
          <strong>Historische Arbeitszeit</strong> < br/>
        </Grid.Column>
        <Grid.Column width={8}>
          <Message>
            Anpassungen der Arbeitszeit bitte via Mail senden
          </Message>
        </Grid.Column>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Gütlig ab</TableHeaderCell>
              <TableHeaderCell>Gültig bis</TableHeaderCell>
              <TableHeaderCell>Monat</TableHeaderCell>
              <TableHeaderCell>Woche</TableHeaderCell>
              <TableHeaderCell>Tag</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {staff.working_time_histories && staff.working_time_histories.map(
              history => <TableRow key={history.id}>
                <TableCell>{iso8601ToLocaleDateString(history.valid_from)}</TableCell>
                <TableCell>{history.valid_to && iso8601ToLocaleDateString(history.valid_to)}</TableCell>
                <TableCell>{history.working_time_model && hhmm(history.working_time_model.working_time_month)}</TableCell>
                <TableCell>{history.working_time_model && hhmm(history.working_time_model.working_time_week)}</TableCell>
                <TableCell>{history.working_time_model && hhmm(history.working_time_model.working_time_day)}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Grid.Row>
    </Grid>
  </Container>;
}
