import React, {useEffect} from 'react'

import {initialSchedule, ScheduleForm} from './ScheduleForm'

import {Container, Header, Segment} from 'semantic-ui-react'
import moment from "moment";
import {useNavigate, useParams} from "react-router-dom";
import {useGetScheduleQuery, useUpdateScheduleMutation} from "../../lib/api/apiSlice";


export default function ScheduleEdit(props) {
  let {id} = useParams()
  const navigate = useNavigate()

  const {data: schedule = initialSchedule, isLoading, isSuccess, isError, error, refetch} = useGetScheduleQuery(id)
  const [updateSchedule, {isLoading: isLoadingUpdateSchedule}] = useUpdateScheduleMutation()

  const update = async (formData) => {
    try {
      let {...clean_formData} = formData
      clean_formData.manual_working_time = moment.duration(clean_formData.manual_working_time).asSeconds()
      if (clean_formData.manual_working_time == 0){
        clean_formData.manual_working_time = null
      }
      await updateSchedule({id: id, body: clean_formData}).unwrap()
      navigate(`/schedules/${id}`)
    } catch (err) {
      console.log('Failure', err)
    }
  }

  useEffect(() => {
    document.title = 'Wagenumlauf bearbeiten | First Class bus'
  }, [])

  return <Container>
    <Segment>
      <Header as="h5">Wagenumlauf bearbeiten</Header>
      {!isLoading && <ScheduleForm formData={schedule} onSubmit={update}/>}
    </Segment>
  </Container>
}

