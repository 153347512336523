import React, {useEffect} from 'react'
import {Container, Header} from "semantic-ui-react";
import {initialLogging, LoggingForm} from "./LoggingForm";
import {useNavigate, useParams} from "react-router-dom";
import {useGetLoggingQuery, useRemoveLoggingMutation, useUpdateLoggingMutation} from "../../../lib/api/apiSlice";
import moment from "moment";


export default function LoggingEdit(props) {
  let {id} = useParams()
  const navigate = useNavigate()

  const {
    data: logging = initialLogging,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetLoggingQuery(id)

  const [updateServiceLogging, {isLoading: isLoadingUpdateLogging}] = useUpdateLoggingMutation(id)
  const [removeLogging, {isLoading: isLoadingRemoveLogging}] = useRemoveLoggingMutation(id)

  const update = async (formData) => {
    try {

      let {...clean_formData} = formData
      clean_formData.duration = moment.duration(formData.duration).asSeconds()
      clean_formData.daily_allowance = moment.duration(formData.daily_allowance).asSeconds()
      clean_formData.extra_pay = moment.duration(formData.extra_pay).asSeconds()

      await updateServiceLogging({id: id, body: clean_formData}).unwrap()

      const month = moment(formData.day, "YYYY-MM-DD").month() + 1
      const year = moment(formData.day, "YYYY-MM-DD").year()

      navigate(`/loggings/staff/${formData.staff_id}?year=${year}&month=${month}`)
    } catch (err) {
      console.log('Failure', err)
    }
  }
  const remove = async (formData) => {
    if (confirm("Wollen Sie den Zeiteintrag wirklich löschen?")) {
      try {
        await removeLogging({id: id}).unwrap()

        let staff_id = formData.staff_id
        let year = moment(formData.day).year()
        let month = moment(formData.day).month()+1

        navigate(`/loggings/staff/${staff_id}?year=${year}&month=${month}`)
      } catch (err) {
        console.log('Failure', err)
      }
    }
  }

  useEffect(() => {
    document.title = 'Zeiteintrag bearbeiten | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
    <Header as="h5">Zeiteintrag bearbeiten</Header>
    {!isLoading && <LoggingForm formData={logging} onSubmit={update} onRemove={remove}/>}
  </Container>
}
