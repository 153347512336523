import React, {useEffect} from 'react'
import {Container, Header} from "semantic-ui-react";
import {initialVacation, VacationForm} from "./VacationForm";
import {useNavigate, useParams} from "react-router-dom";
import {useGetVacationQuery, useRemoveVacationMutation, useUpdateVacationMutation} from "../../../lib/api/apiSlice";

export default function VacationEdit(props) {
  let {id, staff_id} = useParams()
  const navigate = useNavigate()

  const {
    data: vacation = initialVacation,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetVacationQuery(id)

  const [updateVacation, {isLoading: isLoadingUpdateVacation}] = useUpdateVacationMutation(id)
  const [removeVacation, {isLoading: isLoadingRemoveVacation}] = useRemoveVacationMutation(id)

  const update = async (formData) => {
    try {
      await updateVacation({id: id, body: formData}).unwrap()
      navigate(`/vacations/${staff_id}`)
    } catch (err) {
      console.log('Failure', err)
    }
  }

  const remove = async (formData) => {
    if (confirm("Wollen Sie den Korrektureintrag wirklich löschen?")) {
      try {
        await removeVacation({id: id}).unwrap()
        navigate(`/vacations/${staff_id}`)
      } catch (err) {
        console.log('Failure', err)
      }
    }
  }


  useEffect(() => {
    document.title = 'Urlaubseintrag bearbeiten | First Class bus'
  }, [])
  return <Container style={{marginTop: '1em'}}>
    <Header as="h5">Urlaubskontoeintrag bearbeiten</Header>
    {!isLoading && <VacationForm formData={vacation} onSubmit={update} onRemove={remove}/>}
  </Container>
}
