import React, {useEffect} from 'react'

import {Container, Grid, Header} from 'semantic-ui-react'
import {useParams} from "react-router-dom";
import {useGetPlaceQuery} from "../../../lib/api/apiSlice";
import {Edit} from "../../core/shared/controls";


export default function PlaceDetail(props) {
  let {id} = useParams()

  const {
    data: place = '',
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetPlaceQuery(id)

  useEffect(() => {
    document.title = 'Ort Details | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
    <Grid style={{marginTop: '1em'}} stackable>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2">Ort Details</Header>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right'>
          <Edit/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column  width={8}>
          <p>Matchcode: {place.matchcode}</p>
        </Grid.Column>
        <Grid.Column  width={8}>
          <p>Ort: {place.name}</p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
}

