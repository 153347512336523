import React, {useEffect} from 'react'

//import {CourseForm} from './CourseForm'
import {Container, Header} from 'semantic-ui-react'
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAddNewCourseMutation} from "../../../lib/api/apiSlice";
import {CourseForm} from "./CourseForm";


export default function CourseNew(props) {
  const [searchParams, setSearchParams] = useSearchParams({});
  const navigate = useNavigate()
  const [addNewCourse, {isLoading: isLoadingAddNewCourse}] = useAddNewCourseMutation()

  const create = async (formData) => {
    try {
      //clean up formdata remove selects (see Mozilla Reference Operators/Destructuring_assignment)
      let {
        buses_select,
        lines_select,
        schedules_select,
        coursetypes_select,
        places_select,
        trafficdaycodes_select,
        schedule,
        ...clean_formData
      } = formData
      await addNewCourse(clean_formData).unwrap()
      if (searchParams.get("schedule_id") != undefined) {
        navigate(`/schedules/${searchParams.get("schedule_id")}`)
      } else if (searchParams.get("line_id") != undefined) {
        navigate(`/lines/${searchParams.get("line_id")}`)
      } else {
        navigate('/courses')
      }
    } catch (err) {
      console.log('Failure', err)
    }
  }

  useEffect(() => {
    document.title = 'Kurs anlegen | First Class bus'
  }, [])


  return <Container style={{marginTop: '1em'}}>
    <Header as="h5">Neuen Kurs anlegen</Header>
    <CourseForm onSubmit={create}/>
  </Container>
}

