import React, {useState} from 'react'
import {Button, Container, Form, Grid, Header, Icon, Loader, Table} from "semantic-ui-react";
import {Link} from 'react-router-dom';
import moment from "moment";
import FormatedReadableSeconds from "../../core/shared/FormatedReadableSeconds";
import {useGetLoggingsOverviewQuery} from "../../../lib/api/apiSlice";
import ApiPDF from "../../../lib/api/apiPDF"
import {PeriodNavigation} from "../../core/shared/PeriodNavigation";
import {iso8601ToLocaleDateString} from "../../../lib/time/Time";

export default function Loggings(props) {

  const [formData, setFormData] = useState({
    month: moment().month() + 1,
    year: moment().year(),
  })

  const change = (e, data) => {
    setFormData((prevState => ({...prevState, [e.target.name]: e.target.value})))
  }

  const {
    data: service_loggings = {previous_overtime_date: '', entries: []},
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetLoggingsOverviewQuery({year: formData.year, month: formData.month})


  const previousMonth = () => {
    const newMonth = moment([formData.year, formData.month - 1]).subtract(1, 'month').month() + 1
    const newYear = moment([formData.year, formData.month - 1]).subtract(1, 'month').year()
    setFormData({year: newYear, month: newMonth})
  }
  const currentMonth = () => {
    const newMonth = moment().month() + 1
    const newYear = moment().year()
    setFormData({year: newYear, month: newMonth})
    refetch()
  }
  const nextMonth = () => {
    const newMonth = moment([formData.year, formData.month - 1]).add(1, 'month').month() + 1
    const newYear = moment([formData.year, formData.month - 1]).add(1, 'month').year()
    setFormData({year: newYear, month: newMonth})
    refetch()
  }


  const pdf_url = `${API_URL}/v1/admin/fcb/pdf/loggings/${formData.year}/${formData.month}`
  const filename = `Zeiterfassung-Monatsliste-${formData.year}-${formData.month}.pdf`

 console.log("isFetching", isFetching)
    return <Container style={{marginTop: '1em'}}>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={10}>
            <Header as="h2">Zeiterfassung</Header>
          </Grid.Column>
          <Grid.Column width={6} textAlign={"right"}>
            <Button onClick={() => ApiPDF.fetch_pdf(pdf_url, filename)}>
              <Icon name="file pdf"/>Monatsliste
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <Form>
              <Form.Group>
                <Form.Input name='month' label='Monat' value={formData.month} onChange={change}/>
                <Form.Input name='year' label='Jahr' value={formData.year} onChange={change}/>
                <Form.Button label='&nbsp;' onClick={refetch} basic icon labelPosition='left'>
                  <Icon name='refresh'/>
                  Aktualisieren
                </Form.Button>
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column width={8} textAlign='right'>
            <PeriodNavigation previousPeriod={previousMonth}
                              currentPeriod={currentMonth}
                              nextPeriod={nextMonth}
                              title={'Aktueller Monat'}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {isFetching && <Loader active inline='centered' />}

      <Table fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Mitarbeiter</Table.HeaderCell>
            <Table.HeaderCell>Dauer</Table.HeaderCell>
            <Table.HeaderCell>Diäten</Table.HeaderCell>
            <Table.HeaderCell>Aufschläge</Table.HeaderCell>
            <Table.HeaderCell>Soll</Table.HeaderCell>
            <Table.HeaderCell>Überstunden</Table.HeaderCell>
            <Table.HeaderCell>Saldo {iso8601ToLocaleDateString(service_loggings.previous_overtime_date)}</Table.HeaderCell>
            <Table.HeaderCell>akkumuliert</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {service_loggings.entries.map(logging => <Table.Row key={logging.staff.id}>
              <Table.Cell>
                <Link to={`/loggings/staff/${logging.staff.id}?year=${formData.year}&month=${formData.month}`}>
                  {logging.staff.name}
                </Link>
              </Table.Cell>
              <Table.Cell>{<FormatedReadableSeconds seconds={logging.attendance_time}/>}</Table.Cell>
              <Table.Cell>{<FormatedReadableSeconds seconds={logging.daily_allowance}/>}</Table.Cell>
              <Table.Cell>{<FormatedReadableSeconds seconds={logging.extra_pay}/>}</Table.Cell>
              <Table.Cell>{<FormatedReadableSeconds seconds={logging.regular_working_time_month}/>}</Table.Cell>
              <Table.Cell>{<FormatedReadableSeconds seconds={logging.overtime}/>}</Table.Cell>
              <Table.Cell>{<FormatedReadableSeconds seconds={logging.previous_overtime}/>}</Table.Cell>
              <Table.Cell>{<FormatedReadableSeconds seconds={logging.new_overtime}/>}</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Container>
}
