import React from 'react'

import {Header, Table} from "semantic-ui-react";
import {Link} from 'react-router-dom';


export default function BusesShortTable(props) {
  const {buses, header} = props
  return <div>
    <Header as='h5'>{header}</Header>
    <Table compact unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Kennzeichen</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {buses && buses.map(bus => <Table.Row key={bus.id}>
            <Table.Cell>
              <Link to={"/buses/" + bus.id}>{bus.name}</Link>
            </Table.Cell>
            <Table.Cell>
              <Link to={"/buses/" + bus.id}>{bus.license_plate}</Link>
            </Table.Cell>
          </Table.Row>
        )}
        {buses && buses.length === 0 && <Table.Row>
          <Table.Cell colSpan={2}><span>keine Busse zugeordnet</span></Table.Cell>
        </Table.Row>
        }
      </Table.Body>
    </Table>
  </div>
}
