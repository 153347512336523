import React, {useEffect, useState} from 'react'
import {Container, Form, Grid, Header, Table} from "semantic-ui-react";
import {Link} from 'react-router-dom';
import {useGetStaffsQuery} from "../../../lib/api/apiSlice";
import {New} from "../../core/shared/controls";
import FormatedCrew from "./FormatedCrew";

export default function Staffs(props) {

  const staffsInitialState = {
    show_archived: false
  }

  const [formData, setFormData] = useState({
    ...staffsInitialState
  })

  const {
    data: staffs = [],
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetStaffsQuery(formData.show_archived)

  const changeInactive = (e, data) => {
    setFormData((prevState => ({...prevState, show_archived: data.checked})))
    refetch()
  }

  useEffect(() => {
    document.title = 'Mitarbeiter | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
    <Header as="h2">Mitarbeiter</Header>
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={10}>
          <New/>
        </Grid.Column>
        <Grid.Column width={6}>
          <Form.Checkbox label="Nur archivierte Mitarbeiter anzeigen"
                         name="show_archived" checked={formData.show_archived}
                         onChange={changeInactive}/>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <div className='vertical-scrollable'>
      <Table unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Anzeigename</Table.HeaderCell>
            <Table.HeaderCell>Vorname</Table.HeaderCell>
            <Table.HeaderCell>Nachname</Table.HeaderCell>
            <Table.HeaderCell>Personal</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {staffs.map(staff => <Table.Row key={staff.id}>
            <Table.Cell>
              <Link to={`${staff.id}`}>{staff.id}</Link></Table.Cell>
            <Table.Cell>
              <Link to={`${staff.id}`}>{staff.name}</Link>
            </Table.Cell>
            <Table.Cell>{staff.forename}</Table.Cell>
            <Table.Cell>{staff.surename}</Table.Cell>
            <Table.Cell><FormatedCrew crew={staff.crew}/></Table.Cell>
          </Table.Row>)
          }
        </Table.Body>
      </Table>
    </div>
  </Container>
}
