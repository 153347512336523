import React from 'react'


export default function FormatedMonth(props) {

  const {month} = props
  let german_month = ''

  switch (month) {
    case '1':
      german_month = 'Jänner'
      break;
    case '2':
      german_month = 'Februar'
      break;
    case '3':
      german_month = 'März'
      break;
    case '4':
      german_month = 'April'
      break;
    case '5':
      german_month = 'Mai'
      break;
    case '6':
      german_month = 'Juni'
      break;
    case '7':
      german_month = 'Juli'
      break;
    case '8':
      german_month = 'August'
      break;
    case '9':
      german_month = 'September'
      break;
    case '10':
      german_month = 'Oktober'
      break;
    case '11':
      german_month = 'November'
      break;
    case '12':
      german_month = 'Dezember'
      break;
    default:
      german_month = 'error'
      break;
  }

  return <span>
      {german_month}
   </span>

}
