import React, {useEffect} from 'react'

import {Container, Header, Table} from 'semantic-ui-react'
import TrafficdaycodeLabel from "../../core/shared/trafficdaycode/TrafficdaycodeLabel"
import FormatedBool from "../../core/shared/FormatedBool";
import {useGetTrafficdaycodesQuery} from "../../../lib/api/apiSlice";


export default function Trafficdaycode(props) {
  const {
  data: trafficdaycodes = [],
  isLoading,
  isSuccess,
  isError,
  error,
  refetch
} = useGetTrafficdaycodesQuery()

  useEffect(() => {
    document.title = 'Verkehrstageschlüssel | First Class bus'
  }, [])

      return <Container style={{marginTop: '1em'}}>
          <Header as="h2">Verkehrstagesschlüssel</Header>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Code</Table.HeaderCell>
                <Table.HeaderCell>Standardtage</Table.HeaderCell>
                <Table.HeaderCell>Text</Table.HeaderCell>
                <Table.HeaderCell>Mo</Table.HeaderCell>
                <Table.HeaderCell>Di</Table.HeaderCell>
                <Table.HeaderCell>Mi</Table.HeaderCell>
                <Table.HeaderCell>Do</Table.HeaderCell>
                <Table.HeaderCell>Fr</Table.HeaderCell>
                <Table.HeaderCell>Sa</Table.HeaderCell>
                <Table.HeaderCell>So</Table.HeaderCell>
                <Table.HeaderCell>wenn Schultag</Table.HeaderCell>
                <Table.HeaderCell>Feiertag</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {trafficdaycodes.map(tdc => <Table.Row key={tdc.id}>
                <Table.Cell>{tdc.id}</Table.Cell>
                <Table.Cell><TrafficdaycodeLabel content={tdc.code}/></Table.Cell>
                <Table.Cell>{tdc.standard_days}</Table.Cell>
                <Table.Cell>{tdc.text}</Table.Cell>
                <Table.Cell><FormatedBool bool={tdc.monday}/></Table.Cell>
                <Table.Cell><FormatedBool bool={tdc.tuesday}/></Table.Cell>
                <Table.Cell><FormatedBool bool={tdc.wednesday}/></Table.Cell>
                <Table.Cell><FormatedBool bool={tdc.thursday}/></Table.Cell>
                <Table.Cell><FormatedBool bool={tdc.friday}/></Table.Cell>
                <Table.Cell><FormatedBool bool={tdc.saturday}/></Table.Cell>
                <Table.Cell><FormatedBool bool={tdc.sunday}/></Table.Cell>
                <Table.Cell><FormatedBool bool={tdc.school_day}/></Table.Cell>
                <Table.Cell><FormatedBool bool={tdc.public_holiday}/></Table.Cell>
              </Table.Row>)}
            </Table.Body>
          </Table>
      </Container>
}

