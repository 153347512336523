import React from "react"

import {Button, Icon} from "semantic-ui-react"
import {Link} from "react-router-dom";

export function Edit(props){
 return <Button as={Link} to={props.to ? props.to : 'edit'} icon={props.icon}>
   {props.icon ? <Icon name='edit'/> : 'Bearbeiten'}
 </Button>
}
