import React, {useState} from 'react'
import {Link, useNavigate} from "react-router-dom"
import {Media} from "../../AppMedia";
import {Icon, Menu, Sidebar} from "semantic-ui-react";
import FCBusLogo from "./shared/FCBusLogo";
import {createServiceSettings} from "../../lib/localStorage/serviceSettings";


function Navigation(props) {

  const [visible, setVisible] = useState(false);

  const navigate = useNavigate()

  const handleSidebarHide = () => {
    setVisible(false)
  }

  const menuClick = (path) => {
    handleSidebarHide()
    navigate(path)
  }
  const logout = () => {
    sessionStorage.removeItem('auth')
    navigate('/login')
    handleSidebarHide()
  }

  const isLoggedIn = () => {
    return (sessionStorage.auth && sessionStorage.auth.length > 0)
  }

  const handleToggle = () => {
    setVisible(!visible)
  }

  const handlePusher = () => {
    if (visible)
      setVisible(false)
  }

  //const background = "linear-gradient(to left, rgb(13, 71, 161), rgb(126, 190, 237))"
  //const background = "rgb(13, 71, 161)"
  //const background = "#007BFF"

  const childs_logged_in = [
    <Menu.Item key={"home"} onClick={() => menuClick("/")}>
      <FCBusLogo/>
    </Menu.Item>,
    <Menu.Item key={"dashboard"} onClick={() => menuClick("/")}>
      Dashboard
    </Menu.Item>,
    <Menu.Item key={"bus"} className='no-print'>
      Bus
      <Menu.Menu>
        <Menu.Item key={"buses"} onClick={() => menuClick("/buses")}>Busse</Menu.Item>
        <Menu.Item key={"tags"} onClick={() => menuClick("/tags")}>Labels</Menu.Item>
        <Menu.Item key={"constructions"} onClick={() => menuClick("/constructions")}>Fahrzeugtyp</Menu.Item>
        <Menu.Item key={"fuels"} onClick={() => menuClick("/fuels")}>Kraftstoff</Menu.Item>
        <Menu.Item key={"emissions"} onClick={() => menuClick("/emissions")}>Abgasnorm</Menu.Item>
      </Menu.Menu>
    </Menu.Item>,
    <Menu.Item key={"schedule"} className='no-print'>
      Wagenumlauf
      <Menu.Menu>
        <Menu.Item key={"schedules"} onClick={() => menuClick("/schedules")}>Wagenumlauf</Menu.Item>
        <Menu.Item key={"simulation"} onClick={() => menuClick("/simulation")}>Planung</Menu.Item>
        <Menu.Item key={"lines"} onClick={() => menuClick("/lines")}>Linien</Menu.Item>
        <Menu.Item key={"courses"} onClick={() => menuClick("/courses")}>Kurse</Menu.Item>
        <Menu.Item key={"coursetypes"} onClick={() => menuClick("/coursetypes")}>Kurstypen</Menu.Item>
        <Menu.Item key={"trafficdaycodes"}
          onClick={() => menuClick("/trafficdaycodes")}>VTS</Menu.Item>
        <Menu.Item key={"places"} onClick={() => menuClick("/places")}>Orte</Menu.Item>
      </Menu.Menu>
    </Menu.Item>,
    <Menu.Item key={"jobs"} className='no-print'>
      Fahraufträge
      <Menu.Menu>
        <Menu.Item key={"events"} onClick={() => menuClick("/events")}>Fahraufträge</Menu.Item>
        <Menu.Item key={"planer"} onClick={() => menuClick("/planer")}>Kalender</Menu.Item>
      </Menu.Menu>
    </Menu.Item>,
    <Menu.Item key={"service"} className='no-print'>
      Dienst
      <Menu.Menu>
        <Menu.Item key={"services"} onClick={() => menuClick("/services")}>Dienstplan</Menu.Item>
        <Menu.Item key={"staffs"} onClick={() => menuClick("/staffs")}>Mitarbeiter</Menu.Item>
        <Menu.Item key={"loggings"} onClick={() => menuClick("/loggings")}>Zeiterfassung</Menu.Item>
        <Menu.Item key={"overtimes"} onClick={() => menuClick("/overtimes")}>Überstunden</Menu.Item>
        <Menu.Item key={"vacations"} onClick={() => menuClick("/vacations")}>Urlaub</Menu.Item>
        <Menu.Item key={"specialvacations"} onClick={() => menuClick("/specialvacations")}>Dienstverhinderung</Menu.Item>
        <Menu.Item key={"presettings"} onClick={() => menuClick("/presettings")}>Tätigkeit</Menu.Item>
      </Menu.Menu>
    </Menu.Item>,
    <Menu.Item key={"setting"}>
      Einstellungen
      <Menu.Menu>
        <Menu.Item key={"settings"} onClick={() => menuClick("/settings")}>Unternehmensprofil</Menu.Item>
        <Menu.Item key={"countries"} onClick={() => menuClick("/countries")}>Staaten</Menu.Item>
        <Menu.Item key={"states"} onClick={() => menuClick("/states")}>Länder</Menu.Item>
        <Menu.Item key={"schoolholidays"} onClick={() => menuClick("/schoolholidays")}>Schulfreie Tage</Menu.Item>
        <Menu.Item key={"publicholidays"} onClick={() => menuClick("/publicholidays")}>Feiertage</Menu.Item>
      </Menu.Menu>
    </Menu.Item>,
  ]

  const childs_public = [<Menu.Item key={"home"} onClick={() => menuClick("/")}>
    <FCBusLogo/>
  </Menu.Item>,
    <Menu.Item key={"login"} onClick={() => menuClick("/login")}>
      Login
    </Menu.Item>,
    <Menu.Item key={"about"} onClick={() => menuClick("/about")}>
      Impressum
    </Menu.Item>
  ]
  return <div className="no-print">
    <Media at="mobile">
      <Menu fixed="top" inverted>
        <Menu.Item key={"mobile-sidebar"} onClick={handleToggle}>
          <Icon name="sidebar"/>
        </Menu.Item>
        <Menu.Item key={"mobile-home"}>
          <Link to='/'><FCBusLogo/></Link>
        </Menu.Item>
        {isLoggedIn() && <Menu.Menu position='right'>
          <Menu.Item key={"help"} onClick={() => menuClick("/help")} position='right' title="Hilfe">
            <Icon name="help"/>
          </Menu.Item>
          <Menu.Item key={"sign-out"} onClick={logout} position='right' title='Abmelden'>
            <Icon name="sign out"/>
          </Menu.Item>
        </Menu.Menu>
        }
      </Menu>
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        inverted
        vertical
        visible={visible}
        onHide={() => setVisible(false)}
        style={{width: '200px'}}
      >
        {isLoggedIn() == true ? childs_logged_in : childs_public}
      </Sidebar>
    </Media>
    <Media greaterThan="mobile">
      <Menu fixed='left' vertical inverted style={{flex: 1, overflowY: 'scroll'}}>
        {isLoggedIn() == true ? childs_logged_in : childs_public}
        {isLoggedIn() == true && [<Menu.Item key={"help"} onClick={() => menuClick("/help")} position='right'>
          Hilfe
          <Icon name="help"/>
        </Menu.Item>,
          <Menu.Item key={"sign out"} onClick={logout} position='right'>
            Abmelden
            <Icon name="sign out"/>
          </Menu.Item>
        ]}

      </Menu>
    </Media>
  </div>
}

export default Navigation
