import React from 'react'
import moment from "moment";


export default function FormatedDateBus(props) {

  const {date} = props

  let formatedDate

  if (date == null) {
    formatedDate = ""
  } else {
    const js_date = new Date(date)
    formatedDate = moment(js_date).format('DD.MM.YYYY')
  }

  return <span>
      {formatedDate}
   </span>

}
