import React from 'react'


export default function FormatedGender(props) {

  const {gender} = props
  let german_gender = ''

  switch (gender) {
    case 'male':
      german_gender = 'männlich'
      break;
    case 'female':
      german_gender = 'weiblich'
      break;
    case 'diverse':
      german_gender = 'divers'
      break;
    default:
      german_gender = ''
      break;
  }

  return <span>
      {german_gender}
   </span>

}
