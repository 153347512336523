import React from 'react'

const FCBusLogoInline = () => (
    <span>
      <span style={{color:"#F0383C"}}>FC</span>
      <span style={{color:"#246AAA"}}>BUS</span>
    </span>
)

export default FCBusLogoInline
