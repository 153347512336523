import React from "react";

export default function OvertimeType(props) {
  const {overtime} = props

  console.log("ot: ", overtime)

  let content = []

  if(overtime.is_increase){
    content.push(<span style={{marginRight: "0.5em"}}>Zubuchung</span>)
  }
  if(overtime.is_decrease){
    content.push(<span style={{marginRight: "0.5em"}}>Abbuchung</span>)
  }
  if(overtime.is_pay_out){
    content.push(<span style={{marginRight: "0.5em"}}>Auszahlung</span>)
  }

  return content
}
