import React from 'react'

import {Icon} from "semantic-ui-react";
import FormatedDuration from "../core/shared/FormatedDuration";

const TimeWithAlert = (props) => (
  <div>
    <FormatedDuration sec={props.sec}/>
    {props.sec > props.warning && <Icon name="warning sign" size='large' color="yellow"/>}
  </div>
)


export default TimeWithAlert
