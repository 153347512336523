// returns hh:mm from seconds e.g. 03:15
export function hhmm(seconds) {

  function construct_time_string(sec) {
    var minutes = Math.floor(sec / 60);
    var hours = Math.floor(minutes / 60)
    minutes = minutes % 60;
    return `${pad(hours)}:${pad(minutes)}`;
  }

  if (seconds < 0) {
    return `-${construct_time_string(Math.abs(seconds))}`;
  } else {
    return construct_time_string(seconds);
  }
}

export function hhmmss(seconds) {
  var minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  var hours = Math.floor(minutes / 60)
  minutes = minutes % 60;
  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
}

// timeString can be a string 173:00 or and number. if it is a number then it is hours
export function hhmmAsSeconds(timeString) {
  if (typeof timeString == "string") {
    if (timeString.includes(":")) {
      const hours = extractHours(timeString)
      const minutes = extractMinutes(timeString)
      const seconds = hours * 60 * 60 + minutes * 60
      return seconds
    } else {
      const hours = parseInt(timeString, 10)
      const seconds = hours * 60 * 60
      return seconds
    }
  } else if (typeof timeString == "number") { // timeString is hours
    const seconds = timeString * 60 * 60
    return seconds
  } else {
    return null
  }

}

export function lastDayOfPreviousMonth() {
  const today = new Date();
  const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const lastDay = new Date(firstDayOfCurrentMonth);
  lastDay.setDate(0);

  return lastDay.toLocaleDateString('de-DE');
}

function extractHours(timeString) {
  const [hoursStr, minutesStr] = timeString.split(':');

  const hours = parseInt(hoursStr, 10);

  return hours;
}

function extractMinutes(timeString) {
  const [hoursStr, minutesStr] = timeString.split(':');

  const minutes = parseInt(minutesStr, 10);

  return minutes;
}

export function iso8601ToLocaleDateString(isoDateString){
  const isoDate = new Date(isoDateString);

  const formattedDate = isoDate.toLocaleDateString('de-DE');
  return formattedDate
}

function pad(num) {
  let retval;
  if (num < 10) {
    retval = ("0" + num);
  } else {
    retval = num
  }
  return retval
}
