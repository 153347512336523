import React, {useEffect} from 'react'

import {Container, Grid, Header} from 'semantic-ui-react'
import BusesShortTable from "../BusesShortTable";
import {useParams} from "react-router-dom";
import {useGetEmissionQuery} from "../../../lib/api/apiSlice";
import {Edit} from "../../core/shared/controls";

export default function EmissionDetail(props) {
  let {id} = useParams()
  const {
    data: emission = {},
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetEmissionQuery(id)

  useEffect(() => {
    document.title = 'Abgasnorm | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
    <Grid style={{marginTop: '1em'}} stackable>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2">Kraftstoff Stammdaten</Header>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right'>
          <Edit/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row width={1}>
        <Grid.Column>
          <p>Name: {emission.name}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <BusesShortTable header="Busse mit dieser Abgasnorm" buses={emission.buses}/>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>

}


