import React, {useEffect} from 'react'
import {Container, Header} from "semantic-ui-react";
import {useNavigate, useParams} from "react-router-dom";
import {hhmmAsSeconds} from "../../../lib/time/Time";
import {OvertimeForm} from "./OvertimeForm";
import {useAddNewOvertimeMutation} from "../../../lib/api/apiSlice";

export default function OvertimeNew(props) {
  let {staff_id} = useParams()
  const navigate = useNavigate()

  const [addNewOvertime, {isLoading: isLoadingAddNewOvertime}] = useAddNewOvertimeMutation(staff_id)

  const create = async (formData) => {
    try{
      let {...clean_formData} = formData
      clean_formData.amount = hhmmAsSeconds(formData.amount)

      let customized_formData = {...clean_formData, staff_id: parseInt(staff_id)}

      await addNewOvertime(customized_formData).unwrap()
      navigate(`/overtimes/${staff_id}`)
    } catch(err){
      console.log('Failure', err)
    }
  }


  useEffect(() => {
    document.title = 'Überstundeneintrag anlegen | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
        <Header as="h5">Neuer Überstundeneintrag</Header>
        <OvertimeForm onSubmit={create}/>
    </Container>
}
