import React, {useEffect} from 'react'
import {Container, Header} from "semantic-ui-react";
import {useNavigate} from "react-router-dom";
import {useAddNewSchoolHolidayMutation} from "../../../lib/api/apiSlice";
import {SchoolHolidayForm} from "./SchoolHolidayForm";

export default function SchoolHolidayNew(props) {
  const navigate = useNavigate()
  const [addNewSchoolHoliday] = useAddNewSchoolHolidayMutation()

  const create = async (formData) => {
    try{
      let {state,
        country,
        ...clean_formData} = formData
      await addNewSchoolHoliday(clean_formData).unwrap()
      navigate('/schoolholidays')
    } catch(err){
      console.log('Failure', err)
    }
  }
  useEffect(() => {
    document.title = 'Schulfreien Tag anlegen | First Class bus'
  }, [])
    return <Container style={{marginTop: '1em'}}>
        <Header as="h2">Schulfreien Tag anlegen</Header>
        <SchoolHolidayForm onSubmit={create}/>
    </Container>
}
