import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {Dropdown, Form, Grid, Segment} from "semantic-ui-react";
import {
  useGetPresettingsQuery,
  useGetPresettingsSelectQuery,
  useGetSchedulesSelectQuery
} from "../../../lib/api/apiSlice";
import {Cancel, Remove, Submit} from "../../core/shared/controls";

export const initialLogging = {
  id: '',
  staff_id: '',
  day: moment().format('YYYY-MM-DD'),
  duration: '',
  daily_allowance: '',
  extra_pay: '',
  presetting_id: '',
  note: ''
}

export function LoggingForm(props) {
  const [formData, setFormData] = useState({
    ...initialLogging
  })

  const {data: schedules_select = []} = useGetSchedulesSelectQuery()
  const {data: presettings_select = []} = useGetPresettingsSelectQuery({show_in_logging: true})
  const {data: presettings = []} = useGetPresettingsQuery({show_in_logging: true})

  const change = (e, data) => {
    setFormData((prevState => ({...prevState, [e.target.name]: e.target.value})))
  }

  const changeDropDownPresetting = (e, data) => {
    if (data.value != "") {
      const presetting_id = data.value
      const presetting = presettings.find(element => element.id === parseInt(presetting_id))
      const duration = moment.utc(presetting.auto_logging_duration * 1000).format('HH:mm')
      const daily_allowance = moment.utc(presetting.daily_allowance * 1000).format('HH:mm')
      const extra_pay = moment.utc(presetting.extra_pay * 1000).format('HH:mm')


      setFormData((prevState => (
        {
          ...prevState, presetting_id: presetting_id,
          duration: duration, daily_allowance: daily_allowance, extra_pay: extra_pay
        }
      )))
    } else {
      setFormData((prevState => (
        {
          ...prevState, presetting_id: '',
          duration: '', daily_allowance: '', extra_pay: ''
        }
      )))
    }
  }

  useEffect(() => {
    if (props.formData != undefined)
      setFormData(props.formData)
  }, [])


  return <Form>
    <Segment>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Input name='day' label='Datum' value={formData.day}
                      onChange={change} type="date" required/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Form.Field>
            <label>Tätigkeit</label>
            <Dropdown placeholder="" name='presetting_id' value={formData.presetting_id}
                      onChange={changeDropDownPresetting} fluid search selection clearable
                      options={presettings_select}/>
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Form.Input label="Dauer" placeholder="hh:mm" name='duration'
                      value={formData.duration} onChange={change}
                      type="time"/>
        </Grid.Column>
        <Grid.Column>
          <Form.Input label="Diäten" placeholder="hh:mm" name='daily_allowance'
                      value={formData.daily_allowance} onChange={change}
                      type="time"/>
        </Grid.Column>
        <Grid.Column>
          <Form.Input label="Aufschläge" placeholder="hh:mm" name='extra_pay'
                      value={formData.extra_pay} onChange={change}
                      type="time"/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Form.Input label="Notiz" placeholder="" name='note' value={formData.note} onChange={change}/>
        </Grid.Column>
      </Grid.Row>
    </Segment>
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Submit onClick={() => props.onSubmit(formData)}/>
          <Cancel/>
          {formData.id && <Remove onClick={() => props.onRemove(formData)}/>}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Form>
}
