import React, {useEffect} from 'react'

import {Container, Header} from 'semantic-ui-react'
import {useNavigate} from "react-router-dom";
import {useAddNewPlaceMutation} from "../../../lib/api/apiSlice";
import PlaceForm from "./PlaceForm";

export default function PlaceNew(props) {
  const navigate = useNavigate()
  const [addNewPlace, {isLoading: isLoadingAddPlace}] = useAddNewPlaceMutation()

  const create = async (formData) => {
    try {
      await addNewPlace(formData).unwrap()
      navigate('/places')
    } catch (err) {
      console.log('Failure', err)
    }
  }
  useEffect(() => {
    document.title = 'Ort erstellen | First Class bus'
  }, [])
  return <Container style={{marginTop: '1em'}}>
    <Header as="h5">Neuen Ort erstellen</Header>
    <PlaceForm onSubmit={create}/>
  </Container>
}

