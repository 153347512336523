import React, {useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'

import {Button, Container, Divider, Grid, Header, Icon, List} from 'semantic-ui-react'
import FormatedDateBus from "../core/shared/FormatedDateBus";
import FormatedBool from "../core/shared/FormatedBool";
import TagLabel from "./tag/TagLabel";
import {useGetBusQuery, useUpdateBusMutation} from "../../lib/api/apiSlice";

export default function BusDetail(props) {
  let {id} = useParams()

  const {
    data: bus = '',
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetBusQuery(id)
  const [updateBus, {isLoading: isLoadingUpdateBus}] = useUpdateBusMutation()

  useEffect(() => {
    document.title = 'Bus Details | First Class bus'
  }, [])

  const onActiveBusClicked = async (active) => {
    let newBus = {...bus}
    try {
      newBus.active = active
      await updateBus({id: id, body: newBus}).unwrap()
    } catch (err){
      console.error("Failed to activate bus")
    }
  }

  return <Container style={{marginTop: '1em'}}>
    <Grid style={{marginTop: '1em'}} stackable>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2">Bus Stammdaten</Header>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right'>
          {bus.active ? <Button basic color='red' floated='right' onClick={() => onActiveBusClicked(false)}>
              Als inaktiv markieren
            </Button>
            :
            <Button basic color='green' floated='right' onClick={() => onActiveBusClicked(true)}>
              Als aktiv markieren
            </Button>
          }
          <Button as={Link} to={`edit`}>
            Bearbeiten
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <Icon name='bus' size='huge' style={{color: `${bus.color}`}}/>
              <List.Content>
                <List.Header>{bus.name}</List.Header>
                <List.Description>
                  Hersteller: {bus.manufacturer}<br/>
                  Type: {bus.manufacturer_type}<br/>
                  Kennzeichen: {bus.license_plate}<br/>
                  Fahrzeugfarbe: {bus.vehicle_color}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Termine</List.Header>
                <List.Description>
                  Begutachtung: <FormatedDateBus date={bus.safety_check}/><br/>
                  Baujahr: <FormatedDateBus date={bus.date_of_construction}/><br/>
                  Erstzulassung: <FormatedDateBus date={bus.date_of_initial_registration}/><br/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={4}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Motor</List.Header>
                <List.Description>
                  Kraftstoff: {bus.fuel_id && <Link to={`/fuel/detail/${bus.fuel_id}`}>{bus.fuel.name}</Link>}<br/>
                  Abgasnorm: {bus.vehicle_emission_standard_id && <Link to={
                  `/vehicleemissionstandard/detail/${bus.vehicle_emission_standard_id}`}>
                  {bus.vehicle_emission_standard.name}
                </Link>}<br/>
                  Leistung: {bus.power} kW<br/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Technische Details</List.Header>
                <List.Description>
                  Achsen: {bus.axle}<br/>
                  Gesamtgewicht: {bus.total_weight} kg<br/>
                  Eigengewicht: {bus.emtpy_weight} kg<br/>
                  Länge: {bus.length} mm<br/>
                  Breite: {bus.width} mm<br/>
                  Höhe: {bus.height} mm<br/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header></List.Header>
                <List.Description>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={4}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header></List.Header>
                <List.Description>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <strong>Kommentar:</strong><br/>
          {bus.comment}
        </Grid.Column>
      </Grid.Row>
      <Divider horizontal content='Ausstattungsmerkmale'/>
      <Grid.Row>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Fahrzeugkonstruktion</List.Header>
                <List.Description>
                  Fahrzeugtyp: {bus.vehicle_construction_type_id && <Link
                  to={`/constructions/${bus.vehicle_construction_type_id}`}>
                  {bus.vehicle_construction_type.code} - {bus.vehicle_construction_type.name}
                </Link>}<br/>
                  Schneeketten fähig: <FormatedBool bool={bus.skid_chain_able}/> <br/>
                  Anhänger: <FormatedBool bool={bus.trailer}/> <br/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Fahrgastvorteile</List.Header>
                <List.Description>
                  Anzeige innen: {bus.passenger_information_inside}<br/>
                  Anzeige Außen: {bus.passenger_information_outside}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={4}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Passagiere</List.Header>
                <List.Description>
                  Sitzplätze: {bus.seats}<br/>
                  Stehplätze: {bus.standing_room}<br/>
                  Max. Passagiere: {bus.max_passengers}<br/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Innenaustattung</List.Header>
                <List.Description>
                  Sitzreihenabstand: {bus.seat_row_distance} mm<br/>
                  Kopfstütze Messpunkt 1: {bus.neck_rest_width_1} mm<br/>
                  Kopfstütze Messpunkt 2: {bus.neck_rest_width_2} mm<br/>
                  Haltewunschtaster: <FormatedBool bool={bus.stop_request_button}/><br/>
                  Eckhandgriffe: <FormatedBool bool={bus.corner_handles}/><br/>
                  Innenbeleuchtung: <FormatedBool bool={bus.interior_lighting}/><br/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={6}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Zugänglichkeit</List.Header>
                <List.Description>
                  Begindertengerecht: <FormatedBool bool={bus.handicapped_accessible}/><br/>
                  Rollstuhlplatz: <FormatedBool bool={bus.wheelchair_place}/><br/>
                  Hublift oder Rohlstuhlrampe: <FormatedBool bool={bus.lift}/><br/>
                  Kinderwagenplatz: <FormatedBool bool={bus.baby_stroller_place}/><br/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={4}>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>FCBUS Details</List.Header>
                <List.Description>
                  Aktiv: <FormatedBool bool={bus.active}/><br/>
                  Anzeigefarbe: <span><Icon name='bus' style={{color: bus.color}}/>{bus.color}</span><br/>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Divider horizontal content='Labels'/>
      <Grid.Row>
        <Grid.Column width={16}>
          <p><strong>Labels</strong></p>
          {bus.tags && bus.tags.map(tag => <TagLabel key={tag.id} tag={tag}/>)}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>

}


