import React, {useEffect} from 'react'
import {Container, Header} from "semantic-ui-react";
import {initialLogging, LoggingForm} from "./LoggingForm";
import {useNavigate, useParams} from "react-router-dom";
import {useAddNewLoggingMutation} from "../../../lib/api/apiSlice";
import moment from "moment/moment";


export default function LoggingNew(props) {
  let {staff_id} = useParams()
  const navigate = useNavigate()

  const [addNewLogging, {isLoading: isLoadingAddNewLogging}] = useAddNewLoggingMutation()

  const create = async (formData) => {
    try {
      let {...clean_formData} = formData
      clean_formData.duration = moment.duration(formData.duration).asSeconds()
      clean_formData.daily_allowance = moment.duration(formData.daily_allowance).asSeconds()
      clean_formData.extra_pay = moment.duration(formData.extra_pay).asSeconds()


      await addNewLogging(clean_formData).unwrap()

      const month = moment(formData.day, "YYYY-MM-DD").month() + 1
      const year = moment(formData.day, "YYYY-MM-DD").year()

      navigate(`/loggings/staff/${formData.staff_id}?year=${year}&month=${month}`)
    } catch (err) {
      console.log('Failure', err)
    }
  }

  useEffect(() => {
    document.title = 'Meine Zeit erfassen | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
      <Header as="h5">Zeiteintrag erfassen</Header>
      <LoggingForm onSubmit={create} formData={{...initialLogging, staff_id: parseInt(staff_id)}}/>
  </Container>
}
