import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom';

import {Container, Form, Header, Icon, Label, Table} from "semantic-ui-react";
import FormatedTimeOfDayBus from "../../core/shared/FormatedTimeOfDayBus";
import TrafficdaycodeLabel from "../../core/shared/trafficdaycode/TrafficdaycodeLabel";
import {useGetCoursesQuery} from "../../../lib/api/apiSlice";
import {New} from "../../core/shared/controls";


export default function Courses(props) {
  const [coursesData, setCoursesData] = useState({
    filter_value: 'OHNE_FAHRPLAN'
  })

  const filters = [{key: 1, text: "ohne Wagenumlauf", value: "OHNE_FAHRPLAN"},
    {key: 2, text: "mit Wagenumlauf", value: "MIT_FAHRPLAN"},
    {key: 3, text: "alle", value: "ALLE"}]

  const filterChange = (e, data) => {
    setCoursesData((() => ({filter_value: data.value})))
    refetch()
  }

  const {
    data: courses = [],
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetCoursesQuery(coursesData.filter_value)

  useEffect(() => {
    document.title = 'Kurse | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
    <Header size="huge">Kurse</Header>
    <New/>
    <br/>
    <br/>

    <Form>
      <Form.Field>
        <Form.Dropdown label="Filter" placeholder="" name='filter' value={coursesData.filter_value}
                       onChange={filterChange} search selection options={filters}
        />
      </Form.Field>
    </Form>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Wagenumlauf</Table.HeaderCell>
          <Table.HeaderCell>Linie</Table.HeaderCell>
          <Table.HeaderCell>Kurs</Table.HeaderCell>
          <Table.HeaderCell>Abfahrt</Table.HeaderCell>
          <Table.HeaderCell>Abfahrtsort</Table.HeaderCell>
          <Table.HeaderCell>Ankunft</Table.HeaderCell>
          <Table.HeaderCell>Ankunftsort</Table.HeaderCell>
          <Table.HeaderCell>Bus</Table.HeaderCell>
          <Table.HeaderCell>VTS</Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Meter</Table.HeaderCell>
          <Table.HeaderCell>Kommentar</Table.HeaderCell>
          <Table.HeaderCell><Icon name="cogs"/></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {courses.map((course) => <Table.Row key={course.id}>
          <Table.Cell>
            {course.schedule && <Link to={"/schedules/" + course.schedule_id}>{course.schedule.name}</Link>}
          </Table.Cell>
          <Table.Cell><Link to={`/lines/${course.line.id}`}>{course.line.nr}</Link></Table.Cell>
          <Table.Cell><Link to={`${course.id}`}>{course.nr}</Link></Table.Cell>
          <Table.Cell><FormatedTimeOfDayBus time={course.departure}/></Table.Cell>
          <Table.Cell>{course.departure_place.name}</Table.Cell>
          <Table.Cell><FormatedTimeOfDayBus time={course.arrival}/></Table.Cell>
          <Table.Cell>{course.arrival_place.name}</Table.Cell>
          <Table.Cell>{course.bus && course.bus.name}</Table.Cell>
          <Table.Cell className="no-print">
            {course.trafficdaycodes.map(
              (ctdc, index) => <TrafficdaycodeLabel key={index} content={ctdc.code}/>
            )}
          </Table.Cell>
          <Table.Cell>
            {course.course_type &&
              <Label color={course.course_type.color} size="mini">{course.course_type.name}</Label>}
          </Table.Cell>
          <Table.Cell>{course.distance}</Table.Cell>
          <Table.Cell>{course.comment}</Table.Cell>
        </Table.Row>)}
      </Table.Body>
    </Table>
  </Container>
}
