import React from 'react'


export default function FormatedCrew(props) {
  const {crew} = props
  console.log("crew", crew)
  let retVal;
  switch (crew) {
    case 'driving':
      retVal = "Fahrpersonal"
      break;
    case 'operating':
      retVal = 'Betriebspersonal'
      break;
    default:
      retVal = ''
      break;
  }

  return <span>
      {retVal}
   </span>

}
