import React, {useEffect, useState} from 'react'
import {Button, Container, Grid, Header} from "semantic-ui-react";
import moment from "moment";
import ServicesTable from "./ServicesTable";
import {New} from "../core/shared/controls";
import {
  useGetPresettingsSelectForServiceQuery,
  useGetPublicHolidaysQuery,
  useGetSchedulesSelectForServiceQuery,
  useGetSchoolHolidaysQuery,
  useGetServiceStaffArrayQuery,
  useGetStaffsQuery
} from "../../lib/api/apiSlice";
import WeekBrowseControl from "./WeekBrowseControl";
import WeekIterationControl from "./WeekIterationControl";
import {createServiceSettings} from "../../lib/localStorage/serviceSettings";
import {VarButton} from "../core/shared/controls/VarButton";


export const servicesInitialState = {
  isoWeeks: [
    moment().startOf('isoWeek').format('GGGG-[W]WW'),
    moment().startOf('isoWeek').add(1, 'week').format('GGGG-[W]WW'),
    moment().startOf('isoWeek').add(2, 'week').format('GGGG-[W]WW'),
  ],
  plan: new Map(),
  dates: []
}

export default function Services(props) {

  const generateIsoWeeks = (isoWeekStart, iteration) => {
    let isoWeeks = []
    for (var i = 0; i < iteration; i++) {
      isoWeeks.push(moment(isoWeekStart).add(i, 'weeks').format('GGGG-[W]WW'))
    }
    return isoWeeks
  }

  const [formData, setFormData] = useState({
    ...servicesInitialState, isoWeeks: generateIsoWeeks(firstIsoWeek, iteration)
  })
  const {
    data: staffs = [],
  } = useGetStaffsQuery(false)

  const {
    data: service_schedules_select = []
  } = useGetSchedulesSelectForServiceQuery(formData.isoWeeks)

  const {
    data: service_presettings_select = []
  } = useGetPresettingsSelectForServiceQuery()

  const {
    data: service_staff_array = null,
    isLoading
  } = useGetServiceStaffArrayQuery({isoWeeks: formData.isoWeeks, variable_working_time: true})

  const {
    data: public_holidays = []
  } = useGetPublicHolidaysQuery()

  const {
    data: school_holidays = []
  } = useGetSchoolHolidaysQuery()

  const plusIsoWeeks = (isoWeek, amount) => {
    const start = moment(isoWeek).startOf('isoWeek')
    return start.add(amount, 'weeks').format('GGGG-[W]WW')
  }

  function updateFormData(newIsoWeeks) {
    setFormData((prevState => ({...prevState, isoWeeks: newIsoWeeks})))
    createServiceSettings().update(newIsoWeeks)
  }

  const changeIsoWeek = (e) => {
    const {isoWeeks} = formData
    const newIsoWeek = e.target.value
    const iteration = isoWeeks.length
    let newIsoWeeks = generateIsoWeeks(newIsoWeek, iteration)

    updateFormData(newIsoWeeks);
  }

  const previousWeek = () => {
    const {isoWeeks} = formData
    let newIsoWeek = plusIsoWeeks(isoWeeks[0], -1)
    const iteration = isoWeeks.length
    let newIsoWeeks = generateIsoWeeks(newIsoWeek, iteration)

    updateFormData(newIsoWeeks);
  }

  const nextWeek = () => {
    const {isoWeeks} = formData
    let newIsoWeek = plusIsoWeeks(isoWeeks[0], 1)
    const iteration = isoWeeks.length
    let newIsoWeeks = generateIsoWeeks(newIsoWeek, iteration)

    updateFormData(newIsoWeeks);
  }

  const changeIteration = (e) => {
    const {isoWeeks} = formData
    const newIteration = e.target.value

    const isoWeekStart = isoWeeks[0]

    if (newIteration >= 1) {
      let newIsoWeeks = generateIsoWeeks(isoWeekStart, newIteration)
      updateFormData(newIsoWeeks);
    }
  }

  useEffect(() => {
    document.title = 'Dienstplan | First Class bus'

    if (createServiceSettings().get() == null) {
      createServiceSettings().init(servicesInitialState.isoWeeks)
    }
    updateFormData(createServiceSettings().get())

  }, [])

  let firstIsoWeek = formData.isoWeeks[0]
  let iteration = formData.isoWeeks.length

  const currentIsoWeek = moment().startOf('isoWeek').format('GGGG-[W]WW')
  const service_staff_map = new Map(service_staff_array)

  return <Container fluid>
    <Grid style={{marginTop: '1em'}} stackable>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2">Dienstplan Übersicht</Header>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right' className="no-print">
          <VarButton title="Variable Arbeitszeit" to={'variable'}/>
          <New title="Bearbeiten"/>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Grid stackable verticalAlign='middle'>
      <Grid.Row className="no-print">
        <WeekBrowseControl
          previousWeek={previousWeek}
          firstIsoWeek={firstIsoWeek}
          changeIsoWeek={changeIsoWeek}
          nextWeek={nextWeek}
        />
        <WeekIterationControl
          iteration={iteration}
          changeIteration={changeIteration}
        />
        <Grid.Column width={7} textAlign='right'>
          <IsoWeek isoWeek={currentIsoWeek}
                   value={currentIsoWeek}
                   text={'Aktuelle KW'}
                   primary={true}
                   handleClick={changeIsoWeek}/>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <div className='vertical-scrollable'>
      {!isLoading && formData.isoWeeks.map(isoWeek => <ServicesTable key={isoWeek}
                                                                     isoWeek={isoWeek}
                                                                     staffs={staffs}
                                                                     schedules_select={service_schedules_select}
                                                                     presettings_select={service_presettings_select}
                                                                     service_staff_map={service_staff_map}
                                                                     plan={new Map()}
                                                                     public_holidays={public_holidays}
                                                                     school_holidays={school_holidays}/>
      )}
    </div>
  </Container>
}


const IsoWeek = (props) => {
  const {isoWeek, value, handleClick, primary, text} = props
  return <Button basic onClick={handleClick} active={isoWeek === value} primary={primary}
                 value={value}>
    {text}
  </Button>
}
