import React from 'react'

import moment from 'moment'

export default function FormatedTimeOfDayBus(props) {

  const {time} = props
  let time_of_day

  if (time != null) {
    time_of_day = moment.utc(time).format("HH:mm"); //time is time of day, so ignore Timezone Information
  } else {
    time_of_day = ""
  }

  return <span>
        {time_of_day}
   </span>

}
