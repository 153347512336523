import React, {useEffect, useState} from 'react'
import {Form, Grid} from 'semantic-ui-react'
import {Cancel, Submit} from "../../core/shared/controls";


export const initialConstruction = {code:'', name: ''}

export function ConstructionForm(props) {

  const [formData, setFormData] = useState({
    ...initialConstruction
  })

  const change = (e) => {
    setFormData((prevState => ({ ...prevState, [e.target.name]: e.target.value })))
  }

  useEffect(() => {
    if(props.formData != undefined)
      setFormData(props.formData)
  }, [])

  return <Form>
      <Grid style={{marginTop: '1em'}} stackable>
        <Grid.Row width={1}>
          <Grid.Column>
            <Form.Input label='Code' name='code' value={formData.code} onChange={change}/><br/>
            <Form.Input label='Name' name='name' value={formData.name} onChange={change}/><br/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Submit onClick={() => props.onSubmit(formData)}/>
          <Cancel/>
        </Grid.Row>
      </Grid>
    </Form>

}
