import React from 'react'

export default function FormatedBool(props) {

    const {bool} = props
    let retVal;
    switch (bool){
      case true:
        retVal = "ja"
        break;
      case false:
        retVal = 'nein'
        break;
      default:
        retVal = ''
        break;
    }

    return <span>
      {retVal}
   </span>

}
