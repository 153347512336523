import React, {useEffect, useState} from 'react'
import {Container, Form, Grid, Header, Loader, Table} from "semantic-ui-react";
import {Link} from 'react-router-dom';
import {useGetOvertimesQuery} from "../../../lib/api/apiSlice";
import {iso8601ToLocaleDateString} from "../../../lib/time/Time";

export const overtimesInitialState = {
  previous_overtime_date: '',
  entries: [{id: '', name: '', previous_overtime: ''}]
}

export default function Overtimes(props) {

  const formDataInitialState = {
    show_archived: false,
  }
  const [formData, setFormData] = useState({
    ...formDataInitialState
  })

  const {
    data= overtimesInitialState,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetOvertimesQuery(formData.show_archived)

  const changeArchived = (e, data) => {
    setFormData((prevState => ({...prevState, show_archived: data.checked})))
    refetch()
  }

  useEffect(() => {
    document.title = 'Überstunden | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
    <Header as="h2">Überstunden</Header>
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={10}>
        </Grid.Column>
        <Grid.Column width={6}>
          <Form.Checkbox label="Nur archivierte Mitarbeiter anzeigen" name="inactive" checked={formData.show_archived}
                         onChange={changeArchived}/>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <div className='vertical-scrollable'>
      <Table unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Anzeigename</Table.HeaderCell>
            <Table.HeaderCell>Saldo {iso8601ToLocaleDateString(data.previous_overtime_date)}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.entries && data.entries.map(overtime => <Table.Row key={overtime.id}>
            <Table.Cell>
              <Link to={`${overtime.id}`}>{overtime.name}</Link>
            </Table.Cell>
            <Table.Cell>{overtime.previous_overtime}</Table.Cell>
          </Table.Row>)
          }
        </Table.Body>
      </Table>
      {isLoading && <Loader active inline='centered' />}
    </div>
  </Container>
}
