import React, {useEffect} from 'react'
import {Container, Grid, Header} from "semantic-ui-react";
import {useGetSettingsQuery} from "../../lib/api/apiSlice";
import FormatedBool from "../core/shared/FormatedBool";
import {Edit} from "../core/shared/controls";

export default function Settings(props) {

  const {data: settings={}} = useGetSettingsQuery()

  useEffect(() => {
    document.title = 'Einstellungen | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2">Unternehmensprofil</Header>
          <p>Konfigurieren Sie Ihr FCBUS ganz nach Ihren Anforderungen.</p>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right'>
          <Edit/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          Arbeitszeitrunden: <FormatedBool bool={settings.work_hours_round}/>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
}
