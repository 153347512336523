import React from 'react'

import {Container, Header} from 'semantic-ui-react'
import {ConstructionForm} from "./ConstructionForm";
import {useNavigate} from "react-router-dom";
import {useAddNewConstructionMutation} from "../../../lib/api/apiSlice";

export default function ConstructionNew(props) {
  const navigate = useNavigate()
  const [addNewConstruction, {isLoading: isLoadingAddConstruction}] = useAddNewConstructionMutation()

  const create = async (formData) => {
    try{
      await addNewConstruction(formData).unwrap()
      navigate('/constructions')
    } catch(err){
      console.log('Failure', err)
    }
  }

  return <Container style={{marginTop: '1em'}}>
    <Header as="h5">Neuen Fahrzeugtyp erstellen</Header>
    <ConstructionForm onSubmit={create}/>
  </Container>
}

