import React, {useEffect} from 'react'

import {BusForm} from './BusForm'

import {Container, Header} from 'semantic-ui-react'
import {useNavigate} from "react-router-dom";
import {useAddNewBusMutation} from "../../lib/api/apiSlice";

export default function BusNew(props) {
  const navigate = useNavigate()
  const [addNewBus, {isLoading: isLoadingAddNewBus}] = useAddNewBusMutation()

  const create = async (formData) => {
    try{
      //clean up formdata remove selects (see Mozilla Reference Operators/Destructuring_assignment)
      let {fuels_select,
        vehicle_emission_standard_select,
        vehicle_construction_type_select,
        bus_tags_select,
        ...clean_formData} = formData
      await addNewBus(clean_formData).unwrap()
      navigate('/buses')
    } catch(err){
      console.log('Failure', err)
    }
  }
  useEffect(() => {
    document.title = 'Bus anlegen | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
      <Header as="h5">Neuen Bus anlegen</Header>
      <BusForm onSubmit={create}/>
    </Container>
}

