import React from 'react'
import {Label} from "semantic-ui-react";
import ColorHelper from "../../core/shared/color/ColorHelper";
import {useNavigate} from "react-router-dom";


export default function TagLabel(props) {
  console.log("TagLabel:",props)
  const navigate = useNavigate()


    const {tag} = props
    var textColor = ColorHelper.calculateForegroundTextColor(tag.color);
    return <Label key={tag.id} as='a'
                  style={{backgroundColor: tag.color, color:textColor}}
                  onClick={() => navigate(`/tags/${tag.id}`)}
                  >{tag.name}
    </Label>

}
