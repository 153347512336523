import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {Dropdown, Form, Grid, Segment} from "semantic-ui-react";
import {useGetPresettingsVariableWorkingTimeSelectQuery, useGetStaffsSelectQuery} from "../../../lib/api/apiSlice";
import {Cancel, Remove, Submit} from "../../core/shared/controls";

export const initialVariableWorkingTime = {
  id: '',
  staff_id: '',
  day: moment().format("YYYY-MM-DD"),
  presetting_id: '',
  variable_working_time: '',
}

export function VariableWorkingTimeForm(props) {
  const [formData, setFormData] = useState({
    ...initialVariableWorkingTime
  })

  const {data: staffs_select = []} = useGetStaffsSelectQuery()
  const {data: presettings_vara_select = []} = useGetPresettingsVariableWorkingTimeSelectQuery()


  const change = (e, data) => {
    setFormData((prevState => ({...prevState, [e.target.name]: e.target.value})))
  }

  const changeDropdown = (e, data) => {
    setFormData((prevState => ({...prevState, [data.name]: data.value})))
  }

  useEffect(() => {
    if (props.formData != undefined)
      setFormData(props.formData)
  }, [])


  return <Form>
    <Segment>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Input name='day' label='Datum' value={formData.day}
                      onChange={change} type="date" required/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Form.Field>
            <label>Mitarbeiter</label>
            <Dropdown placeholder="" name='staff_id' value={formData.staff_id}
                      onChange={changeDropdown} fluid search selection clearable
                      options={staffs_select}/>
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Form.Field>
            <label>Tätigkeit</label>
            <Dropdown placeholder="" name='presetting_id' value={formData.presetting_id}
                      onChange={changeDropdown} fluid search selection clearable
                      options={presettings_vara_select}/>
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Form.Input label="Variable Arbeitszeit" placeholder="Std:Min" name='variable_working_time'
                      value={formData.variable_working_time} onChange={change}
                      />
        </Grid.Column>
      </Grid.Row>
    </Segment>
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Submit onClick={() => props.onSubmit(formData)}/>
          <Cancel/>
          {formData.id && <Remove onClick={() => props.onRemove(formData)}/>}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Form>
}
