import React from 'react'

import {Container, Header} from 'semantic-ui-react'
import {EmissionForm} from "./EmissionForm";
import {useNavigate} from "react-router-dom";
import {useAddNewEmissionMutation} from "../../../lib/api/apiSlice";

export default function EmissionNew(props) {
  const navigate = useNavigate()
  const [addNewEmission, {isLoading: isLoadingAddVehicleemissionstandard}] = useAddNewEmissionMutation()

  const create = async (formData) => {
    try{
      await addNewEmission(formData).unwrap()
      navigate('/emissions')
    } catch(err){
      console.log('Failure', err)
    }
  }
    return <Container style={{marginTop: '1em'}}>
      <Header as="h5">Neue Abgasnorm erstellen</Header>
      <EmissionForm onSubmit={create}/>
    </Container>
}

