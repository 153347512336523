import React, {useEffect} from 'react'

import {Container, Grid, Header} from 'semantic-ui-react'
import {useGetPublicHolidayQuery} from "../../../lib/api/apiSlice";
import {useParams} from "react-router-dom";
import {Edit} from "../../core/shared/controls";


export default function PublicHolidayDetail(props) {
  let {id} = useParams()

  const {data: public_holiday = {}} = useGetPublicHolidayQuery(id)

  useEffect(() => {
    document.title = 'Feiertag Details | First Class bus'
  }, [])


  return <Container style={{marginTop: '1em'}}>
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2">Feiertagdetails</Header>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right'>
          <Edit/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4}>
          {public_holiday.day}
        </Grid.Column>
        <Grid.Column width={4}>
          {public_holiday.name}
        </Grid.Column>
        <Grid.Column width={4}>
          Staat: {public_holiday.country && public_holiday.country.name}
        </Grid.Column>
        <Grid.Column width={4}>
          Land: {public_holiday.state && public_holiday.state.name}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
}

