import React, {useEffect} from 'react'

import {Container, Grid, Header, Table} from 'semantic-ui-react'
import TagLabel from "./TagLabel";
import {useGetTagsQuery} from "../../../lib/api/apiSlice";
import {New} from "../../core/shared/controls";


export default function Tags(props) {
  const {
    data: tags = [],
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetTagsQuery()

  useEffect(() => {
    document.title = 'Labels | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
    <Header as="h2">Labels</Header>
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={10}>
          <New/>
        </Grid.Column>
        <Grid.Column width={6}>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <div className='vertical-scrollable'>
      <Table unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Anzahl Busse</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tags.map(tag => <Table.Row key={tag.id}>
              <Table.Cell>
                <TagLabel tag={tag}/>
              </Table.Cell>
              <Table.Cell>
                {tag.buses && tag.buses.length}
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  </Container>

}
