import React, {useEffect} from 'react'

import {Container, Header} from 'semantic-ui-react'
import {useNavigate, useParams} from "react-router-dom";
import {useGetPlaceQuery, useUpdatePlaceMutation} from "../../../lib/api/apiSlice";
import PlaceForm, {initialPlace} from "./PlaceForm";

export default function PlaceEdit(props) {
  let {id} = useParams()
  const navigate = useNavigate()

  const {
    data: place = initialPlace, isLoading, isSuccess, isError, error, refetch
  } = useGetPlaceQuery(id)

  const [updatePlace, {isLoading: isLoadingUpdatePlace}] = useUpdatePlaceMutation()

  const update = async (formData) => {
    try {
      await updatePlace({
        id: id,
        body: formData
      }).unwrap()
      navigate(`/places/${id}`)
    } catch (err) {
      console.log('Failure', err)
    }
  }
  useEffect(() => {
    document.title = 'Ort bearbeiten | First Class bus'
  }, [])


  return <Container style={{marginTop: '1em'}}>
    <Header as="h5">Ort bearbeiten</Header>
    {!isLoading && <PlaceForm formData={place} onSubmit={update}/>}
  </Container>
}

