import React, {useEffect, useState} from 'react'

import {Form, Grid, Label} from 'semantic-ui-react'
import SuggestColors from "../../core/shared/color/SuggestColors";
import {Cancel, Submit} from "../../core/shared/controls";

export const initialTag = {name: '', color: ''}


export function TagForm(props) {
  const [formData, setFormData] = useState({
    ...initialTag
  })

  const change = (e) => {
    setFormData((prevState => ({...prevState, [e.target.name]: e.target.value})))
  }

  useEffect(() => {
    if (props.formData != undefined)
      setFormData(props.formData)
  }, [])

  const changeColor = (e) => {
    let value = e.target.value.slice(0, 7)
    setFormData((prevState => ({...prevState, color: value})))
  }
  const clickColor = (color) => {
    setFormData((prevState => ({...prevState, color: color})))
  }

  return <Form>
    <Grid style={{marginTop: '1em'}} stackable>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Input label="Name" name='name' value={formData.name} onChange={change}/>
          <Form.Input label="Farbe" placeholder="#889911" name='color' value={formData.color}
                      onChange={changeColor} labelPosition='left'>
            <Label basic style={{background: formData.color}}></Label>
            <input/>
          </Form.Input>
          <div>
            <p>Tippen Sie Ihre eigene Farbe als Hex Farbe Code oder wählen
              Sie eine von den vorgeschlagenen unterhalb</p>
            <SuggestColors onClick={clickColor}/>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Submit onClick={() => props.onSubmit(formData)}/>
        <Cancel/>
      </Grid.Row>
    </Grid>
  </Form>
}
