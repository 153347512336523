import React, {useEffect} from 'react'

import {BusForm, initialBus} from './BusForm'

import {Container, Header} from 'semantic-ui-react'
import {useNavigate, useParams} from "react-router-dom";
import {useGetBusQuery, useUpdateBusMutation} from "../../lib/api/apiSlice";

export default function BusEdit(props) {
  let {id} = useParams()
  const navigate = useNavigate()

  const  {data: bus = initialBus, isLoading, isSuccess, isError, error, refetch} = useGetBusQuery(id)
  const [updateBus, {isLoading: isLoadingUpdateBus}] = useUpdateBusMutation()

  const update = async (formData) => {
    try{
      await updateBus({id: id, body: formData}).unwrap()
      navigate(`/buses/${id}`)
    } catch(err){
      console.log('Failure', err)
    }
  }
  useEffect(() => {
    document.title = 'Bus bearbeiten | First Class bus'
  }, [])
  return <Container style={{marginTop: '1em'}}>
    <Header as="h5">Bus bearbeiten</Header>
    {!isLoading && <BusForm formData={bus} onSubmit={update}/>}
  </Container>
}

