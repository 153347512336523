import React, {useEffect} from 'react'
import {Container, Grid, Header, Table} from "semantic-ui-react";

export default function SpecialVacation(props) {
  useEffect(() => {
    document.title = 'Dienstverhinderung | First Class bus'
  }, [])
    return <Container>
      <Header as="h2">Dienstverhinderung</Header>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={10}>
            Anzeige muss erst implementiert werden!
          </Grid.Column>
          <Grid.Column width={6}>

          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div className='vertical-scrollable'>
        <Table unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Anzeigename</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell></Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </Container>
}
