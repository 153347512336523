import React, {useEffect} from 'react'

import {Container, Grid, Header} from 'semantic-ui-react'
import {useParams} from "react-router-dom";
import {useGetEventQuery} from "../../lib/api/apiSlice";
import {Edit} from "../core/shared/controls";
import FormatedDateBus from "../core/shared/FormatedDateBus";

export default function EventDetail(props) {
  let {id} = useParams()
  const {
    data: event = {},
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetEventQuery(id)

  useEffect(() => {
    document.title = 'Fahrauftrag | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2">Fahrauftrag Details</Header>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right'>
          <Edit/>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column>
          <Header>{event.title}</Header>
          Bus: {event.bus && event.bus.name}
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column>
          Abfahrt: <FormatedDateBus date={event.start}/> {event.departure}
        </Grid.Column>
        <Grid.Column>
            Abfahrtsort: {event.departure_place}
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column>
            Ankunft: <FormatedDateBus date={event.end}/> {event.arrival}
        </Grid.Column>
        <Grid.Column>
            Ankunftsort: {event.arrival_place}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
}

