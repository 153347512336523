import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'

import {Container, Grid, Header, Table} from 'semantic-ui-react'
import {New} from "../../core/shared/controls";
import {useGetEmissionsQuery} from "../../../lib/api/apiSlice";


export default function Emission(props) {
  const {
    data: emissions = [],
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetEmissionsQuery()

  useEffect(() => {
    document.title = 'Abgasnormen | First Class bus'
  }, [])


    return <Container style={{marginTop: '1em'}}>
      <Header as="h2">Abgasnormen</Header>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={10}>
            <New/>
          </Grid.Column>
          <Grid.Column width={6}>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div className='vertical-scrollable'>
        <Table unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Anzahl Busse</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {emissions.map(emission => <Table.Row key={emission.id}>
              <Table.Cell>
                <Link to={`${emission.id}`}>{emission.name}</Link>
              </Table.Cell>
                <Table.Cell>
                  {emission.buses && emission.buses.length}
                </Table.Cell>
            </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    </Container>

}
