import React, {useEffect} from 'react'
import {Container, Header} from "semantic-ui-react";
import {useNavigate, useParams} from "react-router-dom";
import {useGetOvertimeQuery, useRemoveOvertimeMutation, useUpdateOvertimeMutation} from "../../../lib/api/apiSlice";
import {initialOvertime, OvertimeForm} from "./OvertimeForm";
import {hhmmAsSeconds} from "../../../lib/time/Time";

export default function OvertimeEdit(props) {
  let {id, staff_id} = useParams()
  const navigate = useNavigate()

  const {
    data: overtime = initialOvertime,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetOvertimeQuery(id)

  const [updateOvertime, {isLoading: isLoadingUpdateOvertime}] = useUpdateOvertimeMutation(id)
  const [removeOvertime, {isLoading: isLoadingRemoveOvertime}] = useRemoveOvertimeMutation(id)

  const update = async (formData) => {
    try {
      let {...clean_formData} = formData
      clean_formData.amount = hhmmAsSeconds(formData.amount)

      await updateOvertime({id: id, body: clean_formData}).unwrap()
      navigate(`/overtimes/${staff_id}`)
    } catch (err) {
      console.log('Failure', err)
    }
  }

  const remove = async (formData) => {
    if (confirm("Wollen Sie den Überstundeneintrag wirklich löschen?")) {
      try {
        await removeOvertime({id: id}).unwrap()
        navigate(`/overtimes/${staff_id}`)
      } catch (err) {
        console.log('Failure', err)
      }
    }
  }
  useEffect(() => {
    document.title = 'Überstundeneintrag bearbeiten | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
    <Header as="h5">Überstundeneintrag bearbeiten</Header>
    {!isLoading && <OvertimeForm formData={overtime} onSubmit={update} onRemove={remove}/>}
  </Container>
}
