import React from 'react'

import {initialTag, TagForm} from './TagForm'

import {Container, Header} from 'semantic-ui-react'
import {useNavigate, useParams} from "react-router-dom";
import {useGetTagQuery, useUpdateTagMutation} from "../../../lib/api/apiSlice";

export default function TagEdit(props) {
  let {id} = useParams()
  const navigate = useNavigate()

  const  {data: tag = initialTag, isLoading, isSuccess, isError, error, refetch} = useGetTagQuery(id)
  const [updateTag, {isLoading: isLoadingUpdateFuel}] = useUpdateTagMutation()

  const update = async (formData) => {
    try{
      await updateTag({id: id, body: formData}).unwrap()
      navigate(`/tags/${id}`)
    } catch(err){
      console.log('Failure', err)
    }
  }
    return <Container style={{marginTop: '1em'}}>
      <Header as="h5">Label bearbeiten</Header>
      {!isLoading && <TagForm formData={tag} onSubmit={update}/>}
    </Container>
}

