import React from 'react'


import {Container, Header} from 'semantic-ui-react'
import {EmissionForm, initialEmission} from "./EmissionForm";
import {useNavigate, useParams} from "react-router-dom";
import {useGetEmissionQuery, useUpdateEmissionMutation} from "../../../lib/api/apiSlice";

export default function EmissionEdit(props) {
  let {id} = useParams()
  const navigate = useNavigate()

  const {
    data: emission = initialEmission, isLoading, isSuccess, isError, error, refetch
  } = useGetEmissionQuery(id)
  const [updateEmission, {isLoading: isLoadingUpdateEmission}] = useUpdateEmissionMutation()

  const update = async (formData) => {
    try {
      await updateEmission({
        id: id,
        body: formData
      }).unwrap()
      navigate(`/emissions/${id}`)
    } catch (err) {
      console.log('Failure', err)
    }
  }

  return <Container style={{marginTop: '1em'}}>
    <Header as="h5">Abgasnorm bearbeiten</Header>
    {!isLoading && <EmissionForm formData={emission} onSubmit={update}/>}
  </Container>
}

