import React, {useEffect} from "react";


import {Container, Header} from "semantic-ui-react";
import EventForm, {initialEvent} from "./EventForm";
import {useNavigate, useParams} from "react-router-dom";
import {useGetEventQuery, useUpdateEventMutation} from "../../lib/api/apiSlice";


export default function EventEdit(props) {
  let {id} = useParams()
  const navigate = useNavigate()

  const {data: event = initialEvent, isLoading, isSuccess, isError, error, refetch} = useGetEventQuery(id)
  const [updateEvent, {isLoading: isLoadingUpdateEvent}] = useUpdateEventMutation()

  const update = async (formData) => {
    try {
      await updateEvent({id: id, body: formData}).unwrap()
      navigate(`/events/${id}`)
    } catch (err) {
      console.log('Failure', err)
    }
  }
  useEffect(() => {
    document.title = 'Fahrauftrag bearbeiten | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
    <Header as="h5">Fahrauftrag bearbeiten</Header>
    {!isLoading && <EventForm formData={event} onSubmit={update}/>}
  </Container>
}

