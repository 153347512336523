import {key} from "./key";

export function createServiceSettings(){
  function init(value){
    localStorage.setItem(key.SERVICE, JSON.stringify(value))
  }

  function update(value){
    localStorage.setItem(key.SERVICE, JSON.stringify(value))
  }
  function get(){
    const item = localStorage.getItem(key.SERVICE)
    return JSON.parse(item)
  }
  function clear(){
    localStorage.removeItem(key.SERVICE)
  }

  return {
    init, update, get, clear
  }
}
