import React, {useEffect} from 'react'

import {Container, Header} from "semantic-ui-react";
import {useNavigate, useParams} from "react-router-dom";
import {useGetSchoolHolidayQuery, useUpdateSchoolHolidayMutation} from "../../../lib/api/apiSlice";
import {inititalSchoolHoliday, SchoolHolidayForm} from "./SchoolHolidayForm";


export default function SchoolHolidayEdit(props) {
  let {id} = useParams()
  const navigate = useNavigate()

  const  {data: school_holiday = inititalSchoolHoliday, isLoading} = useGetSchoolHolidayQuery(id)
  const [updateSchoolHoliday] = useUpdateSchoolHolidayMutation()

  const update = async (formData) => {
    try{
      let {state, country, ...clean_formData} = formData

      await updateSchoolHoliday({id: id, body: clean_formData}).unwrap()
      navigate(`/schoolholidays/${id}`)
    } catch(err){
      console.log('Failure', err)
    }
  }
  useEffect(() => {
    document.title = 'Schulfreier Tag bearbeiten | First Class bus'
  }, [])

    return <Container style={{marginTop: '1em'}}>
        <Header as="h2">Schulfreier Tag bearbeiten</Header>
      {!isLoading && <SchoolHolidayForm formData={school_holiday} onSubmit={update}/>}
    </Container>
}
