import React from 'react'
import moment from 'moment'

import {Container, Divider} from 'semantic-ui-react'

const Footer = () => (
  <Container text textAlign="center" style={{marginTop: '4em'}} className='no-print'>
    <Divider />
          <p>FCBUS ist ein Produkt der <a href="https://www.cityofcode.io">City of Code GmbH</a><br />
            Adnet 436, 5421 Adnet &sdot; Österreich<br />
            Tel.: <a href="tel:+436648111838">+43&thinsp;664&thinsp;8111838</a> &sdot;
            E-Mail: <a href="mailto:office@cityofcode.io">office@cityofcode.io</a> &sdot;
            © {moment().format('YYYY')} <a href="https://www.cityofcode.io/" target='_blank'> cityofcode.io</a>
          </p>

    </Container>
)

export default Footer
