import React, {useEffect} from 'react'
import {Link} from 'react-router-dom';

import {Container, Header, Table} from 'semantic-ui-react'
import {useGetCountriesQuery} from "../../../lib/api/apiSlice";
import {New} from "../../core/shared/controls";

export default function Countries(props) {

  const {data: countries = []} = useGetCountriesQuery()

  useEffect(() => {
    document.title = 'Staaten | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
    <Header as="h2">Staaten</Header>
    <New/>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Kürzel</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {countries.map(country => <Table.Row key={country.id}>
          <Table.Cell><Link to={`${country.id}`}>{country.matchcode}</Link></Table.Cell>
          <Table.Cell>{country.name}</Table.Cell>
        </Table.Row>)}
      </Table.Body>
    </Table>
  </Container>
}

