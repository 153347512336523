import React, {useEffect, useState} from 'react'
import {Container, Grid, Header, Icon, Message, Segment, Statistic, Table} from "semantic-ui-react";
import {useParams} from 'react-router-dom';
import FormatedDateBus from "../../core/shared/FormatedDateBus";
import VacationAccountType from "../../core/shared/vacation_account_type/VacationAccountType";
import moment from "moment";
import {useGetStaffQuery, useGetVacationsForStaffQuery} from "../../../lib/api/apiSlice";
import {Edit, New} from "../../core/shared/controls";
import {PeriodNavigation} from "../../core/shared/PeriodNavigation";


export default function VacationDetail(props) {
  let {staff_id} = useParams()

  const initialVacation = {
    available_vacation_contingent_today: "",
    amount_carry_forward_today: "",
    amount_period_contingent_today: "",
    period_start: null,
    period_stop: null,
    vacation_accounts: []
  }

  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))

  const {
    data: staff = {},
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetStaffQuery(staff_id)


  const {
    data: vacations_for_staff = {},
    isLoadingVacation,
    isSuccessVacation,
    isErrorVacation,
    errorVacation,
    refetchVacation
  } = useGetVacationsForStaffQuery({staff_id: staff_id, date: date})


  const previousPeriod = () => {
    let newDate = moment(date).subtract(1, 'year').format('YYYY-MM-DD')
    setDate(newDate)
    refetchVacation
  }

  const currentPeriod = () => {
    let newDate = moment().format('YYYY-MM-DD')
    setDate(newDate)
    refetchVacation
  }

  const nextPeriod = () => {
    let newDate = moment(date).add(1, 'year').format('YYYY-MM-DD')
    setDate(newDate)
    refetchVacation
  }

  useEffect(() => {
    document.title = 'Urlaubdetails | First Class bus'
  }, [])


  let body;
  let period_start;
  let vacation_activation_message;

  console.log("staff:", staff)
  if (staff.vacation_period_start) {
    period_start = moment(staff.vacation_period_start).format("DD.MM")
  } else {
    period_start = "-"
    vacation_activation_message = <Message info>Aktivieren Sie die Urlaubsverwaltung in dem Sie beim Mitarbeiter
      den Beginn des Urlaubsjahres eintragen.
    </Message>
  }


  if (vacations_for_staff.length === 0) {
    body = <Message>Keine Urlaubskonto Einträge vorhanden</Message>
  } else {
    body = <div className='vertical-scrollable'>
      <Table unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Datum</Table.HeaderCell>
            <Table.HeaderCell>Tage</Table.HeaderCell>
            <Table.HeaderCell>Typ</Table.HeaderCell>
            <Table.HeaderCell>Notiz</Table.HeaderCell>
            <Table.HeaderCell className="no-print"><Icon name='cogs'/></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {vacations_for_staff.vacation_accounts && vacations_for_staff.vacation_accounts.map(vacation_account => <Table.Row
              key={vacation_account.id}>
              <Table.Cell><FormatedDateBus date={vacation_account.day}/></Table.Cell>
              <Table.Cell>{vacation_account.amount}</Table.Cell>
              <Table.Cell><VacationAccountType vacation_account={vacation_account}/></Table.Cell>
              <Table.Cell>{vacation_account.note}</Table.Cell>
              <Table.Cell className="no-print">
                {(() => {
                  if (vacation_account.type == "Entry") {
                    return ""
                  } else {
                    return <Edit to={`edit/${vacation_account.id}`} icon/>
                  }
                })()}
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  }


  return <Container>
    <Grid style={{marginTop: '1em'}} stackable>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2">Urlaubskonto {staff.name}</Header>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right' className="no-print">
          <New/>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={16}>
          <Segment>
            <Header size='small'>Aktuelle Periode</Header>
            <Statistic.Group widths='four' size='small'>
              <Statistic>
                <Statistic.Value>{vacations_for_staff.available_vacation_contingent_today}</Statistic.Value>
                <Statistic.Label>Tage Resturlaub</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>{vacations_for_staff.amount_period_contingent_today}</Statistic.Value>
                <Statistic.Label>Tage Jahresurlaub</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>{vacations_for_staff.amount_carry_forward_today} </Statistic.Value>
                <Statistic.Label>Tage Übertrag aus Vorjahr</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>{period_start} </Statistic.Value>
                <Statistic.Label>Begin Urlaubsjahr</Statistic.Label>
              </Statistic>
            </Statistic.Group>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={10}>
          <Header size='small'>Urlaubskonto Einträge
            im Zeitraum <FormatedDateBus date={vacations_for_staff.period_start}/> bis&nbsp;
            <FormatedDateBus date={vacations_for_staff.period_stop}/></Header>
        </Grid.Column>
        <Grid.Column width={6} textAlign='right' className="no-print">
          <PeriodNavigation previousPeriod={previousPeriod} currentPeriod={currentPeriod} nextPeriod={nextPeriod}/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {vacation_activation_message && vacation_activation_message}
          {body}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
}
