import React, {useEffect, useState} from 'react'
import {Container, Grid, Header, Icon, Message, Segment, Statistic, Table} from "semantic-ui-react";
import {useParams} from 'react-router-dom';
import FormatedDateBus from "../../core/shared/FormatedDateBus";
import moment from "moment";
import {useGetOvertimesForStaffQuery} from "../../../lib/api/apiSlice";
import {Edit, New} from "../../core/shared/controls";
import OvertimeType from "./OvertimeType";
import {iso8601ToLocaleDateString} from "../../../lib/time/Time";
import {PeriodNavigation} from "../../core/shared/PeriodNavigation";

export default function OvertimeDetail(props) {
  let {staff_id} = useParams()

  const initialOvertime = {
    staff: {name: ''},
    period_start: '',
    period_stop: '',
    previous_overtime: '',
    overtimes_for_period: []
  }

  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))

  const {
    data = initialOvertime,
    isLoadingVacation,
    isSuccessVacation,
    isErrorVacation,
    errorVacation,
    refetchVacation
  } = useGetOvertimesForStaffQuery({staff_id: staff_id, date: date})


  const previousPeriod = () => {
    let newDate = moment(date).subtract(1, 'year').format('YYYY-MM-DD')
    setDate(newDate)
    refetchVacation
  }

  const currentPeriod = () => {
    let newDate = moment().format('YYYY-MM-DD')
    setDate(newDate)
    refetchVacation
  }

  const nextPeriod = () => {
    let newDate = moment(date).add(1, 'year').format('YYYY-MM-DD')
    setDate(newDate)
    refetchVacation
  }

  useEffect(() => {
    document.title = 'Überstundendetails | First Class bus'
  }, [])


  let body;
  let period_start;
  let vacation_activation_message;

  console.log("#### overtimes", data)
  if (data.overtimes_for_period.length === 0) {
    body = <Message>Keine Überstunden Einträge vorhanden</Message>
  } else {
    body = <div className='vertical-scrollable'>
      <Table unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Datum</Table.HeaderCell>
            <Table.HeaderCell>Dauer</Table.HeaderCell>
            <Table.HeaderCell>Typ</Table.HeaderCell>
            <Table.HeaderCell>Notiz</Table.HeaderCell>
            <Table.HeaderCell className="no-print"><Icon name='cogs'/></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.overtimes_for_period && data.overtimes_for_period.map(overtime =>
            <Table.Row key={overtime.id}>
              <Table.Cell><FormatedDateBus date={overtime.day}/></Table.Cell>
              <Table.Cell>{overtime.amount}</Table.Cell>
              <Table.Cell><OvertimeType overtime={overtime}/></Table.Cell>
              <Table.Cell>{overtime.note}</Table.Cell>
              <Table.Cell className="no-print">
                <Edit to={`edit/${overtime.id}`} icon/>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  }


  return <Container>
    <Grid style={{marginTop: '1em'}} stackable>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2">Überstunden {data.staff.name}</Header>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right' className="no-print">
          <New/>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={16}>
          <Segment>
            <Statistic.Group widths='one' size='small'>
              <Statistic>
                <Statistic.Value>{data.previous_overtime}</Statistic.Value>
                <Statistic.Label>Saldo {iso8601ToLocaleDateString(data.previous_overtime_date)}</Statistic.Label>
              </Statistic>
            </Statistic.Group>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={10}>
          <Header size='small'>
            Überstunden Einträge
            im Zeitraum <FormatedDateBus date={data.period_start}/> bis&nbsp;
            <FormatedDateBus date={data.period_stop}/></Header>
        </Grid.Column>
        <Grid.Column width={6} textAlign='right' className="no-print">
          <PeriodNavigation previousPeriod={previousPeriod}
                            currentPeriod={currentPeriod}
                            nextPeriod={nextPeriod}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {vacation_activation_message && vacation_activation_message}
          {body}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
}
