import React, {useEffect} from 'react'

import {Container, Grid, Header} from 'semantic-ui-react'
import BusesShortTable from "../BusesShortTable";
import {useGetConstructionQuery} from "../../../lib/api/apiSlice";
import {Edit} from "../../core/shared/controls";
import {useParams} from "react-router-dom";

export default function ConstructionDetail(props) {
  let {id} = useParams()
  const {
    data: construction = {},
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetConstructionQuery(id)

  useEffect(() => {
    document.title = 'Fahrzeugtype | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
    <Grid style={{marginTop: '1em'}} stackable>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2">Fahrzeugtyp</Header>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right'>
          <Edit/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row width={1}>
        <Grid.Column>
          Code: {construction.code}<br/>
          Name: {construction.name}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <BusesShortTable header="Busse mit diesem Fahrzeugtyp" buses={construction.buses}/>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>

}
