import React, {useEffect} from 'react'
import {Container, Header} from "semantic-ui-react";
import {useNavigate, useParams} from "react-router-dom";
import {useAddNewEntryMutation} from "../../../lib/api/apiSlice";
import {initialVariableWorkingTime, VariableWorkingTimeForm} from "./VariableWorkingTimeForm";
import {hhmmAsSeconds} from "../../../lib/time/Time";


export default function VariableWorkingTimeNew(props) {
  let {staff_id} = useParams()
  const navigate = useNavigate()

  const [addNewEntry, {isLoading: isLoadingAddNewEntry}] = useAddNewEntryMutation()

  const create = async (formData) => {
    try {
      let {...clean_formData} = formData
      clean_formData.variable_working_time = hhmmAsSeconds(formData.variable_working_time)

      await addNewEntry(clean_formData).unwrap()
      navigate(-1)
    } catch (err) {
      console.log('Failure', err)
    }
  }

  useEffect(() => {
    document.title = 'Variable Arbeitszeit erfassen | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
      <Header as="h5">Variable Arbeitszeit erfassen</Header>
      <VariableWorkingTimeForm onSubmit={create} formData={initialVariableWorkingTime}/>
  </Container>
}
