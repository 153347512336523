import React, {useEffect} from 'react'

import {ScheduleForm} from './ScheduleForm'


import {Container, Header} from 'semantic-ui-react'
import {useNavigate} from "react-router-dom";
import {useAddNewScheduleMutation} from "../../lib/api/apiSlice";

export default function ScheduleNew(props) {
  const navigate = useNavigate()

  const [addNewSchedule, {isLoading: isLoadingAddNewSchedule}] = useAddNewScheduleMutation()


  const create = async (formData) => {
    try {
      let {
        buses_select,
        trafficdaycodes_select,
        lines_select,
        countries_select,
        states_select,
        ...clean_formData
      } = formData
      await addNewSchedule(clean_formData).unwrap()
      navigate(`/schedules`)
    } catch (err) {
      console.log('Failure', err)
    }
  }
  useEffect(() => {
    document.title = 'Wagenumlauf anlegen | First Class bus'
  }, [])


  return <Container style={{marginTop: '1em'}}>
    <Header as="h5">Neuen Wagenumlauf anlegen</Header>
    <ScheduleForm onSubmit={create}/>
  </Container>
}

