import React from "react";
import {createRoot} from "react-dom/client";
import AppRoutes from "./AppRoutes.jsx";
import "semantic-ui-css/semantic.min.css"
import {Provider} from "react-redux";
import store from "./Store";
import {MediaContextProvider} from "./AppMedia";


const container = document.getElementById('app')
const root = createRoot(container)


root.render(
  <MediaContextProvider>
    <Provider store={store}>
      <AppRoutes/>
    </Provider>
  </MediaContextProvider>
)


