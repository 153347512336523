import React, {useEffect} from 'react'
import {Container, Header} from "semantic-ui-react";
import {useNavigate, useParams} from "react-router-dom";
import {useGetEntryQuery, useRemoveEntryMutation, useUpdateEntryMutation} from "../../../lib/api/apiSlice";
import {initialVariableWorkingTime, VariableWorkingTimeForm} from "./VariableWorkingTimeForm";
import {hhmmAsSeconds} from "../../../lib/time/Time";


export default function VariableWorkingTimeEdit(props) {
  let {id} = useParams()
  const navigate = useNavigate()

  const {data: entry = initialVariableWorkingTime, isLoading} = useGetEntryQuery(id)
  const [updateEntry, {isLoading: isLoadingUpdateEntry}] = useUpdateEntryMutation()
  const [removeEntry, {isLoading: isLoadingRemoveEnty}] = useRemoveEntryMutation(id)

  const update = async (formData) => {
    try {
      let {...clean_formData} = formData
      clean_formData.variable_working_time = hhmmAsSeconds(formData.variable_working_time)

      await updateEntry({id: id, body: clean_formData}).unwrap()
      navigate(-1)
    } catch (err) {
      console.log('Failure', err)
    }
  }

  const remove = async (formData) => {
    if (confirm("Wollen Sie den variablen Arbeitszeiteintrag wirklich löschen?")) {
      try {
        await removeEntry({id: id}).unwrap()
        navigate(-1)
      } catch (err) {
        console.log('Failure', err)
      }
    }
  }

  useEffect(() => {
    document.title = 'Variable Arbeitszeit bearbeiten | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
    <Header as="h5">Variable Arbeitszeit bearbeiten</Header>
    {!isLoading && <VariableWorkingTimeForm formData={entry} onSubmit={update} onRemove={remove}/>}
  </Container>
}
