import React, {useEffect} from 'react'

import {Container, Grid, Header} from 'semantic-ui-react'
import {useParams} from "react-router-dom";
import {Edit} from "../../core/shared/controls";
import {useGetStateQuery} from "../../../lib/api/apiSlice";


export default function StateDetail(props) {
  let {id} = useParams()
  const {data: state = {}} = useGetStateQuery(id)

  useEffect(() => {
    document.title = 'Land Details | First Class bus'
  }, [])


  return <Container style={{marginTop: '1em'}}>
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2">Land Details</Header>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right'>
          <Edit/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={5}>
          Kürzel: {state.matchcode}
        </Grid.Column>
        <Grid.Column width={5}>
          Name: {state.name}
        </Grid.Column>
        <Grid.Column width={6}>
          Staat: {state.country && state.country.name}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
}

