import React, {useEffect} from 'react'

import {Container, Header} from "semantic-ui-react";
import {useNavigate, useParams} from "react-router-dom";
import {useGetPublicHolidayQuery, useUpdatePublicHolidayMutation} from "../../../lib/api/apiSlice";
import {inititalPublicHoliday, PublicHolidayForm} from "./PublicHolidayForm";


export default function PublicHolidayEdit(props) {
  let {id} = useParams()
  const navigate = useNavigate()

  const  {data: public_holiday = inititalPublicHoliday, isLoading} = useGetPublicHolidayQuery(id)
  const [updatePublicHoliday] = useUpdatePublicHolidayMutation()

  const update = async (formData) => {
    try{
      let {state, country, ...clean_formData} = formData

      await updatePublicHoliday({id: id, body: clean_formData}).unwrap()
      navigate(`/publicholidays/${id}`)
    } catch(err){
      console.log('Failure', err)
    }
  }
  useEffect(() => {
    document.title = 'Feiertag bearbeiten | First Class bus'
  }, [])

    return <Container style={{marginTop: '1em'}}>
        <Header as="h2">Feiertag bearbeiten</Header>
      {!isLoading && <PublicHolidayForm formData={public_holiday} onSubmit={update}/>}
    </Container>
}
