import React, {useEffect} from 'react'
import {Container, Header} from "semantic-ui-react";
import {useNavigate} from "react-router-dom";
import {useAddNewStateMutation} from "../../../lib/api/apiSlice";
import {StateForm} from "./StateForm";

export default function StateNew(props) {
  const navigate = useNavigate()
  const [addNewState] = useAddNewStateMutation()

  const create = async (formData) => {
    try{
      let {countries,
        ...clean_formData} = formData
      await addNewState(clean_formData).unwrap()
      navigate('/states')
    } catch(err){
      console.log('Failure', err)
    }
  }
  useEffect(() => {
    document.title = 'Land anlegen | First Class bus'
  }, [])
    return <Container style={{marginTop: '1em'}}>
        <Header as="h2">Land anlegen</Header>
        <StateForm onSubmit={create}/>
    </Container>
}
