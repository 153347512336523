import React, {useEffect} from 'react'

import {Container, Grid, Header} from 'semantic-ui-react'
import {Edit} from "../../core/shared/controls";
import {useParams} from "react-router-dom";
import {useGetCountryQuery} from "../../../lib/api/apiSlice";


export default function CountryDetail(props) {
  let {id} = useParams()

  const {data: country = {}} = useGetCountryQuery(id)

  useEffect(() => {
    document.title = 'Staat Details | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2">Staat Details</Header>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right'>
          <Edit/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          Kürzel: {country.matchcode}
        </Grid.Column>
        <Grid.Column width={8}>
          Name: {country.name}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
}

