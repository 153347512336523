import React, {useEffect} from 'react'

import {Container} from 'semantic-ui-react'
import Dashboard from './dashboard/Dashboard'
import {useNavigate} from "react-router-dom";


export default function Home(props) {

  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'Home | FCBUS'

    if (!(sessionStorage.auth && sessionStorage.auth.length > 0)){
      navigate("/login")
    }
  }, [])


  return <Container>
    {(sessionStorage.auth && sessionStorage.auth.length > 0) && <Dashboard/>}
  </Container>
}
