import React, {useEffect} from 'react'

import {Container, Header} from "semantic-ui-react";
import {useNavigate, useParams} from "react-router-dom";
import {useGetStateQuery, useUpdateStateMutation} from "../../../lib/api/apiSlice";
import {StateForm} from "./StateForm";


export default function StateEdit(props) {
  let {id} = useParams()
  const navigate = useNavigate()

  const  {data: state = initialState, isLoading} = useGetStateQuery(id)
  const [updateState] = useUpdateStateMutation()

  const update = async (formData) => {
    try{
      let {country, ...clean_formData} = formData

      await updateState({id: id, body: clean_formData}).unwrap()
      navigate(`/states/${id}`)
    } catch(err){
      console.log('Failure', err)
    }
  }
  useEffect(() => {
    document.title = 'Land bearbeiten | First Class bus'
  }, [])

    return <Container style={{marginTop: '1em'}}>
        <Header as="h2">Land bearbeiten</Header>
      {!isLoading && <StateForm formData={state} onSubmit={update}/>}
    </Container>
}
