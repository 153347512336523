import React, {useEffect} from "react";
import {Link} from 'react-router-dom';

import {Container, Header, Table} from "semantic-ui-react";
import {useGetLinesQuery} from "../../../lib/api/apiSlice";
import {New} from "../../core/shared/controls";

export default function Lines(props){

  const {
    data: lines = [],
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetLinesQuery()

  useEffect(() => {
    document.title = 'Linien | First Class bus'
  }, [])

  return <Container>
        <Header size="huge">Alle Linien</Header>
        <New/>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Nr</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {lines.map(line => <Table.Row key={line.id}>
                <Table.Cell>
                  <Link to={`${line.id}`}>{line.id}</Link>
                </Table.Cell>
                <Table.Cell>
                  <Link to={`${line.id}`}>{line.nr}</Link>
                </Table.Cell>
                <Table.Cell>
                  {line.name}
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
    </Container>
}

