import React, {useEffect, useState} from 'react'

import {Dropdown, Form, Grid, Input} from 'semantic-ui-react'
import {useGetBusesSelectQuery} from "../../lib/api/apiSlice";
import {Cancel, Submit} from "../core/shared/controls";
import moment from "moment";

export const initialEvent = {
  id:'',
  bus_id:0,
  title: '',
  start: moment().format("YYYY-MM-DD"),
  departure: moment().format("HH:mm"),
  departure_place: '',
  end: moment().format("YYYY-MM-DD"),
  arrival: moment().format("HH:mm"),
  arrival_place: ''
}

export default function EventForm(props) {

  const [formData, setFormData] = useState({
    ...initialEvent
  })

  const {data: buses_select = []} = useGetBusesSelectQuery()

  const change = (e) => {
    console.log("events", e)
    setFormData((prevState => ({...prevState, [e.target.name]: e.target.value})))
  }

  const changeDropdownBus = (e, data) => {
    setFormData((prevState => ({...prevState, bus_id: data.value})))
  }


  useEffect(() => {
    if (props.formData != undefined)
      setFormData(props.formData)
  }, [])

  return <Form><Grid columns="2">
    <Grid.Row>
      <Grid.Column>
        <Form.Field>
          <label>Bezeichnung</label>
          <Input name='title' value={formData.title} onChange={change}/>
        </Form.Field>
      </Grid.Column>
      <Grid.Column>
        <Form.Field>
          <Form.Field>
            <label>Bus</label>
            <Dropdown name="bus_id"
                      onChange={changeDropdownBus} value={formData.bus_id}
                      fluid search selection options={buses_select}/>
          </Form.Field>

        </Form.Field>
      </Grid.Column>
    </Grid.Row>
  </Grid>
    <Grid columns={3}>
      <Grid.Row>
        <Grid.Column>
          <Form.Field>
            <Input label="Abfahrtsdatum" name='start' value={formData.start} onChange={change} type="date"/>
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          <Form.Field>
            <Input label="Abfahrtszeit" name='departure' value={formData.departure} onChange={change} type="time"/>
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          <Form.Field>
            <Input label="Abfahrtsort" name='departure_place' value={formData.departure_place} onChange={change}/>
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Grid columns={3}>
      <Grid.Row>
        <Grid.Column>
          <Form.Field>
            <Input label="Ankunftsdatum" name='end' value={formData.end} onChange={change} type="date"/>
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          <Form.Field>
            <Input label="Ankunftszeit" placeholder="" name='arrival' value={formData.arrival} onChange={change} type="time"/>
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          <Form.Field>
            <Input label="Ankunftsort" placeholder="" name='arrival_place' value={formData.arrival_place}
                   onChange={change}/>
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Submit onClick={() => props.onSubmit(formData)}/>
        <Cancel/>
      </Grid.Row>
    </Grid>
  </Form>
}

