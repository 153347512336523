import React from 'react'


import {Container, Header} from 'semantic-ui-react'
import {FuelForm, initialFuel} from "./FuelForm";
import {useNavigate, useParams} from "react-router-dom";
import {useGetFuelQuery, useUpdateFuelMutation} from "../../../lib/api/apiSlice";

export default function FuelEdit(props) {
  let {id} = useParams()
  const navigate = useNavigate()

  const  {data: fuel = initialFuel, isLoading, isSuccess, isError, error, refetch} = useGetFuelQuery(id)
  const [updateFuel, {isLoading: isLoadingUpdateFuel}] = useUpdateFuelMutation()

  const update = async (formData) => {
    try{
      await updateFuel({id: id, body: formData}).unwrap()
      navigate(`/fuels/${id}`)
    } catch(err){
      console.log('Failure', err)
    }
  }

  return <Container style={{marginTop: '1em'}}>
    <Header as="h5">Kraftstoff bearbeiten</Header>
    {!isLoading && <FuelForm formData={fuel} onSubmit={update}/>}
  </Container>
}

