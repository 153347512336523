import React, {useEffect, useState} from 'react'
import {Dropdown, Form, Grid} from "semantic-ui-react";
import {useGetCountriesSelectQuery} from "../../../lib/api/apiSlice";
import {Cancel, Submit} from "../../core/shared/controls";

export const inititalState = {
  country_id: '',
  id: '',
  matchcode: '',
  name: '',
}

export function StateForm(props) {

  const [formData, setFormData] = useState({
    ...inititalState
  })

  const {data: countries_select = []} = useGetCountriesSelectQuery()

  const change = (e) => {
    setFormData((prevState => ({...prevState, [e.target.name]: e.target.value})))
  }
  useEffect(() => {
    if (props.formData != undefined)
      setFormData(props.formData)
  }, [])

  const changeDropdown = (e, data) => {
    setFormData((prevState => ({...prevState, [data.name]: data.value})))
  }

    return <Form>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={5}>
          <Form.Field>
            <Form.Input label="Kürzel" placeholder="" name='matchcode'
                        value={formData.matchcode} onChange={change} required/>
          </Form.Field>
          </Grid.Column>
          <Grid.Column width={5}>
          <Form.Field>
            <Form.Input label="Name" placeholder="" name='name'
                        value={formData.name} onChange={change} required/>
          </Form.Field>
          </Grid.Column>
          <Grid.Column width={6}>
          <Form.Field>
            <label>Land</label>
            <Dropdown name="country_id"
                      onChange={changeDropdown} value={formData.country_id}
                      fluid selection clearable options={countries_select} required/>
          </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Submit onClick={() => props.onSubmit(formData)}/>
          <Cancel/>
        </Grid.Row>
      </Grid>
    </Form>
}
