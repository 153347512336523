import React, {useEffect} from 'react'

import {initialStaff, StaffForm} from "./StaffForm";
import {Container, Header} from "semantic-ui-react";
import {useNavigate, useParams} from "react-router-dom";
import {useGetStaffQuery, useUpdateStaffMutation} from "../../../lib/api/apiSlice";
import {hhmmAsSeconds} from "../../../lib/time/Time";


export default function StaffEdit(props) {
  let {id} = useParams()
  const navigate = useNavigate()

  const  {data: staff = initialStaff, isLoading, isSuccess, isError, error, refetch} = useGetStaffQuery(id)
  const [updateStaff, {isLoading: isLoadingUpdateStaff}] = useUpdateStaffMutation()

  const update = async (formData) => {
    try{
      let {working_time_month,
        working_time_week,
        working_time_day,
        working_time_histories,
        regular_working_time_month,
        ...clean_formData} = formData // delete operator, remove the fields before


      await updateStaff({id: id, body: clean_formData}).unwrap()
      navigate(`/staffs/${id}`)
    } catch(err){
      console.log('Failure', err)
    }
  }
  useEffect(() => {
    document.title = 'Mitarbeiter bearbeiten | First Class bus'
  }, [])

    return <Container style={{marginTop: '1em'}}>
        <Header as="h5">Mitarbeiter bearbeiten</Header>
      {!isLoading && <StaffForm formData={staff} onSubmit={update}/>}
    </Container>
}
