import React, {Component} from "react";
import {Container, Header, List} from "semantic-ui-react";

export default class Help extends Component {


  componentDidMount() {
    document.title = 'Hilfe | First Class bus'
  }

  render() {
    return <Container>
      <Header size="huge">Hilfe</Header>
      <p>Auf dieser Seite finden Sie Hilfestellungen zu den einzelnen Funktionen von FCBUS.</p>
      <Header size="large">Wagenumlauf</Header>
      <p>Der Hauptmenüpunkt Wagenumlauf beinhaltet Funktionen zum Erstellen und Planen eines Busunternehmens. Dort wird folgendes verwaltet:</p>
      <List>
        <List.Item icon='arrow alternate circle right outline' content='Fahrplan'/>
        <List.Item>
          <List.List>
            <List.Item icon='arrow alternate circle right outline' content='Simulation'/>
            <List.Item icon='arrow alternate circle right outline' content='Fahraufträge generieren'/>
          </List.List>
        </List.Item>
        <List.Item icon='arrow alternate circle right outline' content='Linie'/>
        <List.Item icon='arrow alternate circle right outline' content='Kurs'/>
        <List.Item icon='arrow alternate circle right outline' content='Fahraufträge'/>
        <List.Item icon='arrow alternate circle right outline' content='Planung'/>
      </List>
      <p>Zuerst erstellt man einen Wagenumlauf als Grundgerüst. Diesem Wagenumlauf weist man dann Kurse hinzu. Kurse müssen eine Linie und einen Wagenumlauf hinterlegt haben. Weiters natürlich die Kurseigenschaften wie Abfahrtszeit, Abfahrtsort
         usw.</p>
      <p>Nun hat man einen Wagenumlauf mit Kursen. Danach kann man in die Simulatio gehen. Dort wird die Vorlage simuliert. Man weißt im Wagenumlauf einen Kurs zu einem Bus zu und optimiert.</p>
      <p>Ist die Vorlage soweit optimal, kann man daraus über Fahraufträge generieren die Fahraufträge erstellen. Ein Fahrauftrag ist dann der definitive Zeitpunkt z.b 01.12.2018 06:00 wo die Fahrt stattfindet.</p>

      <Header size="medium">Wagenumlauf</Header>
      <p>Mithilfe des Wagenumlaufs kann man mehere Kurse zusammenfassen. Diese Kurse können aus unterschiedlichen Linien stammen, müssen aber nicht.</p>
      <p>Die Wagenumlauf Vorlage kann man in der Kalenderansicht simulieren.</p>
      <p>Aus einem Wagenumlauf werden dann die Fahraufträge erzeugt.</p>
      <Header size="medium">Dienstkarte</Header>
      <p>Auf der Dienstkarte findet man alle Details zum Dienst des Wagenumlaufs. Darunter sind auch die einzelnen Zeiten. Diese werden wie folgt berechnet:</p>
      <ul>
        <li>Einsatzzeit: späteste Ankunft - früheste Abfahrt. Gilt für alle Kurse mit Kurstypeigenschaft Einsatzzeit Hackerl </li>
        <li>Pause: Dauer der Kurse mit Kurstypeigenschaft = PAUSE Hackerl + Wendezeit (Wendetypeigenschaft = PAUSE Hackerl)</li>
        <li>Lenkzeit: Dauer der Kurse mit Kurstypeigenschaft = Lenkzeit</li>
        <li>Leerfahrt: Dauer der Kurse mit Kurstypeigenschaft = Leerfahrt</li>
        <li>Sonstiges: Dauer der kurse mit Kurstypeigenschaft = Sonstiges</li>
      </ul>
      <p>Hinterlegt man bei Wagenumläufen einen Staat so werden darüber die Feier-/Schulfreien-Tage
        für diesen Wagenumlauf bestimmt.
      </p>

      <p>Die Arbeitszeit eines Wagenumlaufs kann auch händisch überschrieben werden. Die Zeit wird dann orange in der Detailansicht angezeigt.</p>

      <Header size="medium">Linie</Header>
      <p>Linien bestehen aus einer Nummer und Namen. Wie z.b. Linie Nummer 455 mit Namen Rohr - Sattledt.</p>
      <Header size="medium">Kurs</Header>
      <p>Kurs definiert sehr genau was zu tun ist. Er muss einem Wagenumlauf und einer Linie zugeordnet sein. Desweiteren besitzt er alle Eigenschaften um daraus Fahraufträge zu erzeugen.</p>
      <Header size="medium">Fahraufträge</Header>
      <p>Fahraufträge entsprechen der wirklichen Aufgabe mit einem konkreten Datum und Uhrzeit. Die Fahrt der Linie 455 mit Kurs 101 am 01.12.2018 um 06:22 Uhr Abfahrt Rohr nach Sattledt mit Ankunft 06:41 ist ein Beispiel eines Fahrauftrags.</p>
      <p>Sie können händisch angelegt aber auch aus Fahrplänen automatisch generiert werden.</p>
      <Header size="medium">Planung</Header>
      <p>In der Planung werden die Fahraufträge innerhalb von einem Kalender angezeigt.</p>

      <Header size="large">Dienstplan</Header>
      <p>Variable Zeiteingabe: Besitzt eine Tätigkeit die Eigenschaft variable Zeiteingabe, so hat dies
        im Dienstplan eine spezielle Funktion. Es kann nämlich dann die Arbeitszeit individuell für genau diesen
        Eintrag hinterlegt werden.
      </p>
      <p>Wird im Dienstplan ein Wagenumlauf eingetragen, wird die Prüfung auf die minimale Arbeitszeit angewendet.
        Diese beträgt 06:30. Sollte die Arbeitszeit darunter liegen, wird 06:30 verwendet. <br/>
        Ist kein Wagenumlauf im Dienstplan eingetragen, wir diese Prüfung auf die minimale Arbeitszeit nicht angewendet.
      </p>


      <Header size="large">Zeiterfassung</Header>
      <p>In der Zeiterfassung können Zeiteinträge erstellt werden, welche nicht über die Dienstplanung eingeplant sind.
      </p>
      <p>Hinweis: Die Zeiterfassung hat keine Auswirkung auf das Urlaubskonto.</p>

      <p>Überstungen und Zeitausgleich starten mit dem ersten Eintrag der Zeiterfassung. Man kann Überstunden auch
        händisch zubuchen, abbuchen oder als ausbezahlt abbuchen.
      </p>

      <p>Wird im Personal Feld beim Mitarbeiter Fahrpersonal ausgewählt, so wird in der Zeiterfassung
        die Mindestarbeitszeit Regel von 6 Stunden und 30 Minuten angewendet. Bei Betriebspersonal ist dies nicht
      der Fall.</p>
      <Header size="large">Variable Arbeitszeit</Header>
      <p>Variable Arbeitszeit kann über den Dienstplan eingegeben werden. Dabei wird ist eine Tätigkeit mit variabler
      Arbeitszeit notwendig. Diese wird gewählt und die Dauer für den Mitarbeiter mit eingegeben.
      </p>

      <Header size="large">Krankenstand</Header>
      <p>Über die Tätigkeit KRANK mit der Eigenschaft Krankenstand kann man beim Mitarbeiter einen Krankenstand
        hinterlegen. Der Krankenstand nimmt sich als Zeit die Tagesarbeitszeit vom Mitarbeiter.
      </p>

      <Header size="large">Urlaubsverwaltung</Header>
      <p>Die Urlaubseinträge sollen primär aus dem Dienstplan befüllt werden.
        Einträge aus dem Dienstplan können nur über den Dienstplan editiert werden.
        Es können aber auch manuelle Urlaubskonto Einträge erstellt werden.
        Jedesjahr wird zum Beginn des Urlaubsjahres der Urlaubsanspruch automatisch zugebucht.
        Im Urlaubskonto können Ansprüche und Verbrauch händisch eingetragen werden.
        Wichtiger Hinweis: Wird ein Dienstplan geändert/gelöscht so ändert sich auch das Urlaubskonto.
      </p>

      <Header size="large">Urlaubsverwaltung</Header>
      <p>Die Arbeitszeit kann über die Checkbox Diätenfähig gemacht werden. Über die Checkbox Aufschlagfähig, wird die
      Arbeitszeit auch bei der Aufschlagberechnung berücksichtig.</p>

      <Header size="large">Feiertage</Header>
      <p>Feiertage können Staaten und Länder hinterlegt haben.
        - Ist kein Staat und kein Land hinterlegt, so gilt der Feiertag im ganzen FCBUS.
        - Ist ein Staat hinterlegt, so gilt er nur für diesen Staat.
        - Ist ein Staat und ein Bundesland hinterlegt, so gilt er nur in diesem Bundesland.

        Feiertage wirken sich auf Wagenumläufe abhängig vom Verkehrstagesschlüssel aus.
      </p>

      <Header size="large">Schulfreie Tage</Header>
      <p>Schulfreie Tage werden ähnlich behandelt die Feiertage. Sie können Staaten und Länder hinterlegt haben.
        - Ist kein Staat und kein Land hinterlegt, so gilt schulfreie Tag im ganzen FCBUS.
        - Ist ein Staat hinterlegt, so gilt er für alle Länder des Staats.
        - Ist ein Staat und ein Land hinterlegt, so gilt er nur in diesem Land.

        Schulfreie Tage wirken sich auf Wagenumläufe abhängig vom Verkehrstagesschlüssel aus.
      </p>


    </Container>
  }
}


