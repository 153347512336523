import React, {useEffect} from 'react'
import {Container, Header} from "semantic-ui-react";
import {useNavigate} from "react-router-dom";
import {useAddNewPublicHolidayMutation} from "../../../lib/api/apiSlice";
import {PublicHolidayForm} from "./PublicHolidayForm";

export default function PublicHolidayNew(props) {
  const navigate = useNavigate()
  const [addNewPublicHoliday] = useAddNewPublicHolidayMutation()

  const create = async (formData) => {
    try{
      let {state,
        country,
        ...clean_formData} = formData
      await addNewPublicHoliday(clean_formData).unwrap()
      navigate('/publicholidays')
    } catch(err){
      console.log('Failure', err)
    }
  }
  useEffect(() => {
    document.title = 'Feiertag anlegen | First Class bus'
  }, [])
    return <Container style={{marginTop: '1em'}}>
        <Header as="h2">Neuen Feiertag anlegen</Header>
        <PublicHolidayForm onSubmit={create}/>
    </Container>
}
