import React, {useEffect} from 'react'
import {Container, Grid, Header} from 'semantic-ui-react'
import TagLabel from "./TagLabel";
import BusesShortTable from "../BusesShortTable";
import {useParams} from "react-router-dom";
import {useGetTagQuery} from "../../../lib/api/apiSlice";
import {Edit} from "../../core/shared/controls";

export default function TagDetail(props) {
  let {id} = useParams()

  const {
    data: tag = '',
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetTagQuery(id)

  useEffect(() => {
    document.title = 'Label | First Class bus'
  }, [])


  return <Container style={{marginTop: '1em'}}>
    <Grid style={{marginTop: '1em'}} stackable>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2">Label {<TagLabel tag={tag}/>}</Header>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right'>
          <Edit/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <BusesShortTable header="Busse mit diesem Label" buses={tag.buses}/>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>

}


