import React from 'react'

import {TagForm} from './TagForm'

import {Container, Header} from 'semantic-ui-react'
import {useNavigate} from "react-router-dom";
import {useAddNewTagMutation} from "../../../lib/api/apiSlice";

export default function TagNew(props) {
  const navigate = useNavigate()
  const [addNewTag, {isLoading: isLoadingAddNewTag}] = useAddNewTagMutation()

  const create = async (formData) => {
    try{
      await addNewTag(formData).unwrap()
      navigate('/tags')
    } catch(err){
      console.log('Failure', err)
    }
  }
    return <Container style={{marginTop: '1em'}}>
      <Header as="h5">Neues Label anlegen</Header>
      <TagForm onSubmit={create}/>
    </Container>
}

