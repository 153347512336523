import React, {useEffect} from 'react'

import {Container, Divider, Grid, Header} from "semantic-ui-react";

export default function About(props){

  useEffect(() => {document.title = 'Impressum | FCBUS'},[])


    return <Container text style={{marginTop: '1em'}}>
        <Header as='h2'>Impressum</Header>
        <Grid columns={2} divided>
          <Grid.Row>
            <Grid.Column>
              <p>
                City of Code GmbH<br/>
                Adnet 436<br/>
                5421 Adnet
              </p>
              <Divider horizontal></Divider>
              <p>
                E-Mail: <a href={"office@cityofcode.io"}>office@cityofcode.io</a><br/>
                Telefon: <a href={"tel:+436648111838"}>+43 664 8111838</a>
              </p>
              <Divider horizontal></Divider>
              <p>Geschäftsführer: DI Norbert Egger, BSc</p>
              <p>
                Fachgruppe: Unternehmensberatung und Informationstechnologie<br/>
                UID: ATU66573656<br/>
                Firmenbuchnummer: 362676y<br/>
                Firmengericht: Landesgericht Salzburg<br/>
                Behörde: Bezirkshauptmannschaft Hallein<br/>
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
}
