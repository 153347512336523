import React from 'react'

export default class SuggestColors extends React.Component {
  render(){
    const {onClick} = this.props
    const colors = [
      "#009966",
      "#8fbc8f",
      "#3cb371",
      "#00b140",
      "#013220",
      "#6699cc",
      "#0000ff",
      "#e6e6fa",
      "#9400d3",
      "#330066",
      "#808080",
      "#36454f",
      "#f7e7ce",
      "#c21e56",
      "#cc338b",
      "#dc143c",
      "#ff0000",
      "#cd5b45",
      "#eee600",
      "#ed9121",
      "#c39953"
    ]
    return <div>
      {colors.map( (color, index) => <a key={index} onClick={() => onClick(color)}
                               style={{background: color,
                                 width: '3em', height: '3em',
                                 marginRight:'1em',
                                 marginBottom:'1em',
                                 borderRadius:'0.5em',
                                 display: 'inline-block'}}></a>
      )}
    </div>
  }
}
