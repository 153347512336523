import React, {useEffect} from 'react'

import {Container, Header} from "semantic-ui-react";
import {useNavigate, useParams} from "react-router-dom";
import {useGetCountryQuery, useUpdateCountryMutation} from "../../../lib/api/apiSlice";
import {CountryForm, inititalCountry} from "./CountryForm";


export default function CountryEdit(props) {
  let {id} = useParams()
  const navigate = useNavigate()

  const  {data: country = inititalCountry, isLoading} = useGetCountryQuery(id)
  const [updateCountry] = useUpdateCountryMutation()

  const update = async (formData) => {
    try{
      await updateCountry({id: id, body: formData}).unwrap()
      navigate(`/countries/${id}`)
    } catch(err){
      console.log('Failure', err)
    }
  }
  useEffect(() => {
    document.title = 'Staat bearbeiten | First Class bus'
  }, [])

    return <Container style={{marginTop: '1em'}}>
        <Header as="h2">Staat bearbeiten</Header>
      {!isLoading && <CountryForm formData={country} onSubmit={update}/>}
    </Container>
}
