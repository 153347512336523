import React from "react"

import {Button} from "semantic-ui-react"
import {Link} from "react-router-dom";

export function New(props){
  if (props.onClick != undefined) {
    const buttonWithNavigate = <Button primary onClick={props.onClick}>
      {props.title ? props.title : "Neu"}
    </Button>

    return buttonWithNavigate
  } else {
    const classicLinkButton = <Button primary as={Link} to="new">
      {props.title ? props.title : "Neu"}
    </Button>

    return classicLinkButton
  }
}
