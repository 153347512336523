import React, {useEffect} from 'react'


import {Container, Header} from 'semantic-ui-react'
import {initialLine, LineForm} from "./LineForm";
import {useNavigate, useParams} from "react-router-dom";
import {useGetLineQuery, useUpdateLineMutation} from "../../../lib/api/apiSlice";


export default function LineEdit(props){
  let {id} = useParams()
  const navigate = useNavigate()

  const  {data: line = initialLine, isLoading, isSuccess, isError, error, refetch} = useGetLineQuery(id)
  const [updateLine, {isLoading: isLoadingUpdateLine}] = useUpdateLineMutation()

  const update = async (formData) => {
    try{
      let {courses,
        ...clean_formData} = formData
      await updateLine({id: id, body: clean_formData}).unwrap()
      navigate(`/lines/${id}`)
    } catch(err){
      console.log('Failure', err)
    }
  }

  useEffect(() => {
    document.title = 'Linie bearbeiten | First Class bus'
  }, [])


  return <Container style={{marginTop: '1em'}}>
      <Header as="h5">Linie bearbeiten</Header>
    {!isLoading && <LineForm formData={line} onSubmit={update}/>}
    </Container>
}

