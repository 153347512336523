import React, {useEffect} from 'react'
import {Container, Header, Table} from "semantic-ui-react";
import {useGetPresettingsQuery} from "../../../lib/api/apiSlice";
import {New} from "../../core/shared/controls";
import {Link} from "react-router-dom";


export default function Presettings(props) {
  const {
    data: presettings = [],
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetPresettingsQuery({show_in_logging: null})



  useEffect(() => {
    document.title = 'Tätigkeit | First Class bus'
  }, [])


    return <Container style={{marginTop: '1em'}}>
        <Header as="h2">Tätigkeit</Header>
        <New/>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Kürzel</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Urlaub</Table.HeaderCell>
              <Table.HeaderCell>Zeitausgleich</Table.HeaderCell>
              <Table.HeaderCell>Diätenfähig</Table.HeaderCell>
              <Table.HeaderCell>Aufschlägsfähig</Table.HeaderCell>
              <Table.HeaderCell>Variable Arbeitszeit</Table.HeaderCell>
              <Table.HeaderCell>Dienstverhinderung</Table.HeaderCell>
              <Table.HeaderCell>Freistellung</Table.HeaderCell>
              <Table.HeaderCell>Krankenstand</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {presettings.map(presetting => <Table.Row key={presetting.id}>
              <Table.Cell><Link to={`${presetting.id}`}>{presetting.matchcode}</Link></Table.Cell>
              <Table.Cell>{presetting.name}</Table.Cell>
              <Table.Cell>{presetting.vacation && <p>Ja</p>}</Table.Cell>
              <Table.Cell>{presetting.comp_time && <p>Ja</p>}</Table.Cell>
              <Table.Cell>{presetting.daily_allowance && <p>Ja</p>}</Table.Cell>
              <Table.Cell>{presetting.extra_pay && <p>Ja</p>}</Table.Cell>
              <Table.Cell>{presetting.variable_working_time && <p>Ja</p>}</Table.Cell>
              <Table.Cell>{presetting.special_vacation && <p>Ja</p>}</Table.Cell>
              <Table.Cell>
                {(() => {
                if (presetting.special_vacation_type == "standard") {
                  return <span>{presetting.special_vacation_contingent} Arbeitstage</span>
                } else if (presetting.special_vacation_type == "home_care"){
                  return <span>1 Arbeitswoche</span>
                } else if (presetting.special_vacation_type == "contingent_each_x_months"){
                  return <span>{presetting.special_vacation_contingent}  Arbeitstag alle {presetting.special_vacation_each_x_months} Monate</span>
                }

                })()}
              </Table.Cell>
              <Table.Cell>{presetting.sick_leave && <p>Ja</p>}</Table.Cell>
            </Table.Row>)}
          </Table.Body>
        </Table>
    </Container>
}
