import React, {useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'

import {Container, Grid, Header, Table} from 'semantic-ui-react'
import {useGetLineQuery} from "../../../lib/api/apiSlice";
import FormatedTimeOfDayBus from "../../core/shared/FormatedTimeOfDayBus";
import {Edit} from "../../core/shared/controls";

export default function LineDetail(props) {
  let {id} = useParams()

  const {
    data: line = [],
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetLineQuery(id)

  useEffect(() => {
    document.title = 'Linien Details | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
    <Grid style={{marginTop: '1em'}} stackable>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h1">Linie {line.nr}</Header>
          <Header as="h3">{line.name}</Header>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right'>
          <Edit/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h5">Kurse</Header>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right'>
          <Link to={`/courses/new?line_id=${line.id}`}>Kurs hinzufügen</Link>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Linie</Table.HeaderCell>
              <Table.HeaderCell>Kurs</Table.HeaderCell>
              <Table.HeaderCell>Abfahrt</Table.HeaderCell>
              <Table.HeaderCell>Abfahrtsort</Table.HeaderCell>
              <Table.HeaderCell>Ankunft</Table.HeaderCell>
              <Table.HeaderCell>Ankunftsort</Table.HeaderCell>
              <Table.HeaderCell>Kommentar</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {line.courses && line.courses.map((course) => <Table.Row key={course.id}>
              <Table.Cell>{line.nr}</Table.Cell>
              <Table.Cell><Link to={`/courses/${course.id}`}>{course.nr}</Link></Table.Cell>
              <Table.Cell><FormatedTimeOfDayBus time={course.departure}/></Table.Cell>
              <Table.Cell>{course.departure_place}</Table.Cell>
              <Table.Cell><FormatedTimeOfDayBus time={course.arrival}/></Table.Cell>
              <Table.Cell>{course.arrival_place}</Table.Cell>
              <Table.Cell>{course.comment}</Table.Cell>
            </Table.Row>)}
          </Table.Body>
        </Table>
      </Grid.Row>
    </Grid>
  </Container>

}


