import React, {useEffect} from 'react'

import {Container, Divider, Grid, Header, Segment} from 'semantic-ui-react'
import {useGetCourseQuery} from "../../../lib/api/apiSlice";
import {Link, useParams} from "react-router-dom";
import CourseTypeLabel from "../../core/shared/course_type/CourseTypeLabel";
import {Edit} from "../../core/shared/controls";
import TrafficdaycodeLabel from "../../core/shared/trafficdaycode/TrafficdaycodeLabel";


export default function Course(props) {
  let {id} = useParams()

  const {data: course = []} = useGetCourseQuery(id)

  useEffect(() => {
    document.title = 'Kurse Details | First Class bus'
  }, [])


  return <Container style={{marginTop: '1em'}}>
    <Grid style={{marginTop: '1em'}} stackable>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2">Kurs</Header>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right'>
          <Edit/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Segment>
          <p>Wagenumlauf:
            {course.schedule && <Link to={`/schedules/${course.schedule.id}`}>
              {course.schedule.name}
            </Link>}
          </p>
          <p>Linie: {course.line && <Link to={`/lines/${course.line.id}`}>{course.line.name}</Link>}</p>
          <p>Kursnummer: {course.nr}</p>
          <p>Abfahrt: {course.departure}</p>
          <p>Abfahrtsort:
            {course.departure_place && <Link to={`/places/${course.departure_place.id}`}>
              {course.departure_place.name}
            </Link>}
          </p>
          <p>Ankunft: {course.arrival}</p>
          <p>Ankunftsort:
            {course.arrival_place && <Link to={`/places/${course.arrival_place.id}`}>
              {course.arrival_place.name}
            </Link>}

          </p>
          <p>Kommentar: {course.comment}</p>
          <p>Meter: {course.distance}</p>

          <p>VTS: {course.trafficdaycodes && course.trafficdaycodes.map(
            (ctdc, index) => <TrafficdaycodeLabel key={index} content={ctdc.code}/>
          )}</p>
          <Divider/>
          <Header size='small'>Kurs Type Details</Header>
          {course.course_type && <CourseTypeLabel course_type={course.course_type}/>}
        </Segment>
      </Grid.Row>
    </Grid>
  </Container>

}


