import React from 'react'
import {Link} from "react-router-dom";
import {hhmm} from "../../../lib/time/Time";


export default function LoggingDescription(props) {

  const getSchedule = (schedule_id) => {
    const options = props.schedules
    if (options.length > 0) {
      let schedule = options.find(x => x.id === schedule_id)
      if (schedule != undefined) {
        return schedule
      }
    } else {
      return ''
    }
  }

  const getScheduleName = (schedule_id) => {
    let finding = getSchedule(schedule_id)
    if (finding != '') {
      return finding.matchcode
    } else {
      return finding
    }
  }

  const getPresetting = (presetting_id) => {
    const options = props.presettings
    if (options.length > 0) {
      let presetting = options.find(x => x.id === presetting_id)
      if (presetting != undefined) {
        return presetting
      }
    } else {
      return ''
    }
  }

  const getPresettingName = (presetting_id) => {
    let finding = getPresetting(presetting_id)
    if (finding != '') {
      return finding.matchcode
    } else {
      return finding
    }
  }

  let content = []
  if (props.time_recording.type == "Entry") {
    props.time_recording.entries.forEach((entry) => {
      if (entry.schedule_id != null) {
        content.push(<Link to={`/schedules/${entry.schedule_id}`} style={{marginRight: "0.5em"}}>
            {getScheduleName(entry.schedule_id)} {hhmm(getSchedule(entry.schedule_id).working_time)}
          </Link>
        )
      } else if (entry.presetting_id != null) {
        if (entry.variable_working_time > 0) {
          content.push(<Link to={`/services/variable/${entry.id}/edit`} style={{marginRight: "0.5em"}}>
              {getPresettingName(entry.presetting_id)} {hhmm(entry.variable_working_time)}
            </Link>
          )
        } else if (getPresetting(entry.presetting_id).sick_leave) {
          content.push(<Link to={`/presettings/${entry.presetting_id}`} style={{marginRight: "0.5em"}}>
              {getPresettingName(entry.presetting_id)}
            </Link>
          )
        } else {
          content.push(<Link to={`/presettings/${entry.presetting_id}`} style={{marginRight: "0.5em"}}>
              {getPresettingName(entry.presetting_id)} {hhmm(getPresetting(entry.presetting_id).auto_logging_duration)}
            </Link>
          )
        }


      }
    })
    props.time_recording.schedule_ids.forEach((schedule_id) => {

    })

    props.time_recording.presetting_ids.forEach((presetting_id) => {

    })
    return content
  } else {
    return <span></span>
  }
}
