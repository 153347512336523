import React from "react";


import {Container, Header} from 'semantic-ui-react'
import PlannerCalendar from "./PlannerCalendar";

export default function Planner(props) {
  return <Container style={{marginTop: '1em'}}>
    <Header as="h2">Kalenderansicht der Fahraufträge</Header>
      <PlannerCalendar/>
  </Container>
}
