export default class ColorHelper {

  static calculateForegroundTextColor(hexBackgroundColor) {
    var textColor = "#000000"
    if (hexBackgroundColor) {
      var bigint = parseInt(hexBackgroundColor.slice(1, 7), 16);
      var red = (bigint >> 16) & 255;
      var green = (bigint >> 8) & 255;
      var blue = bigint & 255;

      if ((red * 0.299 + green * 0.587 + blue * 0.114) <= 186) {
        textColor = "#ffffff"
      }
    }
    return textColor;
  }
}
