import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'

import {Container, Grid, Header, Table} from 'semantic-ui-react'
import {useGetConstructionsQuery} from "../../../lib/api/apiSlice";
import {New} from "../../core/shared/controls";


export default function Construtions(props) {
  const {
    data: constructions = [],
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetConstructionsQuery()

  useEffect(() => {
    document.title = 'Fahrzeugtypen | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
    <Header as="h2">Fahrzeugtyp</Header>
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={10}>
          <New/>
        </Grid.Column>
        <Grid.Column width={6}>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <div className='vertical-scrollable'>
      <Table unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Code</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Anzahl Busse</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {constructions.map(construction => <Table.Row key={construction.id}>
              <Table.Cell>
                <Link to={`${construction.id}`}>{construction.id}</Link>
              </Table.Cell>
              <Table.Cell>
                <Link to={`${construction.id}`}>{construction.code}</Link>
              </Table.Cell>
              <Table.Cell>
                <Link to={`${construction.id}`}>{construction.name}</Link>
              </Table.Cell>
              <Table.Cell>
                {construction.buses && construction.buses.length}
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  </Container>

}
