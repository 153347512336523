import React, {useEffect, useState} from 'react'
import {Container, Form, Grid, Header, Table} from "semantic-ui-react";
import {Link} from 'react-router-dom';
import FormatedDateBus from "../../core/shared/FormatedDateBus";
import {useGetVacationsQuery} from "../../../lib/api/apiSlice";

export default function Vacations(props) {

  const vacationInitialState = {
    show_archived: false,
  }
  const [formData, setFormData] = useState({
    ...vacationInitialState
  })

  const {
    data: vacations = [],
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetVacationsQuery(formData.show_archived)

  const changeArchived = (e, data) => {
    setFormData((prevState => ({...prevState, show_archived: data.checked})))
    refetch()
  }

  useEffect(() => {
    document.title = 'Urlaubsverwaltung | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
    <Header as="h2">Urlaubsverwaltung</Header>
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={10}>
        </Grid.Column>
        <Grid.Column width={6}>
          <Form.Checkbox label="Nur archivierte Mitarbeiter anzeigen" name="inactive" checked={formData.show_archived}
                         onChange={changeArchived}/>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <div className='vertical-scrollable'>
      <Table unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Anzeigename</Table.HeaderCell>
            <Table.HeaderCell>Resturlaub</Table.HeaderCell>
            <Table.HeaderCell>Urlaubsanspruch</Table.HeaderCell>
            <Table.HeaderCell>Begin Urlaubsjahr</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {vacations.map(vacation => <Table.Row key={vacation.id}>
            <Table.Cell>
              <Link to={`${vacation.id}`}>{vacation.name}</Link>
            </Table.Cell>
            <Table.Cell>{vacation.vacation_period_start && vacation.available_vacation_contingent_today}</Table.Cell>
            <Table.Cell>{vacation.vacation_contingent && <span>{vacation.vacation_contingent} Tage</span>}</Table.Cell>
            <Table.Cell><FormatedDateBus date={vacation.vacation_period_start}/></Table.Cell>
          </Table.Row>)
          }
        </Table.Body>
      </Table>
    </div>
  </Container>
}
