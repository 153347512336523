import React, {useEffect} from 'react'
import {Container, Grid, Header} from 'semantic-ui-react'
import BusesShortTable from "../BusesShortTable";
import {useGetFuelQuery} from "../../../lib/api/apiSlice";
import {useParams} from "react-router-dom";
import {Edit} from "../../core/shared/controls";

export default function FuelDetail(props) {
  let {id} = useParams()

  const {
    data: fuel = '',
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetFuelQuery(id)

  useEffect(() => {
    document.title = 'Kraftstoff Details | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
    <Grid style={{marginTop: '1em'}} stackable>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2">Kraftstoff Stammdaten</Header>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right'>
          <Edit/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row width={1}>
        <Grid.Column>
          <p>Name: {fuel.name}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <BusesShortTable header="Busse mit diesem Kraftstoff" buses={fuel.buses}/>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>

}


