import React, {useEffect} from 'react'
import {Link} from 'react-router-dom';

import {Container, Header, Table} from 'semantic-ui-react'
import {useGetStatesQuery} from "../../../lib/api/apiSlice";
import {New} from "../../core/shared/controls";


export default function States(props) {
  const {data: states = []} = useGetStatesQuery()

  useEffect(() => {
    document.title = 'Länder | First Class bus'
  }, [])


    return <Container style={{marginTop: '1em'}}>
          <Header as="h2">Länder</Header>
          <New/>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Kürzel</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Staat</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {states.map(state => <Table.Row key={state.id}>
                  <Table.Cell><Link to={`${state.id}`}>{state.matchcode}</Link></Table.Cell>
                  <Table.Cell>{state.name}</Table.Cell>
                  <Table.Cell>{state.country && state.country.name}</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
      </Container>
}
