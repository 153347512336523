import React, {useEffect, useState} from 'react'

import {Form, Grid} from 'semantic-ui-react'
import {Cancel, Submit} from "../../core/shared/controls";

export const initialPlace = {id: '', matchcode: '', name: ''}


export default function PlaceForm(props) {

  const [formData, setFormData] = useState({
    ...initialPlace
  })

  const change = (e) => {
    setFormData((prevState => ({...prevState, [e.target.name]: e.target.value})))
  }

  useEffect(() => {
    if(props.formData != undefined)
      setFormData(props.formData)
  }, [])

  return <Form>
    <Grid style={{marginTop: '1em'}} stackable>
      <Grid.Row width={1}>
        <Grid.Column>
          <Form.Field>
            <Form.Input label="Matchcode" placeholder="" name='matchcode' value={formData.matchcode} onChange={change}
                        required/>
            <Form.Input label="Ort" placeholder="" name='name' value={formData.name} onChange={change} required/>
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Submit onClick={() => props.onSubmit(formData)}/>
        <Cancel/>
      </Grid.Row>
    </Grid>
  </Form>
}

