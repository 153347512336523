import React, {useState} from "react"
import FullCalendar from '@fullcalendar/react' // must go before plugins
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'

import {useNavigate} from "react-router-dom";
import {useGetResourcesQuery, useGetSimulationConfigQuery, useGetSimulationsQuery} from "../../../lib/api/apiSlice";
import moment from "moment";

import "./simulation.css"

export default function SimulationCalendar(props) {
  const navigate = useNavigate()

  const [tooltip, setTooltip] = useState({
    event: {},
    x: 400,
    y: 400,
    show: false
  })
  const start = moment().format("YYYY-MM-DD")

  const {data: resources = [], isLoading, isSuccess, isError, error, refetch} = useGetResourcesQuery()
  const {data: config = {slotMinTime:'06:00'}} = useGetSimulationConfigQuery(start)
  const {
    data: simulations = [],
    isLoadingEvents,
    isSuccessEvents,
    isErrorEvents,
    errorEvents,
    refetchEvents
  } = useGetSimulationsQuery(start)

  const views = {
    resourceTimeline30min: {
      type: 'resourceTimeline',
      buttonText: "30 min",
      duration: {days: 1},
      slotDuration: {minutes: 30},
    },
    resourceTimeline15min: {
      type: 'resourceTimeline',
      buttonText: "15 min",
      duration: {days: 1},
      slotDuration: {minutes: 15},
    },
    resourceTimeline5min: {
      type: 'resourceTimeline',
      buttonText: "5 min",
      duration: {days: 1},
      slotDuration: {minutes: 5},
    },
  }

  const headerToolbar = {
    start: '',
    center: '',
    end: 'resourceTimeline5min,resourceTimeline15min,resourceTimeline30min'
  }

  const showTooltip = (info) => {
    const event = simulations.find(element => element.id === parseInt(info.event.id))
    const x = info.jsEvent.pageX
    const y = info.jsEvent.pageY
    const show = true
    setTooltip(prevState => ({...prevState, event: event, x: x, y: y, show: show}))
  }
  const hideTooltip = (info) => {
    const event = {}
    const x = info.jsEvent.pageX
    const y = info.jsEvent.pageY
    const show = false
    setTooltip(prevState => ({...prevState, event: event, x: x, y: y, show: show}))
  }

  const renderEventContent = (eventInfo) => {
    let class_name = ''
    const event = simulations.find(element => element.id === parseInt(eventInfo.event.id))

    return (
      <div className={`event ${class_name}`}>
        <span className='line_nr'>{event.line_nr}</span>
        <span className='course_nr'>{event.course_nr}</span>
        <br style={{clear:'both'}}/>
        <span className='departure_min'>{moment(event.start).format("mm")}</span>
        <span className='arrival_min'>{moment(event.end).format("mm")}</span>
        <br style={{clear:'both'}}/>
        <span className='departure_place'>{event.departure_place && event.departure_place.matchcode}</span>
        <span className='arrival_place'>{event.arrival_place && event.arrival_place.matchcode}</span>
      </div>
    )
  }

  return <div>
    {tooltip.show && <Tooltip {...tooltip}/>}
    <FullCalendar schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                  plugins={[resourceTimelinePlugin]}
                  initialView='resourceTimelineDay'
                  views={views}
                  resources={resources}
                  events={simulations}
                  resourceAreaWidth="15%"
                  timeZone='local'
                  editable={true}
                  scrollTime='00:00'
                  contentHeight='auto'
                  slotMinTime={config.slotMinTime}
                  headerToolbar={headerToolbar}
                  eventClick={(info) => navigate(`/courses/${info.event.id}`)}
                  eventMouseEnter={showTooltip}
                  eventMouseLeave={hideTooltip}
                  eventContent={renderEventContent}

    />
  </div>
}

const Tooltip = (props) => {
  const {event} = props
  return <div className='tooltiptopicevent'
              style={{
                width: 'auto',
                height: 'auto',
                background: '#f5f5f5',
                position: 'absolute',
                zIndex: 10001,
                padding: '10px 10px 10px 10px',
                lineHeight: '200%',
                top: props.y + 20,
                left: props.x - 60
              }}>
    {event.line_nr}<br/>
    {event.course_nr} <br/>
    Ab: {moment(event.start).format("HH:mm")} {event.departure_place.matchcode} <br/>
    An: {moment(event.end).format("HH:mm")} {event.arrival_place.matchcode} <br/>
    Type: {event.course_type.name}
  </div>
}
