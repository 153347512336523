import React, {useEffect} from 'react'
import {Container, Header} from "semantic-ui-react";
import {VacationForm} from "./VacationForm";
import {useNavigate, useParams} from "react-router-dom";
import {useAddNewVacationMutation} from "../../../lib/api/apiSlice";

export default function VacationNew(props) {
  let {staff_id} = useParams()
  const navigate = useNavigate()

  const [addNewVacation, {isLoading: isLoadingAddNewVacation}] = useAddNewVacationMutation(staff_id)

  const create = async (formData) => {
    try{
      let customized_formData = {...formData, staff_id: parseInt(staff_id)}
      await addNewVacation(customized_formData).unwrap()
      navigate(`/vacations/${staff_id}`)
    } catch(err){
      console.log('Failure', err)
    }
  }


  useEffect(() => {
    document.title = 'Urlaubseintrag anlegen | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
        <Header as="h5">Neuer Urlaubskontoeintrag</Header>
        <VacationForm onSubmit={create}/>
    </Container>
}
