import React, {useEffect} from 'react'


import {Header} from 'semantic-ui-react'
import SimulationCalendar from "./SimulationCalendar";

export default function Simulation(props) {
  useEffect(() => {
    document.title = 'Wagenumlauf Planung | First Class bus'
  }, [])


    return <div style={{marginTop: '1em', marginRight: '1em'}}>
        <Header>Wagenumlauf Planung</Header>
        <SimulationCalendar/>
    </div>
}
