import React, {Component} from 'react'
import {Header, Table} from "semantic-ui-react";
import {Link} from 'react-router-dom';
import FormatedDay from "../core/shared/FormatedDay";
import moment from "moment";


export default class YourNextService extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {upcoming_entries} = this.props

    if (upcoming_entries.length == 0) {
      return (
        <div>
          <Header>Deine nächsten Dienste</Header>
          <p>keine anstehenden Dienste eingetragen</p>
        </div>
      )
    } else {
      return (
        <div>
          <Header>Deine nächsten Dienste</Header>
          <Table>
            <Table.Body>
              {upcoming_entries && upcoming_entries.map((entry) => {
                  let name = ''
                  if (entry.schedule) {
                    name = <Link to={"/schedules/" + entry.schedule.id}>{entry.schedule.matchcode} - {entry.schedule.name}</Link>
                  } else if(entry.presetting){
                    name = entry.presetting.name
                  } else {
                    name = "-"
                }
                  return (
                    <Table.Row key={entry.day}>
                      <Table.Cell><FormatedDay
                        day={moment(entry.day).format("dddd")}/> {moment(entry.day).format("DD.MM.YYYY")}</Table.Cell>
                      <Table.Cell>{name}</Table.Cell>
                    </Table.Row>
                  )
                }
              )}
            </Table.Body>
          </Table>
        </div>
      )
    }
  }
}
