import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'

import {Container, Header, Table} from 'semantic-ui-react'
import {useGetPlacesQuery} from "../../../lib/api/apiSlice";
import {New} from "../../core/shared/controls";

export default function Places(props) {
  const {
    data: places = [],
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetPlacesQuery()


  useEffect(() => {
    document.title = 'Orte | First Class bus'
  }, [])

  return <Container style={{marginTop: '1em'}}>
    <Header as="h2">Orte</Header>
    <New/>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.HeaderCell>Matchcode</Table.HeaderCell>
          <Table.HeaderCell>Ort</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {places.map(place => <Table.Row>
          <Table.Cell><Link to={`${place.id}`}>{place.id}</Link></Table.Cell>
          <Table.Cell><Link to={`${place.id}`}>{place.matchcode}</Link></Table.Cell>
          <Table.Cell><Link to={`${place.id}`}>{place.name}</Link></Table.Cell>
        </Table.Row>)}
      </Table.Body>
    </Table>
  </Container>
}
