import React from 'react'
import moment from "moment";


export default function FormatedDateTimeBus(props) {

  const {date} = props
  const formatedDateTime = moment(date).format('DD.MM.YYYY HH:mm')

  return <span>
      {formatedDateTime}
   </span>

}
