import React from 'react'

import moment from 'moment'

// please also check FormatedReadableSeconds which is more flexible
export default function FormatedDuration(props) {


  const {sec} = props

  const duration = moment.utc(sec * 1000).format('HH:mm')

  return <span>
        {duration}
   </span>

}
