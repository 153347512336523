import {Button, Icon} from "semantic-ui-react";
import React from "react";

export function PeriodNavigation(props) {
  const {previousPeriod, currentPeriod, nextPeriod, title} = props

  return <div>
    <Button onClick={previousPeriod} icon basic>
      <Icon name="arrow left"/>
    </Button>
    <Button onClick={currentPeriod} basic>
      {title ? title : 'Aktuelle Periode'}
    </Button>
    <Button onClick={nextPeriod} icon basic>
      <Icon name="arrow right"/>
    </Button>
  </div>;
}
