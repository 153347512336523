import React, {useEffect} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'

import {Button, Card, Container, Divider, Grid, Header, Icon, Message, Table} from 'semantic-ui-react'
import FormatedDateBus from "../core/shared/FormatedDateBus";
import FormatedDuration from "../core/shared/FormatedDuration";
import TimeWithAlert from "./TimeWithAlert";
import TrafficdaycodeLabel from "../core/shared/trafficdaycode/TrafficdaycodeLabel";
import CourseTypeLabel from "../core/shared/course_type/CourseTypeLabel";
import ScheduleAlert from "./ScheduleAlert"
import TimeSlots from "../core/shared/time_slots/TimeSlots"
import ServiceType from "../core/shared/service_type/ServiceType";
import {
  useDuplicateScheduleMutation,
  useGetCheckQuery,
  useGetScheduleQuery,
  useUpdateScheduleRemoveCourseMutation
} from "../../lib/api/apiSlice";
import ScheduleCoursesTableRow from "./ScheduleCoursesTableRow";
import {Edit} from "../core/shared/controls";
import ApiPDF from "../../lib/api/apiPDF";

export default function ScheduleDetail(props) {
  let {id} = useParams()
  const navigate = useNavigate()

  const {data: schedule = []} = useGetScheduleQuery(id)
  const {data: checks = []} = useGetCheckQuery(id)

  const [duplicateSchedule] = useDuplicateScheduleMutation(id)
  const [updateScheduleRemoveCourse] = useUpdateScheduleRemoveCourseMutation()

  const duplicate = async () => {
    try {
      await duplicateSchedule(id).unwrap()
      navigate('/schedules')
    } catch (err) {
      console.log('Failure', err)
    }
  }

  const handleRemove = (course_id) => {
    if (confirm("Wollen Sie den Kurs wirklich aus dem Wagenumlauf entfernen?")) {
      try {
        updateScheduleRemoveCourse({id: id, course_id: course_id})
      } catch (err) {
        console.log('Failure', err)
      }
    }
  }

  useEffect(() => {
    document.title = 'Dienstkarte | FCBUS'
  }, [])


  return <Container>
    <Grid columns={2} style={{marginTop: "1em"}}>
      <Grid.Column>
        <Header as="h3">Dienstkarte: {schedule.matchcode} - {schedule.name}</Header>
      </Grid.Column>
      <Grid.Column textAlign="right">
        <Actions schedule={schedule} duplicate={duplicate}/>
      </Grid.Column>
    </Grid>
    <Grid columns={schedule.note ? 4 : 3} stackable>
      <Grid.Row>
        <Grid.Column>
          <ConfigurationTable schedule={schedule}/>
        </Grid.Column>
        <Grid.Column className="no-print">
          <SumTable schedule={schedule}/>
        </Grid.Column>
        <Grid.Column>
          <TimingTable schedule={schedule}/>
        </Grid.Column>
        {schedule.note ?
          <Grid.Column><Card header="Notiz" color="yellow" description={schedule.note}/></Grid.Column> : ""}
      </Grid.Row>
    </Grid>
    <div>
      {checks.time_slots && checks.time_slots.length > 0 && <TimeSlots time_slots={checks.time_slots}/>}
      {checks.alerts && checks.alerts.length == 0 && <Message content='Keine Alarme gefunden.' positive/>}
      {checks.alerts && checks.alerts.length > 0 && checks.alerts.map((alert, index) => <ScheduleAlert alert={alert}
                                                                                                       key={index}/>)}
      <Divider className='no-print'/>
    </div>
    <Header as="h5" className='no-print'>Kurse des Wagenumlaufs</Header>
    <Link to={`/courses/new?schedule_id=${schedule.id}&line_id=${schedule.line_id}`} className='no-print'>
      Neuen Kurs erstellen und zu Wagenumlauf hinzufügen</Link>
    <div className='vertical-scrollable'>
      <Table compact unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Linie</Table.HeaderCell>
            <Table.HeaderCell>Kurs</Table.HeaderCell>
            <Table.HeaderCell>Abfahrt</Table.HeaderCell>
            <Table.HeaderCell>Abfahrtsort</Table.HeaderCell>
            <Table.HeaderCell>Ankunft</Table.HeaderCell>
            <Table.HeaderCell>Ankunftsort</Table.HeaderCell>
            <Table.HeaderCell className="no-print">Bus</Table.HeaderCell>
            <Table.HeaderCell className="no-print">VTS</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell className="no-print">Meter</Table.HeaderCell>
            <Table.HeaderCell>Kommentar</Table.HeaderCell>
            <Table.HeaderCell><Icon name='cogs'/> </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {schedule.courses && schedule.courses.map((course) => <ScheduleCoursesTableRow key={course.id}
                                                                                       course={course}
                                                                                       handleRemove={handleRemove}
        />)}
      </Table>
    </div>
  </Container>
}

const ConfigurationTable = (props) => {
  const {schedule} = props
  return <Table compact unstackable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Eigenschaften</Table.HeaderCell>
        <Table.HeaderCell>Details</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      <Table.Row>
        <Table.Cell>Gültig ab</Table.Cell>
        <Table.Cell><FormatedDateBus date={schedule.valid_from}/> bis <FormatedDateBus
          date={schedule.valid_to}/></Table.Cell>
      </Table.Row>
      <Table.Row className='no-print'>
        <Table.Cell>Linie</Table.Cell>
        <Table.Cell>{schedule.line ? schedule.line.nr : ""}</Table.Cell>
      </Table.Row>
      <Table.Row className='no-print'>
        <Table.Cell>Bus</Table.Cell>
        <Table.Cell>{schedule.bus ? schedule.bus.name : ""}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>VTS</Table.Cell>
        <Table.Cell>
          {schedule.trafficdaycodes && schedule.trafficdaycodes.map(stdc => <TrafficdaycodeLabel key={stdc.code}
                                                                                                 content={stdc.code}/>
          )}
        </Table.Cell>
      </Table.Row>
      <Table.Row className='no-print'>
        <Table.Cell>Los</Table.Cell>
        <Table.Cell>{schedule.batch}</Table.Cell>
      </Table.Row>
      <Table.Row className='no-print'>
        <Table.Cell>Service Typ</Table.Cell>
        <Table.Cell><ServiceType service_type={schedule.service_type}/></Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
}

const SumTable = (props) => {
  const {schedule} = props;
  return <Table compact unstackable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Type</Table.HeaderCell>
        <Table.HeaderCell>Meter</Table.HeaderCell>
        <Table.HeaderCell>Duration</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {schedule.details && schedule.details.sum_by_course_types && schedule.details.sum_by_course_types.map((t, index) =>
        <Table.Row key={index}>
          <Table.Cell><CourseTypeLabel course_type={t}/></Table.Cell>
          <Table.Cell>{t.distance}</Table.Cell>
          <Table.Cell><FormatedDuration sec={t.duration}/></Table.Cell>
        </Table.Row>
      )}
      <Table.Row>
        <Table.Cell>Gesamt</Table.Cell>
        <Table.Cell>{schedule.details && schedule.details.sum_meter_total}</Table.Cell>
        <Table.Cell></Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
}
const TimingTable = (props) => {
  const {schedule} = props;
  return <Table compact className="no-print" unstackable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Art</Table.HeaderCell>
        <Table.HeaderCell>Dauer</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      <Table.Row>
        <Table.Cell>Arbeitszeit</Table.Cell>
        <Table.Cell style={schedule.manual_working_time != "00:00" ? {color: "#f2711c"} : { }}>
          <FormatedDuration sec={schedule.details && schedule.details.sum_seconds_working_hours}/>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Diäten</Table.Cell>
        <Table.Cell><FormatedDuration
          sec={schedule.details && schedule.details.sum_seconds_daily_allowance}/></Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Aufschläge</Table.Cell>
        <Table.Cell><FormatedDuration sec={schedule.details && schedule.details.sum_seconds_extra_pay}/></Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Einsatzzeit</Table.Cell>
        <Table.Cell>
          <TimeWithAlert sec={schedule.details && schedule.details.sum_seconds_operating_time} warning={15 * 60 * 60}/>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Lenkzeit</Table.Cell>
        <Table.Cell><TimeWithAlert sec={schedule.details && schedule.details.sum_seconds_driving_time}
                                   warning={11 * 60 * 60}/></Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Lenkpause</Table.Cell>
        <Table.Cell><FormatedDuration sec={schedule.details && schedule.details.sum_seconds_break}/></Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Ruhezeit</Table.Cell>
        <Table.Cell><FormatedDuration sec={schedule.details && schedule.details.sum_seconds_rest_period}/></Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Tagesarbeitszeit</Table.Cell>
        <Table.Cell><FormatedDuration
          sec={schedule.details && schedule.details.sum_seconds_daily_work_time}/></Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Leerfahrt</Table.Cell>
        <Table.Cell><FormatedDuration sec={schedule.details && schedule.details.sum_seconds_empty_drive}/></Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Sonstiges</Table.Cell>
        <Table.Cell><FormatedDuration sec={schedule.details && schedule.details.sum_seconds_misc}/></Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
}

const Actions = (props) => {
  const {schedule} = props;


  const pdf_url = `${API_URL}/v1/admin/fcb/pdf/schedules/${schedule.id}`
  const filename = `Wagenumlauf-${schedule.matchcode}-${schedule.id}.pdf`

  return <Container style={{marginTop: '1em'}}>
    <Edit/>
    <Button onClick={props.duplicate} className='no-print'>
      Duplizieren
    </Button>
    <Button onClick={() => ApiPDF.fetch_pdf(pdf_url, filename)}>
      <Icon name="file pdf"/>Wagenumlauf
    </Button>
  </Container>
}

