import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom';

import {Button, Container, Form, Header, Icon, Message, Table} from "semantic-ui-react";
import FormatedDateBus from "../core/shared/FormatedDateBus";
import TimeWithAlert from './TimeWithAlert'
import TrafficdaycodeLabel from "../core/shared/trafficdaycode/TrafficdaycodeLabel";
import {useDuplicateScheduleMutation, useGetSchedulesQuery} from "../../lib/api/apiSlice";
import {New} from "../core/shared/controls";
import FormatedDuration from "../core/shared/FormatedDuration";

export default function Schedules(props) {

  const schedulesInitialState = {
    show_archived: false,
    query: ''
  }

  const [formData, setFormData] = useState({
    ...schedulesInitialState
  })

  const {
    data: schedules = [],
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetSchedulesQuery({show_archived: formData.show_archived, query: formData.query})

  const [duplicateSchedule] = useDuplicateScheduleMutation()

  const duplicate = async (id) => {
    try {
      await duplicateSchedule(id).unwrap()
      navigate('/schedules')
    } catch (err) {
      console.log('Failure', err)
    }
  }

  const changeArchived = (e, data) => {
    setFormData((prevState => ({...prevState, show_archived: data.checked})))
    refetch()
  }
  const change = (e) => {
    setFormData((prevState => ({...prevState, [e.target.name]: e.target.value})))
    refetch()
  }
  const resetSearch = () => {
    setFormData({...schedulesInitialState})
    refetch()
  }

  useEffect(() => {
    document.title = 'Schedules | First Class bus'
  }, [])

  let body;

  if (schedules.length > 0) {
    body = <div className='vertical-scrollable'>
      <Table unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Kürzel</Table.HeaderCell>
            <Table.HeaderCell>Bezeichnung</Table.HeaderCell>
            <Table.HeaderCell>Los</Table.HeaderCell>
            <Table.HeaderCell>Gültig ab</Table.HeaderCell>
            <Table.HeaderCell>Gültig bis</Table.HeaderCell>
            <Table.HeaderCell>VTS</Table.HeaderCell>
            <Table.HeaderCell>Arbeitszeit</Table.HeaderCell>
            <Table.HeaderCell>Diäten</Table.HeaderCell>
            <Table.HeaderCell>Lenkzeit</Table.HeaderCell>
            <Table.HeaderCell>Einsatzzeit</Table.HeaderCell>
            <Table.HeaderCell className='no-print'><Icon name='cogs'/> </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {schedules.map(schedule => <Table.Row key={schedule.id}>
              <Table.Cell>
                <Link to={`${schedule.id}`}>{schedule.matchcode}</Link>
              </Table.Cell>
              <Table.Cell>
                <Link to={`${schedule.id}`}>{schedule.name}</Link>
              </Table.Cell>
              <Table.Cell>
                {schedule.batch}
              </Table.Cell>
              <Table.Cell>
                <FormatedDateBus date={schedule.valid_from}/>
              </Table.Cell>
              <Table.Cell>
                <FormatedDateBus date={schedule.valid_to}/>
              </Table.Cell>
              <Table.Cell>
                {schedule.trafficdaycodes.map((stdc, index) =>
                  <TrafficdaycodeLabel key={index} content={stdc.code}/>)
                }
              </Table.Cell>
              <Table.Cell>
                <FormatedDuration sec={schedule.sum_seconds_working_hours}/>
              </Table.Cell>
              <Table.Cell>
                <FormatedDuration sec={schedule.sum_seconds_daily_allowance}/>
              </Table.Cell>
              <Table.Cell>
                <TimeWithAlert sec={schedule.sum_seconds_driving_time} warning={11 * 60 * 60}/>
              </Table.Cell>
              <Table.Cell>
                <TimeWithAlert sec={schedule.sum_seconds_operating_time} warning={15 * 60 * 60}/>
              </Table.Cell>
              <Table.Cell className='no-print'>
                <Button onClick={() => duplicate(schedule.id)} icon basic size='mini' title='Duplizieren'>
                  <Icon name="clone"/>
                </Button>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  } else {
    if (formData.query === '') {
      body = <Message>Keine Wagenumläufe vorhanden. Versuchen Sie die Seite neu zu laden.</Message>
    } else {
      body = <Message>Keine Wagenumläufe gefunden für: {formData.query}</Message>
    }
  }

  return <Container style={{marginTop: '1em'}}>
    <Header size="huge">Wagenumlauf</Header>
    <New/>
    <br/>
    <br/>
    <div>
      <Form>
        <Form.Group widths='equal'>
          <Form.Input placeholder="Suche" name="query" value={formData.query}
                      onChange={change}/>
          <Form.Checkbox label="Archivierte anzeigen" name="show_archived" checked={formData.show_archived}
                         onChange={changeArchived}/>
          <Form.Button onClick={resetSearch} basic>Zurücksetzten</Form.Button>
        </Form.Group>
      </Form>
    </div>
    {body}
  </Container>
}

