import React from "react"

import {Button} from "semantic-ui-react"
import {useNavigate} from "react-router-dom";

export function Cancel(props){
  const navigate = useNavigate()

  return <Button onClick={() => navigate(-1)}>Abbrechen</Button>
}
