import React from 'react'
import {Link} from "react-router-dom";

export default function VacationAccountType(props) {

  const {vacation_account} = props
  let ret_val = ''
  if (vacation_account.is_amount_carry_forward) {
    ret_val += "Übertrag "
  }
  if (vacation_account.is_amount_period_contingent) {
    ret_val += "Anspruch "
  } else if (!vacation_account.is_amount_period_contingent) {
    ret_val += "Verbrauch "
  }
  if (vacation_account.type == "Entry") {
    ret_val = <Link to={`/services`}>Verbrauch Dienstplan</Link>
  }
  return <span>
      {ret_val}
    </span>
}
