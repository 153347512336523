import React, {useEffect, useState} from 'react'
import {Form, Grid} from "semantic-ui-react";
import {Cancel, Remove, Submit} from "../../core/shared/controls";

export const initialOvertime = {
  staff_id:'',
  day: '',
  amount: '',
  note: '',
  is_increase: false,
  is_decrease: false,
  is_pay_out: false,
}

export function OvertimeForm(props){
  const [formData, setFormData] = useState({
  ...initialOvertime
  })

  const change = (e, data) => {
    setFormData((prevState => ({...prevState, [e.target.name]: e.target.value})))
  }
  const handleCheckIsIncrease = () => {
    setFormData((prevState => ({...prevState, is_increase: !prevState.is_increase})))

  }
  const handleCheckIsDecrease = () => {
    setFormData((prevState => ({...prevState, is_decrease: !prevState.is_decrease})))
  }
  const handleCheckIsPayOut = () => {
    setFormData((prevState => ({...prevState, is_pay_out: !prevState.is_pay_out})))
  }

  useEffect(() => {
    if (props.formData != undefined)
      setFormData(props.formData)
  }, [])


    return <Form>
      <Grid stackable>
        <Grid.Row columns={3}>
          <Grid.Column>
            <Form.Input label='Eintragsdatum' name='day' value={formData.day}
                        onChange={change} type='date' required/>
          </Grid.Column>
          <Grid.Column>
            <Form.Input label='Dauer'
                        placeholder={'Std:Min'}
                        name='amount'
                        value={formData.amount}
                        onChange={change}/>
          </Grid.Column>
          <Grid.Column>
            <Form.Input label='Notiz' name='note' value={formData.note}
                        onChange={change}/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <Form.Checkbox label="Zubuchung" placeholder="" name='is_increase'
                           checked={formData.is_increase}
                           onChange={handleCheckIsIncrease}
                           type="checkbox"/>
          </Grid.Column>
          <Grid.Column>
            <Form.Checkbox label="Abbuchung" placeholder="" name='is_decrease'
                           checked={formData.is_decrease}
                           onChange={handleCheckIsDecrease}
                           type="checkbox"/>
          </Grid.Column>
          <Grid.Column>
            <Form.Checkbox label="Auszahlung" placeholder="" name='is_pay_out'
                           checked={formData.is_pay_out}
                           onChange={handleCheckIsPayOut}
                           type="checkbox"/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Submit onClick={() => props.onSubmit(formData)}/>
          <Cancel/>
          {formData.id && <Remove onClick={() => props.onRemove(formData)}/>}
        </Grid.Row>
      </Grid>
    </Form>
}
