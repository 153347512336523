import React from 'react'

import {Container, Header} from 'semantic-ui-react'
import {ConstructionForm, initialConstruction} from "./ConstructionForm";
import {useNavigate, useParams} from "react-router-dom";

import {useGetConstructionQuery, useUpdateConstructionMutation} from "../../../lib/api/apiSlice";


export default function ConstructionEdit(props) {
  let {id} = useParams()
  const navigate = useNavigate()

  const {
    data: construction = initialConstruction, isLoading, isSuccess, isError, error, refetch
  } = useGetConstructionQuery(id)
  const [updateConstruction, {isLoading: isLoadingUpdateConstruction}] = useUpdateConstructionMutation()

  const update = async (formData) => {
    try {
      await updateConstruction({
        id: id,
        body: formData
      }).unwrap()
      navigate(`/constructions/${id}`)
    } catch (err) {
      console.log('Failure', err)
    }
  }

  return <Container style={{marginTop: '1em'}}>
    <Header as="h5">Fahrzeugtyp bearbeiten</Header>
    {!isLoading && <ConstructionForm formData={construction} onSubmit={update}/>}
  </Container>
}

