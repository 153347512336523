import React, {useEffect} from 'react'

import {Container, Header} from "semantic-ui-react";
import {useNavigate, useParams} from "react-router-dom";
import {useGetSettingsQuery, useUpdateSettingsMutation} from "../../lib/api/apiSlice";
import {SettingForm} from "./SettingForm";


export default function SettingEdit(props) {
  let {id} = useParams()
  const navigate = useNavigate()

  const  {data: setting = initialState, isLoading} = useGetSettingsQuery(id)
  const [updateSetting] = useUpdateSettingsMutation()

  const update = async (formData) => {
    try{
      await updateSetting(formData).unwrap()
      navigate(`/settings`)
    } catch(err){
      console.log('Failure', err)
    }
  }
  useEffect(() => {
    document.title = 'Unternehmensprofil bearbeiten | First Class bus'
  }, [])

    return <Container style={{marginTop: '1em'}}>
        <Header as="h2">Unternehmensprofil bearbeiten</Header>
      {!isLoading && <SettingForm formData={setting} onSubmit={update}/>}
    </Container>
}
