import React, {useEffect, useState} from 'react'
import {Form, Grid} from "semantic-ui-react";
import {Cancel, Submit} from "../core/shared/controls";

export const initialSetting = {
  id: '',
  work_hours_round: '',
}

export function SettingForm(props) {

  const [formData, setFormData] = useState({
    ...initialSetting
  })

  const change = (e) => {
    setFormData((prevState => ({...prevState, [e.target.name]: e.target.value})))
  }
  useEffect(() => {
    if (props.formData != undefined)
      setFormData(props.formData)
  }, [])

  const handleCheck = (e, data) => {
    setFormData((prevState => ({...prevState, [data.name]: data.checked })))
  }

  return <Form>
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={8}>
          <Form.Field>
            <Form.Checkbox label='Arbeitszeit auf 15min runden' name='work_hours_round'
                           checked={formData.work_hours_round}
                           onChange={handleCheck} type='checkbox'/>
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Submit onClick={() => props.onSubmit(formData)}/>
        <Cancel/>
      </Grid.Row>
    </Grid>
  </Form>
}
