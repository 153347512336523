import React, {useEffect, useState} from 'react'

import {Dropdown, Form} from 'semantic-ui-react'
import {
  useGetBusesSelectQuery,
  useGetCountriesSelectQuery,
  useGetLinesSelectQuery,
  useGetStatesSelectQuery,
  useGetTrafficdaycodesSelectQuery
} from "../../lib/api/apiSlice";
import {Cancel, Submit} from "../core/shared/controls";

export const initialSchedule = {
  name: '',
  bus_id: '',
  line_id: '',
  trafficdaycode_ids: [],
  country_id:'',
  state_id:'',
  valid_from: '',
  valid_to: '',
  manual_working_time:'',
  note:''
}

export function ScheduleForm(props) {

  const [formData, setFormData] = useState({
    ...initialSchedule
  })

  const {data: buses_select = []} = useGetBusesSelectQuery()
  const {data: trafficdaycodes_select = []} = useGetTrafficdaycodesSelectQuery()
  const {data: lines_select = []} = useGetLinesSelectQuery()
  const {data: countries_select = []} = useGetCountriesSelectQuery()
  const {data: states_select = []} = useGetStatesSelectQuery()

  const change = (e, data) => {
    setFormData((prevState => ({...prevState, [e.target.name]: e.target.value})))
  }
  const changeDropdown = (e, data) => {
    setFormData((prevState => ({...prevState, [data.name]: data.value})))
  }
  const changeMatchcode = (e) => {
    let value = e.target.value.substr(0, 6)
    setFormData((prevState => ({...prevState, [e.target.name]: value})))
  }

  useEffect(() => {
    if (props.formData != undefined)
      setFormData(props.formData)
  }, [])

  return <Form>
      <Form.Group>
        <Form.Input label='Matchcode (max. 6 Zeichen)' name='matchcode' width={4}
                    value={formData.matchcode} onChange={changeMatchcode} required/>
        <Form.Input label="Wagenumlaufname" placeholder="" name='name' value={formData.name} width={12}
                    onChange={change} required/>
      </Form.Group>
      <Form.Group widths='equal'>
        <Form.Field>
          <label>Bus wählen</label>
          <Dropdown placeholder='Bus wählen' name="bus_id" onChange={changeDropdown} value={formData.bus_id} fluid
                    search selection options={buses_select}/>
        </Form.Field>
        <Form.Field>
          <label>Linie</label>
          <Dropdown placeholder="" name='line_id' value={formData.line_id} onChange={changeDropdown} fluid search
                    selection options={lines_select}/>
        </Form.Field>
        <Form.Field>
          <label>Verkehrstagesschlüssel</label>
          <Dropdown placeholder='Verkehrstagesschlüssel' name="trafficdaycode_ids" onChange={changeDropdown}
                    value={formData.trafficdaycode_ids} fluid multiple search selection options={trafficdaycodes_select}/>
        </Form.Field>
      </Form.Group>
      <Form.Group widths='equal'>
        <Form.Field>
          <Form.Input label="Gültig ab" placeholder="" name='valid_from' value={formData.valid_from} onChange={change}
                      type='date' required/>
        </Form.Field>
        <Form.Field>
          <Form.Input label="Gültig bis" placeholder="" name='valid_to' value={formData.valid_to} onChange={change}
                      type='date' required/>
        </Form.Field>
      </Form.Group>
      <Form.Group widths='equal'>
        <Form.Field>
          <label>Staat</label>
          <Dropdown name="country_id"
                    onChange={changeDropdown} value={formData.country_id}
                    fluid selection clearable options={countries_select} required/>
        </Form.Field>
        <Form.Field>
          <label>Land</label>
          <Dropdown name="state_id"
                    onChange={changeDropdown} value={formData.state_id}
                    fluid selection clearable options={states_select} required/>
        </Form.Field>
      </Form.Group>
    <Form.Field>
      <Form.Input label="Arbeitszeit überschreiben" placeHolder="" name='manual_working_time'
                  value={formData.manual_working_time}
                  onChange={change}
                  type='time'/>
    </Form.Field>
      <Form.Field>
        <Form.Input label="Notiz" placeholder="" name='note' value={formData.note} onChange={change}/>
      </Form.Field>
    <Submit onClick={() => props.onSubmit(formData)}/>
    <Cancel/>
    </Form>

}

