import React, {useEffect, useState} from 'react'
import {Checkbox, Divider, Form, Grid, Message, Segment} from "semantic-ui-react";
import {Cancel, Submit} from "../../core/shared/controls";

export const initialPresetting = {
  id: '',
  name: '',
  auto_logging_start: '',
  auto_logging_end: '',
  vacation: false,
  comp_time: false,
  variable_working_time: false,
  show_in_logging: false,
  special_vacation: false,
  special_vacation_type: '',
  auto_logging_duration: '',
  daily_allowance: false,
  extra_pay: false
}

export function PresettingForm(props) {
  const [formData, setFormData] = useState({
    ...initialPresetting
  })
  const special_vacation_type_select = [
    {key: '0', value: 'standard', text: 'Standard'},
    {key: '1', value: 'home_care', text: 'Pflege'},
    {key: '2', value: 'contingent_each_x_months', text: 'Anspruch alle X Monate'}
  ]

  const change = (e) => {
    setFormData((prevState => ({...prevState, [e.target.name]: e.target.value})))
  }

  const changeMatchcode = (e) => {
    let value = e.target.value.substr(0, 6)
    setFormData((prevState => ({...prevState, [e.target.name]: value})))
  }

  const handleCheckbox = (event, data) => {
    setFormData((prevState => ({...prevState, [data.name]: data.checked})))
  }

  const changeDropdown = (e, data) => {
    setFormData((prevState => ({...prevState, [data.name]: data.value})))
  }

  useEffect(() => {
    if (props.formData != undefined)
      setFormData(props.formData)
  }, [])


  return <Form>
    <Form.Group>
      <Form.Input label='Matchcode (max. 6 Zeichen)' name='matchcode' width={4}
                  value={formData.matchcode} onChange={changeMatchcode} required/>
      <Form.Input label='Name' name='name' width={12}
                  value={formData.name} onChange={change} required/>
    </Form.Group>
    <Divider horizontal>Automatische Zeiterfassung</Divider>
    <Form.Group widths='equal'>
      <Form.Field>
        <Form.Input label="Dauer" placeholder="" name='auto_logging_duration'
                    value={formData.auto_logging_duration} onChange={change}
                    type="time"/>
      </Form.Field>

      <Message info><Message.Header>Hinweis</Message.Header>Ist die Dauer für die automatische Zeiterfassung leer,
        wird diese ignoriert.
      </Message>
    </Form.Group>
    <Divider content='Eigenschaften' horizontal/>
    <Grid columns='5'>
      <Grid.Row>
        <Grid.Column>
          <Segment>
            <Form.Field>
              <Checkbox label="Diätenfähig" placeholder="" name='daily_allowance'
                        checked={formData.daily_allowance} onChange={handleCheckbox}
                        type="checkbox"/>
            </Form.Field>
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment>
            <Form.Field>
              <Checkbox label="Aufschlägsfähig" placeholder="" name='extra_pay'
                        checked={formData.extra_pay} onChange={handleCheckbox}
                        type="checkbox"/>
            </Form.Field>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Segment>
            <Form.Field>
              <Checkbox label="Urlaub" placeholder="" name='vacation'
                        checked={formData.vacation} onChange={handleCheckbox}
                        type="checkbox"/>
            </Form.Field>
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment>
            <Form.Field>
              <Checkbox label="Zeitausgleich" placeholder="" name='comp_time'
                        checked={formData.comp_time} onChange={handleCheckbox}
                        type="checkbox"/>
            </Form.Field>
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment>
            <Checkbox label="Variable Arbeitszeit" placeholder="" name='variable_working_time'
                      checked={formData.variable_working_time} onChange={handleCheckbox}
                      type="checkbox"/>
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment>
            <Form.Field>
              <Checkbox label="In Zeiterfassung anzeigen" placeholder="" name='show_in_logging'
                        checked={formData.show_in_logging} onChange={handleCheckbox}
                        type="checkbox"/>
            </Form.Field>
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment>
            <Form.Field>
              <Checkbox label="Krankenstand" placeholder="" name='sick_leave'
                        checked={formData.sick_leave} onChange={handleCheckbox}
                        type="checkbox"/>
            </Form.Field>
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment>
            <Form.Field>
              <Checkbox label="Dienstverhinderung" placeholder="" name='special_vacation'
                        checked={formData.special_vacation} onChange={handleCheckbox}
                        type="checkbox"/>
            </Form.Field>

            {formData.special_vacation &&
              <Form.Dropdown label="Dienstverhinderungsart" placeholder="" name="special_vacation_type"
                             value={formData.special_vacation_type}
                             onChange={changeDropdown}
                             fluid search selection options={special_vacation_type_select}></Form.Dropdown>

            }
            {(formData.special_vacation == true &&
                (formData.special_vacation_type == "standard" ||
                  formData.special_vacation_type == "contingent_each_x_months")
              ) &&
              <Form.Input label='Freistellung Arbeitstage' name='special_vacation_contingent'
                          value={formData.special_vacation_contingent} onChange={change}
                          type="number"
              />}
            {(formData.special_vacation == true && formData.special_vacation_type == "home_care") &&
              <Form.Field><p>1 Arbeitswoche </p></Form.Field>
            }
            {(formData.special_vacation == true && formData.special_vacation_type == "contingent_each_x_months") &&
              <Form.Input label='Zeitraum Gültigkeit' name='special_vacation_each_x_months'
                          value={formData.special_vacation_each_x_months} onChange={change}
                          type="number"
              />}
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Grid.Row columns={1}>
      <Grid.Column>
        < br/>
        <Submit onClick={() => props.onSubmit(formData)}/>
        <Cancel/>
      </Grid.Column>
    </Grid.Row>
  </Form>
}
