import React from 'react'
import FCBusLogoInline from "./FCBusLogoInline";

const FCBusLogo = () => (
    <div style={{fontSize:'1em',
      fontWeight:'bold',
      background:'transparent',

    }}>
      <FCBusLogoInline/>
    </div>
)

export default FCBusLogo
