import React from "react"

import {Button} from "semantic-ui-react"
import {Link} from "react-router-dom";

export function VarButton(props){
  if (props.onClick != undefined) {
    const buttonWithNavigate = <Button basic onClick={props.onClick}>
      {props.title ? props.title : "Neu"}
    </Button>

    return buttonWithNavigate
  } else if (props.to != undefined && props.title != undefined)  {
    const classicLinkButton = <Button basic as={Link} to={props.to}>
      {props.title}
    </Button>

    return classicLinkButton
  } else {
    return <span>Error props missing.</span>
  }
}
