import React from 'react'
import {Button, Container, Grid, Header, Icon, Loader, Table} from "semantic-ui-react";
import FormatedDateBus from "../../core/shared/FormatedDateBus";
import FormatedMonth from "../../core/shared/FormatedMonth";
import LoggingDescription from "./LoggingDescription";
import FormatedReadableSeconds from "../../core/shared/FormatedReadableSeconds";
import {useParams, useSearchParams} from "react-router-dom";
import {useGetLoggingsStaffQuery, useGetStaffQuery} from "../../../lib/api/apiSlice";
import {Edit} from "../../core/shared/controls";
import ApiPDF from "../../../lib/api/apiPDF";
import {lastDayOfPreviousMonth} from "../../../lib/time/Time";

export default function LoggingStaff(props) {
  let {staff_id} = useParams()
  const [searchParams, setSearchParams] = useSearchParams({});

  const year = searchParams.get("year")
  const month = searchParams.get("month")

  const {
    data: staff_service_loggings = {
      time_recordings: [],
      details: {
        total_daily_allowance: 0,
        working_time: 0,
        total_extra_pay: 0,
        overtime: 0,
        previous_overtime:0,
        new_overtime:0
      },
      schedules: [],
      presettings: []
    },
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetLoggingsStaffQuery({staff_id: staff_id, year: year, month: month})

  const {
    data: staff = {},
    isLoadingStaff,
    isSuccessStaff,
    isErrorStaff,
    errorStaff,
    refetchStaff
  } = useGetStaffQuery(staff_id)

  const pdf_url = `${API_URL}/v1/admin/fcb/pdf/loggings/staff/${staff_id}/${year}/${month}`
  const filename = `Stundenliste-${staff_id}-${year}-${month}.pdf`

  return <Container style={{marginTop: '1em'}}>
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2">
            Zeiterfassung<br/>
            {staff.name} <FormatedMonth month={month}/> {year}
          </Header>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right' className="no-print">
          <Button onClick={() => ApiPDF.fetch_pdf(pdf_url, filename)}>
            <Icon name="file pdf"/>Stundenliste
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Table compact='very' striped celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Datum</Table.HeaderCell>
          <Table.HeaderCell>Dauer</Table.HeaderCell>
          <Table.HeaderCell>Diäten</Table.HeaderCell>
          <Table.HeaderCell>Aufschläge</Table.HeaderCell>
          <Table.HeaderCell>Dienst</Table.HeaderCell>
          <Table.HeaderCell className="no-print"><Icon name='cogs'/></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {staff_service_loggings.time_recordings && staff_service_loggings.time_recordings.map(time_recording => {
            return <Table.Row key={time_recording.id}>
              <Table.Cell className="print-shrinked-table-row"><FormatedDateBus date={time_recording.day}/>
              </Table.Cell>
              <Table.Cell className="print-shrinked-table-row">
                {time_recording.working_time > 0 &&
                  <FormatedReadableSeconds seconds={time_recording.working_time}/>
                }
                {time_recording.comp_time_duration > 0 &&
                  <i><FormatedReadableSeconds seconds={time_recording.comp_time_duration}/></i>
                }
              </Table.Cell>
              <Table.Cell className="print-shrinked-table-row">
                {time_recording.daily_allowance > 0 &&
                  <FormatedReadableSeconds seconds={time_recording.daily_allowance}/>
                }
              </Table.Cell>
              <Table.Cell className="print-shrinked-table-row">
                {time_recording.extra_pay > 0 &&
                  <FormatedReadableSeconds seconds={time_recording.extra_pay}/>
                }
              </Table.Cell>
              <Table.Cell className="print-shrinked-table-row" style={{wordWrap:"break-all"}}>
                <LoggingDescription time_recording={time_recording}
                                    schedules={staff_service_loggings.schedules}
                                    presettings={staff_service_loggings.presettings}
                />
              </Table.Cell>
              <Table.Cell className="print-shrinked-table-row no-print">
                {time_recording.type == "Logging" && <Edit to={`/loggings/${time_recording.id}/edit`} icon/>}
              </Table.Cell>
            </Table.Row>
          }
        )}
        <Table.Row>
          <Table.Cell className="print-shrinked-table-row"><strong>Monatssumme</strong></Table.Cell>
          <Table.Cell className="print-shrinked-table-row">
            <strong>
              <FormatedReadableSeconds seconds={staff_service_loggings.details.working_time}/>
            </strong>
          </Table.Cell>
          <Table.Cell className="print-shrinked-table-row">
            <strong>
              <FormatedReadableSeconds seconds={staff_service_loggings.details.total_daily_allowance}/>
            </strong>
          </Table.Cell>
          <Table.Cell className="print-shrinked-table-row">
            <strong>
              <FormatedReadableSeconds seconds={staff_service_loggings.details.total_extra_pay}/>
            </strong>
          </Table.Cell>
          <Table.Cell className="print-shrinked-table-row"></Table.Cell>
          <Table.Cell className="print-shrinked-table-row no-print"></Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="print-shrinked-table-row"><strong>Monatsstunden Soll</strong></Table.Cell>
          <Table.Cell className="print-shrinked-table-row">{staff_service_loggings.details.regular_working_time_month}</Table.Cell>
          <Table.Cell colSpan={'4'}></Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="print-shrinked-table-row"><strong>Monatsüberstunden</strong></Table.Cell>
          <Table.Cell className="print-shrinked-table-row">{staff_service_loggings.details.overtime}</Table.Cell>
          <Table.Cell colSpan={'4'}></Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="print-shrinked-table-row"><strong>Saldo Zeitausgleich per {lastDayOfPreviousMonth()}</strong></Table.Cell>
          <Table.Cell className="print-shrinked-table-row">{staff_service_loggings.details.previous_overtime}</Table.Cell>
          <Table.Cell colSpan={'4'}></Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="print-shrinked-table-row"><strong>Überstunden Akkumuliert</strong></Table.Cell>
          <Table.Cell className="print-shrinked-table-row">{staff_service_loggings.details.new_overtime}</Table.Cell>
          <Table.Cell colSpan={'4'}></Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
    {isLoading && <Loader active inline='centered' />}
  </Container>
}
