import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {Container, Form, Grid, Header, Segment, Table} from 'semantic-ui-react'
import {useGetBusesQuery} from "../../lib/api/apiSlice";
import {New} from "../core/shared/controls";


export default function Buses(props) {
  const [showInactive,setShowInactive] = useState(false)

  const {
    data: buses = [],
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetBusesQuery(showInactive)

  const changeInactive = (e, data) => {
    const checked = data.checked
    setShowInactive(checked)
    refetch()
  }

  useEffect(() => {
    document.title = 'Busse | First Class bus'
  }, [])


  return <Container style={{marginTop: '1em'}}>
    <Header as="h2">Busliste</Header>
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={10}>
          <New/>
        </Grid.Column>
        <Grid.Column width={6}>
          <Form.Checkbox label="Nur inaktive Busse anzeigen" name="inactive" checked={showInactive}
                         onChange={changeInactive}/>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <div className='vertical-scrollable'>
      <Segment loading={isLoading}>
        <Table unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Kennzeichen</Table.HeaderCell>
              <Table.HeaderCell>Begutachtung</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {buses.map(bus => <Table.Row key={bus.id}>
                <Table.Cell>
                  <Link to={`${bus.id}`}>{bus.id}</Link>
                </Table.Cell>
                <Table.Cell>
                  <Link to={`${bus.id}`}>{bus.name}</Link>
                </Table.Cell>
                <Table.Cell>
                  <Link to={`${bus.id}`}>{bus.license_plate}</Link>
                </Table.Cell>
                <Table.Cell>
                  <Link to={`${bus.id}`}>{bus.safety_check}</Link>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Segment>
    </div>
  </Container>
}
