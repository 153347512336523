import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import moment from "moment";
import {hhmm} from "../time/Time";

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery(
    {
      baseUrl: `${API_URL}/v1/admin/fcb`,
      mode: "cors",
      prepareHeaders: (headers, {getState}) => {
        const auth = JSON.parse(sessionStorage.getItem('auth'))
        headers.set('Content-Type', 'application/json')
        headers.set('Authorization', 'Basic ' + auth.token)
        return headers
      }
    }),
  tagTypes: ['Bus', 'Fuel', 'Construction', 'Emission', 'Tag', 'Event', 'Trafficdaycode', 'Coursetype', 'Course',
    'Line', 'Schedule', 'ScheduleSelect', 'ScheduleServiceSelect', 'Check', 'Country', 'Resource', 'Simulation',
    'Presetting', 'PresettingsVariableWorkingTimeSelect', 'Vacation', 'Staff',
    'VacationsForStaff', 'LoggingOverview', 'Logging', 'LoggingStaff', 'DrivingLicenseGroup',
    'ServiceStaffMap', 'PublicHoliday', 'SchoolHoliday', 'UpcomingEntry', 'Dashboard', 'SimulationConfig', 'Settings',
    'Overtime', 'Overtimes', 'OvertimesForStaff', 'Entry'],
  endpoints: builder => ({
    getBus: builder.query({
      query: id => `/buses/${id}`,
      transformResponse: responseData => {
        let tag_ids = []

        responseData.tags.forEach(function (tag) {
          tag_ids.push(tag.id)
        })

        return {
          ...responseData,
          tag_ids: tag_ids
        }
      },
      providesTags: (result, error, arg) => [{type: 'Bus', id: arg}]
    }),
    getBuses: builder.query({
      query: (showInactive = false) => `/buses?show_inactive=${showInactive}`,
      providesTags: (result = [], error, arg) => [
        'Bus',
        ...result.map(({id}) => ({type: 'Bus', id}))
      ]
    }),
    getBusesSelect: builder.query({
      query: () => `/buses`,
      transformResponse: responseData => {
        return transformResponseToSelect(responseData);
      },
      providesTags: (result = [], error, arg) => [
        'Bus',
        ...result.map(({id}) => ({type: 'Bus', id}))
      ]
    }),
    addNewBus: builder.mutation({
      query: body => ({
        url: `/buses`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Bus', 'Dashboard']
    }),
    updateBus: builder.mutation({
      query: ({id, body}) => ({
        url: `/buses/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => [{type: 'Bus', id: arg.id}, 'Dashboard']
    }),
    getFuels: builder.query({
      query: (showInactive = false) => `/fuels`,
      providesTags: (result = [], error, arg) => [
        'Fuel',
        ...result.map(({id}) => ({type: 'Fuel', id}))
      ]
    }),
    getFuelsSelect: builder.query({
      query: () => `/fuels`,
      transformResponse: responseData => {
        return transformResponseToSelect(responseData);
      },
      providesTags: (result = [], error, arg) => [
        'Fuel',
        ...result.map(({id}) => ({type: 'Fuel', id}))
      ]
    }),
    getFuel: builder.query({
      query: id => `/fuels/${id}`,
      providesTags: (result, error, arg) => [{type: 'Fuel', id: arg}]
    }),
    updateFuel: builder.mutation({
      query: ({id, body}) => ({
        url: `/fuels/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => [{type: 'Fuel', id: arg.id}]
    }),
    addNewFuel: builder.mutation({
      query: body => ({
        url: `/fuels`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Fuel']
    }),
    getConstructions: builder.query({
      query: (showInactive = false) => `/vehicleconstructiontypes`,
      providesTags: (result = [], error, arg) => [
        'Construction',
        ...result.map(({id}) => ({type: 'Construction', id}))
      ]
    }),
    getConstructionsSelect: builder.query({
      query: (showInactive = false) => `/vehicleconstructiontypes`,
      transformResponse: responseData => {
        return transformResponseToSelect(responseData);
      },
      providesTags: (result = [], error, arg) => [
        'Construction',
        ...result.map(({id}) => ({type: 'Construction', id}))
      ]
    }),
    getConstruction: builder.query({
      query: id => `/vehicleconstructiontypes/${id}`,
      providesTags: (result, error, arg) => [{type: 'Construction', id: arg}]
    }),
    addNewConstruction: builder.mutation({
      query: body => ({
        url: `/vehicleconstructiontypes`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Construction']
    }),
    updateConstruction: builder.mutation({
      query: ({id, body}) => ({
        url: `/vehicleconstructiontypes/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => [{type: 'Construction', id: arg.id}]
    }),
    //
    getEmissions: builder.query({
      query: (showInactive = false) => `/vehicleemissionstandards`,
      providesTags: (result = [], error, arg) => [
        'Emission',
        ...result.map(({id}) => ({type: 'Emission', id}))
      ]
    }),
    getEmissionsSelect: builder.query({
      query: (showInactive = false) => `/vehicleemissionstandards`,
      transformResponse: responseData => {
        return transformResponseToSelect(responseData);
      },
      providesTags: (result = [], error, arg) => [
        'Emission',
        ...result.map(({id}) => ({type: 'Emission', id}))
      ]
    }),
    getEmission: builder.query({
      query: id => `/vehicleemissionstandards/${id}`,
      providesTags: (result, error, arg) => [{type: 'Vehicleemissionstandard', id: arg}]
    }),
    addNewEmission: builder.mutation({
      query: body => ({
        url: `/vehicleemissionstandards`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Emission']
    }),
    updateEmission: builder.mutation({
      query: ({id, body}) => ({
        url: `/vehicleemissionstandards/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => [{type: 'Emission', id: arg.id}]
    }),
    getTags: builder.query({
      query: (showInactive = false) => `/bustags`,
      providesTags: (result = [], error, arg) => [
        'Tag',
        ...result.map(({id}) => ({type: 'Tag', id}))
      ]
    }),
    getTagsSelect: builder.query({
      query: (showInactive = false) => `/bustags`,
      transformResponse: responseData => {
        return transformResponseToSelectWithColor(responseData);
      },
      providesTags: (result = [], error, arg) => [
        'Tag',
        ...result.map(({id}) => ({type: 'Tag', id}))
      ]
    }),
    getTag: builder.query({
      query: id => `/bustags/${id}`,
      providesTags: (result, error, arg) => [{type: 'Tag', id: arg}]
    }),
    addNewTag: builder.mutation({
      query: body => ({
        url: `/bustags`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Tag']
    }),
    updateTag: builder.mutation({
      query: ({id, body}) => ({
        url: `/bustags/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => [{type: 'Tag', id: arg.id}]
    }),
    getEvents: builder.query({
      query: () => `/events`,
      providesTags: (result = [], error, arg) => [
        'Event',
        ...result.map(({id}) => ({type: 'Event', id}))
      ]
    }),
    getEvent: builder.query({
      query: id => `/events/${id}`,
      transformResponse: responseData => {
        return {
          ...responseData,
          departure: moment(responseData.start).format("HH:mm"),
          arrival: moment(responseData.end).format("HH:mm"),
          start: moment(responseData.start).format("YYYY-MM-DD"),
          end: moment(responseData.end).format("YYYY-MM-DD"),
        }
      },
      providesTags: (result, error, arg) => [{type: 'Event', id: arg}]
    }),
    updateEvent: builder.mutation({
      query: ({id, body}) => ({
        url: `/events/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => [{type: 'Event', id: arg.id}, 'Dashboard']
    }),
    addNewEvent: builder.mutation({
      query: body => ({
        url: `/events`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Event', 'Dashboard']
    }),
    getPlaces: builder.query({
      query: () => `/places`,
      providesTags: (result = [], error, arg) => [
        'Place',
        ...result.map(({id}) => ({type: 'Place', id}))
      ]
    }),
    getPlacesSelect: builder.query({
      query: () => `/places`,
      transformResponse: responseData => {
        return transformResponseToSelect(responseData);
      },
      providesTags: (result = [], error, arg) => [
        'Place',
        ...result.map(({id}) => ({type: 'Place', id}))
      ]
    }),
    getPlace: builder.query({
      query: id => `/places/${id}`,
      providesTags: (result, error, arg) => [{type: 'Place', id: arg}]
    }),
    updatePlace: builder.mutation({
      query: ({id, body}) => ({
        url: `/places/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => [{type: 'Place', id: arg.id}, 'Dashboard']
    }),
    addNewPlace: builder.mutation({
      query: body => ({
        url: `/places`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Place', 'Dashboard']
    }),
    getTrafficdaycodes: builder.query({
      query: () => `/trafficdaycodes`,
      providesTags: (result = [], error, arg) => [
        'Trafficdaycode',
        ...result.map(({id}) => ({type: 'Trafficdaycode', id}))
      ]
    }),
    getTrafficdaycodesSelect: builder.query({
      query: () => `/trafficdaycodes`,
      transformResponse: responseData => {
        return transformResponseToSelectTrafficdaycode(responseData);
      },
      providesTags: (result = [], error, arg) => [
        'Trafficdaycode',
        ...result.map(({id}) => ({type: 'Trafficdaycode', id}))
      ]
    }),
    getCoursetypes: builder.query({
      query: () => `/coursetypes`,
      providesTags: (result = [], error, arg) => [
        'Coursetype',
        ...result.map(({id}) => ({type: 'Coursetype', id}))
      ]
    }),
    getCoursetypesSelect: builder.query({
      query: () => `/coursetypes`,
      transformResponse: responseData => {
        return transformResponseToSelect(responseData);
      },
      providesTags: (result = [], error, arg) => [
        'Coursetype',
        ...result.map(({id}) => ({type: 'Coursetype', id}))
      ]
    }),
    getCourse: builder.query({
      query: id => `/courses/${id}`,
      transformResponse: responseData => {
        let trafficdaycode_ids = []
        let new_transfers = []

        responseData.trafficdaycodes.forEach(function (tdc) {
          trafficdaycode_ids.push(tdc.id)
        })

        responseData.transfers.forEach(function(transfer){
          transfer.time = moment.utc(transfer.time).format("HH:mm")
          new_transfers.push(transfer)
        })

        return {
          ...responseData,
          arrival: moment.utc(responseData.arrival).format("HH:mm"),
          departure: moment.utc(responseData.departure).format("HH:mm"),
          trafficdaycode_ids: trafficdaycode_ids,
          transfers: new_transfers
        }
      },
      providesTags: (result, error, arg) => [{type: 'Course', id: arg}]
    }),
    getCourses: builder.query({
      query: (filter) => `/courses?filter=${filter}`,
      providesTags: (result = [], error, arg) => [
        'Course',
        ...result.map(({id}) => ({type: 'Course', id}))
      ]
    }),
    addNewCourse: builder.mutation({
      query: body => ({
        url: `/courses`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: (result, error, arg) => ['Course',
        {type: 'Schedule', id: arg.schedule_id}, {type: 'Line', id: arg.line_id},
        'ScheduleSelect',
        'Dashboard',
      ]

    }),
    updateCourse: builder.mutation({
      query: ({id, body}) => ({
        url: `/courses/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => [{type: 'Course', id: arg.id}, 'Dashboard',
        'Schedule', 'ScheduleSelect']
    }),
    getLine: builder.query({
      query: id => `/lines/${id}`,
      providesTags: (result, error, arg) => [{type: 'Line', id: arg}]
    }),
    getLines: builder.query({
      query: () => `/lines`,
      providesTags: (result = [], error, arg) => [
        'Line',
        ...result.map(({id}) => ({type: 'Line', id}))
      ]
    }),
    getLinesSelect: builder.query({
      query: () => `/lines`,
      transformResponse: responseData => {
        return transformResponseToSelect(responseData);
      },
      providesTags: (result = [], error, arg) => [
        'Line',
        ...result.map(({id}) => ({type: 'Line', id}))
      ]
    }),
    addNewLine: builder.mutation({
      query: body => ({
        url: `/lines`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Line', 'Dashboard']
    }),
    updateLine: builder.mutation({
      query: ({id, body}) => ({
        url: `/lines/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => [{type: 'Line', id: arg.id}, 'Dashboard']
    }),
    getSchedule: builder.query({
      query: id => `/schedules/${id}`,
      providesTags: (result, error, arg) => [{type: 'Schedule', id: arg}],
      transformResponse: responseData => {
        let trafficdaycode_ids = []

        responseData.trafficdaycodes.forEach(function (tdc) {
          trafficdaycode_ids.push(tdc.id)
        })
        return {
          ...responseData,
          manual_working_time: moment.utc(responseData.manual_working_time * 1000).format("HH:mm"),
          valid_from: moment.utc(responseData.valid_from).format("YYYY-MM-DD"),
          valid_to: moment.utc(responseData.valid_to).format("YYYY-MM-DD"),
          trafficdaycode_ids: trafficdaycode_ids
        }
      },
    }),
    getSchedules: builder.query({
      query: ({show_archived, query}) => `/schedules?show_archived=${show_archived}&query=${query}`,
      providesTags: (result = [], error, arg) => [
        'Schedule',
        ...result.map(({id}) => ({type: 'Schedule', id}))
      ]
    }),
    getSchedulesSelect: builder.query({
      query: (show_archived = false) => `/schedules?show_archived=${show_archived}`,
      transformResponse: responseData => {
        return transformResponseToSelectWithMatchcode(responseData);
      },
      providesTags: (result = [], error, arg) => [
        'ScheduleSelect',
        ...result.map(({id}) => ({type: 'ScheduleSelect', id}))
      ]
    }),
    getSchedulesSelectForService: builder.query({
      query: isoWeeks => `/services/schedules?isoWeeks=${isoWeeks.toString()}`,
      transformResponse: responseData => {
        return transformResponseToSchedulesSelectForService(responseData);
      },
      providesTags: (result = [], error, arg) => [
        'ScheduleServiceSelect',
        ...result.map(({id}) => ({type: 'ScheduleServiceSelect', id}))
      ]
    }),
    addNewSchedule: builder.mutation({
      query: body => ({
        url: `/schedules`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Schedule', 'Dashboard', 'ScheduleSelect']
    }),
    updateSchedule: builder.mutation({
      query: ({id, body}) => ({
        url: `/schedules/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => ['Schedule', 'Dashboard', 'LoggingOverview', 'Logging', 'LoggingStaff',]
    }),
    updateScheduleRemoveCourse: builder.mutation({
      query: ({id, course_id}) => ({
        url: `/schedules/${id}/removecourse/${course_id}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, arg) => [
        {type: 'Schedule', id: arg.id},
        'Dashboard',
        'Course',
        'ScheduleSelect']
    }),
    duplicateSchedule: builder.mutation({
      query: (id) => ({
        url: `/schedules/${id}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => ['Schedule', 'ScheduleSelect', 'Dashboard']
    }),
    getCheck: builder.query({
      query: (id) => `/check/schedules/${id}`,
      providesTags: (result, error, arg) => [{type: 'Check', id: arg}]
    }),
    getCountry: builder.query({
      query: id => `/countries/${id}`,
      providesTags: (result, error, arg) => [{type: 'Country', id: arg}]
    }),
    getCountries: builder.query({
      query: () => `/countries`,
      providesTags: (result = [], error, arg) => [
        'Country',
        ...result.map(({id}) => ({type: 'Country', id}))
      ]
    }),
    getCountriesSelect: builder.query({
      query: () => `/countries`,
      transformResponse: responseData => {
        return transformResponseToSelect(responseData);
      },
      providesTags: (result = [], error, arg) => [
        'Country',
        ...result.map(({id}) => ({type: 'Country', id}))
      ]
    }),
    addNewCountry: builder.mutation({
      query: body => ({
        url: `/countries`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Country']
    }),
    updateCountry: builder.mutation({
      query: ({id, body}) => ({
        url: `/countries/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => [{type: 'Country', id: arg.id}]
    }),
    getState: builder.query({
      query: id => `/states/${id}`,
      providesTags: (result, error, arg) => [{type: 'State', id: arg}]
    }),
    getStates: builder.query({
      query: () => `/states`,
      providesTags: (result = [], error, arg) => [
        'State',
        ...result.map(({id}) => ({type: 'State', id}))
      ]
    }),
    getStatesSelect: builder.query({
      query: () => `/states`,
      transformResponse: responseData => {
        return transformResponseToSelect(responseData);
      },
      providesTags: (result = [], error, arg) => [
        'State',
        ...result.map(({id}) => ({type: 'State', id}))
      ]
    }),
    getStatesWithCountrySelect: builder.query({
      query: () => `/states`,
      transformResponse: responseData => {
        return transformResponseToStatesWithCountrySelect(responseData);
      },
      providesTags: (result = [], error, arg) => [
        'State',
        ...result.map(({id}) => ({type: 'State', id}))
      ]
    }),
    addNewState: builder.mutation({
      query: body => ({
        url: `/states`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['State']
    }),
    updateState: builder.mutation({
      query: ({id, body}) => ({
        url: `/states/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => [{type: 'State', id: arg.id}]
    }),
    getResources: builder.query({
      query: () => `/resources`,
      providesTags: (result = [], error, arg) => [
        'Resource',
        ...result.map(({id}) => ({type: 'Resource', id}))
      ]
    }),
    getSimulations: builder.query({
      query: (start) => `/simulation?start=${start}`,
      providesTags: (result = [], error, arg) => [
        'Simulation',
        ...result.map(({id}) => ({type: 'Simulation', id}))
      ]
    }),
    getSimulationConfig: builder.query({
      query: (start) => `/simulation/config?start=${start}`,
      providesTags: (result = [], error, arg) => ['SimulationConfig'],
      transformResponse: responseData => {
        const minTime = moment(responseData.min_time)
        const minDiff = (minTime.minute() % 30)
        const slotMinTime = moment(minTime).subtract(minDiff, "minutes").format("HH:mm")

        return {slotMinTime: slotMinTime}
      }
    }),
    getPresetting: builder.query({
      query: id => `/presettings/${id}`,
      transformResponse: responseData => {
        return {
          ...responseData,
          auto_logging_duration: moment.utc(responseData.auto_logging_duration * 1000).format('HH:mm')
        }
      },
      providesTags: (result, error, arg) => [{type: 'Presetting', id: arg}]
    }),
    getPresettings: builder.query({
      query: ({show_in_logging = null}) => `/presettings?show_in_logging=${show_in_logging}`,
      providesTags: (result = [], error, arg) => [
        'Presetting',
        ...result.map(({id}) => ({type: 'Presetting', id}))
      ]
    }),
    getPresettingsSelect: builder.query({
      query: ({show_in_logging = null}) => `/presettings?show_in_logging=${show_in_logging}`,
      transformResponse: responseData => {
        return transformResponseToSelect(responseData);
      },
      providesTags: (result = [], error, arg) => [
        'Presetting',
        ...result.map(({id}) => ({type: 'PresettingSelect', id}))
      ]
    }),
    getPresettingsVariableWorkingTimeSelect: builder.query({
      query: () => `/presettings?variable_working_time=${true}`,
      transformResponse: responseData => {
        return transformResponseToSelect(responseData);
      },
      providesTags: (result = [], error, arg) => [
        'PresettingsVariableWorkingTimeSelect',
        ...result.map(({id}) => ({type: 'PresettingSelect', id}))
      ]
    }),
    getPresettingsSelectForService: builder.query({
      query: () => `/presettings?variable_working_time=${false}`,
      transformResponse: responseData => {
        return transformResponseToPresettingsSelectForService(responseData);
      },
      providesTags: (result = [], error, arg) => [
        'Presetting',
        ...result.map(({id}) => ({type: 'Presetting', id}))
      ]
    }),
    addNewPresetting: builder.mutation({
      query: body => ({
        url: `/presettings`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Presetting']
    }),
    updatePresetting: builder.mutation({
      query: ({id, body}) => ({
        url: `/presettings/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => [{type: 'Presetting', id: arg.id}]
    }),
    getOvertimes: builder.query({
      query: (show_inactive) => `/overtimes?show_inactive=${show_inactive}`,
      transformResponse: responseData => {
        let newResponseData = responseData
        let newEntries = []

        responseData.entries.forEach(function (o) {
          newEntries.push({
            ...o,
            previous_overtime: hhmm(o.previous_overtime)
          })
        })

        newResponseData.entries = newEntries
        return newResponseData
      },
      providesTags: (result = [], error, arg) => [
        'Overtimes'
      ]
    }),
    getOvertimesForStaff: builder.query({
      query: ({staff_id, date}) => `/overtimes/staff/${staff_id}?date=${date}`,
      transformResponse: responseData => {
        let newOvertimesForPeriod = []
        responseData.overtimes_for_period.forEach(function (o) {
          newOvertimesForPeriod.push({
            ...o,
            amount: hhmm(o.amount)
          })
        })
        let newResponse = responseData
        newResponse.previous_overtime = hhmm(responseData.previous_overtime)
        newResponse.overtimes_for_period = newOvertimesForPeriod
        return newResponse
      },
      providesTags: (result, error, arg) => [{type: 'OvertimesForStaff', id: arg}]
    }),
    addNewOvertime: builder.mutation({
      query: body => ({
        url: `/overtimes`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Overtimes', 'OvertimesForStaff']
    }),
    getOvertime: builder.query({
      query: (id) => `/overtimes/${id}`,
      transformResponse: responseData => {
        let newResponse = responseData
        newResponse.amount = hhmm(responseData.amount)
        return newResponse
      },
      providesTags: (result, error, arg) => [{type: 'Overtime', id: arg}]
    }),
    updateOvertime: builder.mutation({
      query: ({id, body}) => ({
        url: `/overtimes/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => [{type: 'Overtime', id: arg.id}, 'OvertimesForStaff', 'Overtimes']
    }),
    removeOvertime: builder.mutation({
      query: ({id, body}) => ({
        url: `/overtimes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{type: 'Overtime', id: arg.id}, 'OvertimesForStaff', 'Overtimes']
    }),
    getVacations: builder.query({
      query: (show_inactive) => `/vacationaccounts?show_inactive=${show_inactive}`,
      providesTags: (result = [], error, arg) => [
        'Vacation',
        ...result.map(({id}) => ({type: 'Vacation', id}))
      ]
    }),
    getVacationsForStaff: builder.query({
      query: ({staff_id, date}) => `/vacationaccounts/staff/${staff_id}?date=${date}`,
      providesTags: (result, error, arg) => [{type: 'VacationsForStaff', id: arg}]
    }),
    getVacation: builder.query({
      query: (id) => `/vacationaccounts/${id}`,
      providesTags: (result, error, arg) => [{type: 'Vacation', id: arg}]
    }),
    addNewVacation: builder.mutation({
      query: body => ({
        url: `/vacationaccounts`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Vacation', 'VacationsForStaff']
    }),
    updateVacation: builder.mutation({
      query: ({id, body}) => ({
        url: `/vacationaccounts/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => [{type: 'Vacation', id: arg.id}, 'VacationsForStaff']
    }),
    removeVacation: builder.mutation({
      query: ({id, body}) => ({
        url: `/vacationaccounts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{type: 'Vacation', id: arg.id}, 'VacationsForStaff']
    }),
    getStaffs: builder.query({
      query: (show_archived) => `/staffs?show_inactive=${show_archived}`,
      providesTags: (result = [], error, arg) => [
        'Staff',
        ...result.map(({id}) => ({type: 'Staff', id}))
      ]
    }),
    getStaffsSelect: builder.query({
      query: () => `/staffs`,
      transformResponse: responseData => {
        return transformResponseToSelect(responseData);
      },
      providesTags: (result = [], error, arg) => [
        'Staff',
        ...result.map(({id}) => ({type: 'Staff', id}))
      ]
    }),
    getStaff: builder.query({
      query: (id) => `/staffs/${id}`,
      transformResponse: responseData => {
        let driving_license_group_ids = []

        responseData.driving_license_groups.forEach(function (g) {
          driving_license_group_ids.push(g.id)
        })

        return {
          ...responseData,
          driving_license_group_ids: driving_license_group_ids,
          working_time_month: hhmm(responseData.working_time_month),
          working_time_week: hhmm(responseData.working_time_week),
          working_time_day: hhmm(responseData.working_time_day),
        }
      },
      providesTags: (result, error, arg) => [{type: 'Staff', id: arg}]
    }),
    updateStaff: builder.mutation({
      query: ({id, body}) => ({
        url: `/staffs/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => ['Staff', 'ServiceStaffMap', 'LoggingStaff', 'LoggingOverview']
    }),
    addNewStaff: builder.mutation({
      query: body => ({
        url: `/staffs`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Staff', 'ServiceStaffMap']
    }),
    addNewWorkingTime: builder.mutation( {
      query: body => ({
        url: `/staffs/working-time`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Staff', 'ServiceStaffMap']
    }),
    getLogging: builder.query({
      query: id => `/loggings/${id}`,
      transformResponse: responseData => {
        let duration, daily_allowance, extra_pay
        if (responseData.schedule_id != null) {
          duration = ''
          daily_allowance = ''
        } else {
          duration = moment.utc(responseData.duration * 1000).format('HH:mm')
          daily_allowance = moment.utc(responseData.daily_allowance * 1000).format('HH:mm')
          extra_pay = moment.utc(responseData.extra_pay * 1000).format('HH:mm')
        }
        return {
          ...responseData,
          day: moment(responseData.day).format('YYYY-MM-DD'),
          duration: duration,
          daily_allowance: daily_allowance,
          extra_pay: extra_pay
        }
      },
      providesTags: (result, error, arg) => [{type: 'Logging', id: arg}]
    }),
    getLoggingsOverview: builder.query({
      query: ({year, month}) => `/loggings/overview/${year}/${month}`,
      providesTags: (result = [], error, arg) => [
        'LoggingOverview']
    }),
    getLoggingsStaff: builder.query({
      query: ({staff_id, year, month}) => `/loggings/staff/${staff_id}/${year}/${month}`,
      transformResponse: responseData => {
        let newResponseData = responseData
        let newDetails = newResponseData.details

        newDetails.overtime = hhmm(newDetails.overtime)
        newDetails.previous_overtime = hhmm(newDetails.previous_overtime)
        newDetails.new_overtime = hhmm(newDetails.new_overtime)
        newDetails.regular_working_time_month = hhmm(newDetails.regular_working_time_month)
        console.log("this ist called. ")
        newResponseData.details = newDetails


        return newResponseData
      },
      providesTags: (result = [], error, arg) => [
        'LoggingStaff',
      ]
    }),
    updateLogging: builder.mutation({
      query: ({id, body}) => ({
        url: `/loggings/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => [
        {type: 'Logging', id: arg.id},
        'LoggingStaff',
        'LoggingOverview'
      ]
    }),
    addNewLogging: builder.mutation({
      query: body => ({
        url: `/loggings`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['LoggingStaff', 'Logging', 'LoggingOverview']
    }),
    removeLogging: builder.mutation({
      query: ({id}) => ({
        url: `/loggings/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{
        type: 'Logging',
        id: arg.id
      }, 'LoggingStaff', 'Logging', 'LoggingOverview']
    }),
    getDrivingLicenseGroupsSelect: builder.query({
      query: () => `/drivinglicensegroups`,
      transformResponse: responseData => {
        return transformResponseToSelect(responseData);
      },
      providesTags: (result = [], error, arg) => [
        'DrivingLicenseGroup',
        ...result.map(({id}) => ({type: 'DrivingLicenseGroup', id}))
      ]
    }),
    getServiceStaffArray: builder.query({
      query: ({
                isoWeeks = [],
                variable_working_time = true
              }) => `/services?isoWeeks=${isoWeeks.toString()}&variable_working_time=${variable_working_time}`,
      transformResponse: responseData => {
        let newServiceStaffMap = new Map()
        responseData.forEach(function (entry) {
          const key = `${entry.staff_id}-${entry.day}`
          let value = {}
          if (entry.presetting_id != null) {
            value.id = `p-${entry.presetting_id}`
            value.matchcode = entry.presetting.matchcode
            value.variable_working_time = entry.variable_working_time
            value.entry_id = entry.id
          } else if (entry.schedule_id != null) {
            value.id = `s-${entry.schedule_id}`
            value.matchcode = entry.schedule.matchcode
          }

          if (newServiceStaffMap.has(key)) {
            newServiceStaffMap.get(key).push(value);
          } else {
            newServiceStaffMap.set(key, [value])
          }
        })
        return Array.from(newServiceStaffMap)
      },
      providesTags: () => ['ServiceStaffMap']
    }),
    getPublicHolidays: builder.query({
      query: () => `/publicholidays`,
      providesTags: (result = [], error, arg) => [
        'PublicHoliday',
        ...result.map(({id}) => ({type: 'PublicHoliday', id}))
      ]
    }),
    getPublicHoliday: builder.query({
      query: id => `/publicholidays/${id}`,
      providesTags: (result, error, arg) => [{type: 'PublicHoliday', id: arg}]
    }),
    updatePublicHoliday: builder.mutation({
      query: ({id, body}) => ({
        url: `/publicholidays/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => [{type: 'PublicHoliday', id: arg.id}]
    }),
    addNewPublicHoliday: builder.mutation({
      query: body => ({
        url: `/publicholidays`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['PublicHoliday']
    }),
    getSchoolHolidays: builder.query({
      query: () => `/schoolholidays`,
      providesTags: (result = [], error, arg) => [
        'SchoolHoliday',
        ...result.map(({id}) => ({type: 'SchoolHoliday', id}))
      ]
    }),
    getSchoolHoliday: builder.query({
      query: id => `/schoolholidays/${id}`,
      providesTags: (result, error, arg) => [{type: 'SchoolHoliday', id: arg}]
    }),
    updateSchoolHoliday: builder.mutation({
      query: ({id, body}) => ({
        url: `/schoolholidays/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => [{type: 'SchoolHoliday', id: arg.id}]
    }),
    addNewSchoolHoliday: builder.mutation({
      query: body => ({
        url: `/schoolholidays`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['SchoolHoliday']
    }),
    addNewServiceWizard: builder.mutation({
      query: (body) => ({
        url: `/servicewizard`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: (result, error, arg) => ['ServiceStaffMap', 'Vacation', 'VacationsForStaff',
        'LoggingOverview', 'Logging', 'LoggingStaff', 'UpcomingEntry'
      ]
    }),
    getUpcomingEntries: builder.query({
      query: (staff_id) => `/entries/upcoming?staff_id=${staff_id}`,
      providesTags: (result = [], error, arg) => [
        'UpcomingEntry',
        ...result.map(({id}) => ({type: 'UpcomingEntry', id}))
      ]
    }),
    getEntry: builder.query({
      query: id => `/entries/${id}`,
      transformResponse: responseData => {
        return {
          ...responseData,
          variable_working_time: hhmm(responseData.variable_working_time)
        }
      },
      providesTags: (result, error, arg) => [{type: 'Entry', id: arg}]
    }),
    addNewEntry: builder.mutation({
      query: body => ({
        url: `/entries`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['LoggingStaff', 'Logging', 'LoggingOverview', 'ServiceStaffMap']
    }),
    updateEntry: builder.mutation({
      query: ({id, body}) => ({
        url: `/entries/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => [
        {type: 'Logging', id: arg.id},
        'LoggingStaff',
        'LoggingOverview', 'ServiceStaffMap', 'Entry'
      ]
    }),
    removeEntry: builder.mutation({
      query: ({id}) => ({
        url: `/entries/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{type: 'Logging', id: arg.id}, 'LoggingStaff', 'Logging',
        'LoggingOverview', 'ServiceStaffMap', 'Entry']
    }),
    getDashboard: builder.query({
      query: () => `/dashboard`,
      providesTags: (result = [], error, arg) => [
        'Dashboard'
      ]
    }),
    getSettings: builder.query({
      query: () => `/settings`,
      providesTags: (result = [], error, arg) => ['Settings']
    }),
    updateSettings: builder.mutation({
      query: (body) => ({
        url: `/settings`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => ['Settings']
    }),
  })

})

function transformResponseToSelect(responseData) {
  var select = []
  responseData.forEach(function (row) {
    select.push({key: row.id, value: row.id, text: row.name})
  })
  return select
}

function transformResponseToSelectWithMatchcode(responseData) {
  var select = []
  responseData.forEach(function (row) {
    select.push({key: row.id, value: row.id, text: `${row.matchcode} ${row.name}`})
  })
  return select
}

function transformResponseToStatesWithCountrySelect(responseData) {
  var select = []
  responseData.forEach(function (row) {
    select.push({key: row.id, value: row.id, text: row.name, country_id: row.country_id})
  })
  return select
}

function transformResponseToSchedulesSelectForService(responseData) {
  var select = []
  responseData.forEach(function (row) {
    select.push({key: `s-${row.id}`, value: `s-${row.id}`, text: row.matchcode})
  })
  return select
}

function transformResponseToPresettingsSelectForService(responseData) {
  var select = []
  responseData.forEach(function (row) {
    select.push({key: `p-${row.id}`, value: `p-${row.id}`, text: row.matchcode})
  })
  return select
}

function transformResponseToSelectWithColor(responseData) {
  var select = []
  responseData.forEach(function (row) {
    select.push({key: row.id, value: row.id, text: row.name, color: row.color})
  })
  return select
}

function transformResponseToSelectTrafficdaycode(responseData) {
  var select = []
  responseData.forEach(function (row) {
    select.push({key: row.id, value: row.id, text: row.code})
  })
  return select
}

export const {
  useGetBusQuery,
  useGetBusesQuery,
  useGetBusesSelectQuery,
  useUpdateBusMutation,
  useAddNewBusMutation,
  useGetFuelsQuery,
  useGetFuelsSelectQuery,
  useGetFuelQuery,
  useUpdateFuelMutation,
  useAddNewFuelMutation,
  useGetConstructionsQuery,
  useGetConstructionsSelectQuery,
  useGetConstructionQuery,
  useAddNewConstructionMutation,
  useUpdateConstructionMutation,
  useGetEmissionsQuery,
  useGetEmissionsSelectQuery,
  useGetEmissionQuery,
  useAddNewEmissionMutation,
  useUpdateEmissionMutation,
  useGetTagsQuery,
  useGetTagsSelectQuery,
  useGetTagQuery,
  useAddNewTagMutation,
  useUpdateTagMutation,
  useGetEventsQuery,
  useGetEventQuery,
  useAddNewEventMutation,
  useUpdateEventMutation,
  useGetPlacesQuery,
  useGetPlacesSelectQuery,
  useGetPlaceQuery,
  useAddNewPlaceMutation,
  useUpdatePlaceMutation,
  useGetTrafficdaycodesQuery,
  useGetTrafficdaycodesSelectQuery,
  useGetCoursetypesQuery,
  useGetCoursetypesSelectQuery,
  useGetCourseQuery,
  useGetCoursesQuery,
  useUpdateCourseMutation,
  useUpdateScheduleRemoveCourseMutation,
  useAddNewCourseMutation,
  useGetLineQuery,
  useGetLinesQuery,
  useGetLinesSelectQuery,
  useUpdateLineMutation,
  useAddNewLineMutation,
  useGetScheduleQuery,
  useGetSchedulesQuery,
  useGetSchedulesSelectQuery,
  useGetSchedulesSelectForServiceQuery,
  useUpdateScheduleMutation,
  useAddNewScheduleMutation,
  useDuplicateScheduleMutation,
  useGetCheckQuery,
  useGetCountryQuery,
  useGetCountriesQuery,
  useGetCountriesSelectQuery,
  useUpdateCountryMutation,
  useAddNewCountryMutation,
  useGetStateQuery,
  useGetStatesQuery,
  useGetStatesSelectQuery,
  useGetStatesWithCountrySelectQuery,
  useUpdateStateMutation,
  useAddNewStateMutation,
  useGetResourcesQuery,
  useGetSimulationsQuery,
  useGetSimulationConfigQuery,
  useGetPresettingQuery,
  useGetPresettingsQuery,
  useGetPresettingsSelectQuery,
  useGetPresettingsVariableWorkingTimeSelectQuery,
  useGetPresettingsSelectForServiceQuery,
  useUpdatePresettingMutation,
  useAddNewPresettingMutation,
  useGetOvertimesQuery,
  useGetOvertimeQuery,
  useGetOvertimesForStaffQuery,
  useUpdateOvertimeMutation,
  useRemoveOvertimeMutation,
  useAddNewOvertimeMutation,
  useGetVacationsQuery,
  useGetVacationQuery,
  useGetVacationsForStaffQuery,
  useAddNewVacationMutation,
  useUpdateVacationMutation,
  useRemoveVacationMutation,
  useGetStaffQuery,
  useGetStaffsQuery,
  useGetStaffsSelectQuery,
  useUpdateStaffMutation,
  useAddNewStaffMutation,
  useAddNewWorkingTimeMutation,
  useGetLoggingQuery,
  useGetLoggingsOverviewQuery,
  useGetLoggingsStaffQuery,
  useUpdateLoggingMutation,
  useAddNewLoggingMutation,
  useRemoveLoggingMutation,
  useGetEntryQuery,
  useAddNewEntryMutation,
  useUpdateEntryMutation,
  useRemoveEntryMutation,
  useGetDrivingLicenseGroupsSelectQuery,
  useGetServiceStaffArrayQuery,
  useGetPublicHolidaysQuery,
  useGetPublicHolidayQuery,
  useUpdatePublicHolidayMutation,
  useAddNewPublicHolidayMutation,
  useGetSchoolHolidaysQuery,
  useGetSchoolHolidayQuery,
  useUpdateSchoolHolidayMutation,
  useAddNewSchoolHolidayMutation,
  useAddNewServiceWizardMutation,
  useGetUpcomingEntriesQuery,
  useGetDashboardQuery,
  useGetSettingsQuery,
  useUpdateSettingsMutation,
} = apiSlice

