import React from 'react'
import {Link} from 'react-router-dom'

import FormatedTimeOfDayBus from '../core/shared/FormatedTimeOfDayBus'

import {Button, Icon, Table} from 'semantic-ui-react'

import TrafficdaycodeLabel from "../core/shared/trafficdaycode/TrafficdaycodeLabel";
import TableBody from "semantic-ui-react/dist/commonjs/collections/Table/TableBody";
import FormatedDuration from "../core/shared/FormatedDuration";
import CourseTypeLabel from "../core/shared/course_type/CourseTypeLabel"

export default function ScheduleCoursesTableRow(props) {
  const {course, handleRemove} = props
  return (
    <TableBody>
      <Table.Row key={course.id}>
        <Table.Cell>{course.line && <Link to={`/lines/${course.line.id}`}>{course.line.nr}</Link>}</Table.Cell>
        <Table.Cell><Link to={`/courses/${course.id}`}>{course.nr}</Link></Table.Cell>
        <Table.Cell><FormatedTimeOfDayBus time={course.departure}/></Table.Cell>
        <Table.Cell>{course.departure_place && course.departure_place.name}</Table.Cell>
        <Table.Cell><FormatedTimeOfDayBus time={course.arrival}/></Table.Cell>
        <Table.Cell>{course.arrival_place && course.arrival_place.name}</Table.Cell>
        <Table.Cell className="no-print">
          {course.bus && course.bus.license_plate}
        </Table.Cell>
        <Table.Cell className="no-print">
          {course.trafficdaycodes.map(
            (ctdc, index) => <TrafficdaycodeLabel key={index}
                                                  content={ctdc.code}/>
          )}
        </Table.Cell>
        <Table.Cell>{course.course_type &&
          <CourseTypeLabel course_type={course.course_type}/>}</Table.Cell>
        <Table.Cell className="no-print">{course.distance}</Table.Cell>
        <Table.Cell>{course.comment}</Table.Cell>
        <Table.Cell className="no-print">
          <Button onClick={() => handleRemove(course.id)}
                  icon
                  basic
                  size='mini'
                  title='Kurs aus Wagenumlauf entfernen'
          >
            <Icon name='minus'/>
          </Button>
        </Table.Cell>
      </Table.Row>
      {course.turn_sec > 0 && <TurnRow course={course}/>}
      {course.transfers.length > 0 && course.transfers.map(
        (transfer) => <TransferRow transfer={transfer}
                                   key={transfer.id}/>
      )}
    </TableBody>
  );
}
export const TurnRow = (props) => (
  <Table.Row style={{background: '#F7F7F7', color: 'rgba(0,0,0,.6)', borderTop: 'none'}}>
    <Table.Cell style={{borderTop: 'none'}}></Table.Cell>
    <Table.Cell style={{borderTop: 'none'}}></Table.Cell>
    <Table.Cell style={{borderTop: 'none'}}><FormatedDuration sec={props.course.turn_sec}/></Table.Cell>
    <Table.Cell style={{borderTop: 'none'}}>Wende</Table.Cell>
    <Table.Cell style={{borderTop: 'none'}}></Table.Cell>
    <Table.Cell style={{borderTop: 'none'}}></Table.Cell>
    <Table.Cell style={{borderTop: 'none'}} className="no-print"></Table.Cell>
    <Table.Cell style={{borderTop: 'none'}} className="no-print"></Table.Cell>
    <Table.Cell style={{borderTop: 'none'}}>{props.course.turn_course_type &&
      <CourseTypeLabel course_type={props.course.turn_course_type}/>}</Table.Cell>
    <Table.Cell style={{borderTop: 'none'}} className="no-print"></Table.Cell>
    <Table.Cell style={{borderTop: 'none'}}>{props.course.turn_manual &&
      <p className="no-print">Wende überschrieben</p>}</Table.Cell>
    <Table.Cell className="no-print" style={{borderTop: 'none'}}></Table.Cell>
  </Table.Row>
)

export const TransferRow = (props) => (
  <Table.Row style={{background: '#F7F7F7', color: 'rgba(0,0,0,.6)', borderTop: 'none'}}>
    <Table.Cell style={{borderTop: 'none'}}></Table.Cell>
    <Table.Cell style={{borderTop: 'none'}}></Table.Cell>
    <Table.Cell colSpan="4" style={{borderTop: 'none'}}><TransferInfo icon={props.transfer.icon}
                                                                      direction={props.transfer.direction}
                                                                      info={props.transfer.info}
                                                                      time={props.transfer.time}/></Table.Cell>
    <Table.Cell style={{borderTop: 'none'}} className="no-print"></Table.Cell>
    <Table.Cell style={{borderTop: 'none'}} className="no-print"></Table.Cell>
    <Table.Cell style={{borderTop: 'none'}}></Table.Cell>
    <Table.Cell style={{borderTop: 'none'}} className="no-print"></Table.Cell>
    <Table.Cell style={{borderTop: 'none'}}></Table.Cell>
    <Table.Cell style={{borderTop: 'none'}}></Table.Cell>
  </Table.Row>
)

export const TransferInfo = (props) => (
  <span>
    <Icon name={props.icon}/>
    {props.direction == "arrive" && <Icon name="long arrow alternate right"/>}
    {props.direction == "departure" && <Icon name="long arrow alternate left"/>}
    {props.info} &nbsp;
    <FormatedTimeOfDayBus time={props.time}/>
  </span>
)
