import {Divider, Label, List} from "semantic-ui-react";
import React from "react";
import moment from "moment";

export default function TimeSlots(props) {
  const {time_slots} = props;

  return (<div className='no-print'>
      <Divider/>
      <List horizontal inverted style={{width: '100%'}} verticalAlign='middle'>
        {time_slots.map((time_slot, index) => <TimeSlot time_slot={time_slot} key={index} index={index}/>)}
      </List>
      <div>
        Legende:
        <Label style={{background: '#FBE52D'}}>Lenkzeit</Label>,
        <Label style={{background: '#00b5ad', color: 'white'}}>Fahrtunterbrechung</Label> und
        <Label style={{background: '#2185d0', color: 'white'}}>Ruhezeit</Label>
      </div>
    </div>
  )
}


const TimeSlot = (props) => {
  const {time_slot, index} = props;
  const duration = moment.utc(time_slot.duration * 1000).format('HH:mm')
  switch (time_slot.type) {
    case 'driving_time':
      return <TimeSlotListItem index={index} color="rgba(0,0,0,.6)" backgroundColor='#FBE52D'
                               percentage={time_slot.percentage}>
        {duration}
      </TimeSlotListItem>
    case 'break_time':
      return <TimeSlotListItem index={index} color="white" backgroundColor='#00b5ad' percentage={time_slot.percentage}>
        {duration}
      </TimeSlotListItem>
    case 'rest_period':
      return <TimeSlotListItem index={index} color="white" backgroundColor='#2185d0' percentage={time_slot.percentage}>
        {duration}
      </TimeSlotListItem>
    default:
      return <TimeSlotListItem index={index}>
        Fehler bei DetailTyp!
      </TimeSlotListItem>
  }
}


const TimeSlotListItem = (props) => {
  const {backgroundColor, index, color, children, percentage} = props;
  const width = percentage + "%"
  return <List.Item key={index}
                    style={{background: backgroundColor, margin: 0, padding: 0, width: width, height: '2em'}}>
    <List.Content style={{color: color, padding: '0.5em'}}>
      <div title={children}>{children}</div>
    </List.Content>
  </List.Item>
}
