import React from "react"

import {Button, Icon} from "semantic-ui-react"

export default function RemoveIcon(props) {
    const {onClick, title} = props
    return <Button compact icon onClick={onClick} title={title}>
      <Icon name='delete'/>
    </Button>
}
