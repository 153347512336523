import React, {useEffect} from 'react'
import {Container, Header} from "semantic-ui-react";
import {useNavigate} from "react-router-dom";
import {useAddNewCountryMutation} from "../../../lib/api/apiSlice";
import {CountryForm} from "./CountryForm";

export default function CountryNew(props) {
  const navigate = useNavigate()
  const [addNewCountry] = useAddNewCountryMutation()

  const create = async (formData) => {
    try{
      await addNewCountry(formData).unwrap()
      navigate('/countries')
    } catch(err){
      console.log('Failure', err)
    }
  }
  useEffect(() => {
    document.title = 'Staat anlegen | First Class bus'
  }, [])
    return <Container style={{marginTop: '1em'}}>
        <Header as="h2">Staat anlegen</Header>
        <CountryForm onSubmit={create}/>
    </Container>
}
