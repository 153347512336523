import React, {useEffect} from 'react'
import {Container, Divider, Grid, Header} from "semantic-ui-react";
import {useParams} from "react-router-dom";
import {useGetPresettingQuery} from "../../../lib/api/apiSlice";
import {Edit} from "../../core/shared/controls";

export default function PresettingDetail(props) {
    let {id} = useParams()

  const {
    data: presetting = {},
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetPresettingQuery(id)

  useEffect(() => {
    document.title = 'Tätigkeit Details | First Class bus'
  }, [])


  return <Container style={{marginTop: '1em'}}>
    <Grid style={{marginTop: '1em'}} stackable>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h1">Tätigkeit</Header>
          <Header as="h3">{presetting.name}</Header>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right'>
          <Edit/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={6}>
        <Grid.Column>Kürzel: {presetting.matchcode} </Grid.Column>
        <Grid.Column>Urlaub: {presetting.vacation == true ? <p>Ja</p> : <p>Nein</p>}</Grid.Column>
        <Grid.Column>Zeitausgleich: {presetting.comp_time == true ? <p>Ja</p> : <p>Nein</p>}</Grid.Column>
        <Grid.Column>Variable Arbeitszeit: {presetting.variable_working_time == true ? <p>Ja</p> : <p>Nein</p>}</Grid.Column>
        <Grid.Column>In Zeiterfassung anzeigen: {presetting.show_in_logging == true ? <p>Ja</p> : <p>Nein</p>}</Grid.Column>
        <Grid.Column>Dienstverhinderung: {presetting.special_vacation == true ? <p>Ja</p> : <p>Nein</p>}</Grid.Column>
        <Grid.Column>Freistellung: {(() => {
          if (presetting.special_vacation_type == "standard") {
            return <span>Standard {presetting.special_vacation_contingent} Arbeitstage</span>
          } else if (presetting.special_vacation_type == "home_care"){
            return <span>Pflege 1 Arbeitswoche</span>
          } else if (presetting.special_vacation_type == "contingent_each_x_months"){
            return <span>{presetting.special_vacation_contingent}  Arbeitstag alle {presetting.special_vacation_each_x_months} Monate</span>
          } else {
            return <span>-</span>
          }

        })()}</Grid.Column>
        <Grid.Column>Krankenstand: {presetting.sick_leave == true ? <p>Ja</p> : <p>Nein</p>}</Grid.Column>
      </Grid.Row>
      <Divider horizontal>Automatische Zeiterfassung</Divider>
      <Grid.Row columns={3}>
        <Grid.Column>
          Arbeitszeit: {presetting.auto_logging_duration}
        </Grid.Column>
        <Grid.Column>
          Diätenfähig: {presetting.daily_allowance == true ? <p>Ja</p> : <p>Nein</p>}
        </Grid.Column>
        <Grid.Column>
          Aufschlägsfähig: {presetting.extra_pay == true ? <p>Ja</p> : <p>Nein</p>}
        </Grid.Column>
      </Grid.Row>
      </Grid>
    </Container>
}
