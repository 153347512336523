import React, {useEffect} from 'react'

import EventNewForm from './EventForm'


import {Container, Header} from 'semantic-ui-react'
import {useNavigate} from "react-router-dom";
import {useAddNewEventMutation} from "../../lib/api/apiSlice";

export default function EventNew(props) {
  const navigate = useNavigate()
  const [addNewEvent, {isLoading: isLoadingAddNewEvent}] = useAddNewEventMutation()

  const create = async (formData) => {
    try{
      console.log(formData)
      await addNewEvent(formData).unwrap()
      navigate('/events')
    } catch(err){
      console.log('Failure', err)
    }
  }

  useEffect(() => {
  document.title = 'Fahrauftrag anlegen | First Class bus'
}, [])

  return <Container>
      <Header as="h5">Neuen Fahrauftrag erstellen</Header>
      <EventNewForm onSubmit={create} />
  </Container>
}

