import React from 'react'

import {Container, Header} from 'semantic-ui-react'
import {FuelForm} from "./FuelForm";
import {useAddNewFuelMutation} from "../../../lib/api/apiSlice";
import {useNavigate} from "react-router-dom";

export default function FuelNew(props) {
  const navigate = useNavigate()
  const [addNewFuel, {isLoading: isLoadingAddNewFuel}] = useAddNewFuelMutation()

  const create = async (formData) => {
    try{
      await addNewFuel(formData).unwrap()
      navigate('/fuels')
    } catch(err){
      console.log('Failure', err)
    }
  }

  return <Container style={{marginTop: '1em'}}>
      <Header as="h5">Neuen Kraftstoff erstellen</Header>
      <FuelForm onSubmit={create}/>
    </Container>
}

