import React, {useEffect} from 'react'

import {Container, Grid, Header} from 'semantic-ui-react'
import {Edit} from "../../core/shared/controls";
import {useGetSchoolHolidayQuery} from "../../../lib/api/apiSlice";
import {useParams} from "react-router-dom";

export const initialSchoolHoliday = {
  country_id: '',
  state_id: '',
  id: '',
  name: '',
  day: ''
}


export default function SchoolHolidayDetail(props) {
  let {id} = useParams()
  useEffect(() => {
    document.title = 'Schulfreier Tag Details | First Class bus'
  }, [])

  const {data: school_holiday = {}} = useGetSchoolHolidayQuery(id)

  return <Container style={{marginTop: '1em'}}>
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2">Schulfreier Tag Details</Header>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right'>
          <Edit/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4}>
          {school_holiday.day}
        </Grid.Column>
        <Grid.Column width={4}>
          {school_holiday.name}
        </Grid.Column>
        <Grid.Column width={4}>
          Staat: {school_holiday.country && school_holiday.country.name}
        </Grid.Column>
        <Grid.Column width={4}>
          Land: {school_holiday.state && school_holiday.state.name}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
}

