import {Message} from "semantic-ui-react";
import React from "react";

export default function ScheduleAlert(props) {
  const {alert} = props

  switch (alert.severity) {
    case 'error':
      return <Message error className="no-print">
        <Message.Header>{alert.title}</Message.Header>
        {alert.message}
      </Message>
    case 'warning':
      return <Message warning className="no-print">
        <Message.Header>{alert.title}</Message.Header>
        {alert.message}
      </Message>
    case 'note':
      return <Message className="no-print">
        <Message.Header>{alert.title}</Message.Header>
        {alert.message}
      </Message>
    default:
      return <Message className="no-print">
        <Message.Header>{alert.title}</Message.Header>
        {alert.message}
      </Message>
  }
}



